import React from 'react'

import { CartItemInfo } from './cart_item_info'
import { CartItemCommands } from './cart_item_commands'

import ImageIcon from '@material-ui/icons/Image'

import { Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

interface ICartItemProps {
  item: ICartItem
  serialNumber: string | undefined
  updateItem: (item: ICartItem, qty: number | null) => void
}

const CartItem = ({ item, updateItem, serialNumber }: ICartItemProps) => {
  const { t } = useTranslation()

  let _itemSerial = serialNumber ?? '-'
  let _itemTitle = item.componentCode

  return (
    <Grid container spacing={2}>
      <Grid item>
        {item.img && !item.img.startsWith('blob:') ? (
          <img style={{ height: '130px' }} alt='cart-item' src={item.img} />
        ) : (
          <ImageIcon />
        )}
      </Grid>
      <Grid item xs>
        <Typography variant='h6'>{_itemTitle}</Typography>

        <Typography variant='subtitle1'>
          {t<string>('serialNumber')}: {_itemSerial}
        </Typography>

        <CartItemInfo item={item} />
      </Grid>
      <Grid item xs={3}>
        <Grid container spacing={2}>
          <CartItemCommands item={item} updateItem={updateItem} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export { CartItem }
