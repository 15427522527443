import React, { useEffect, useState } from 'react'
import {
  Grid,
  makeStyles,
  Card,
  CardActionArea,
  CardContent,
  useMediaQuery,
} from '@material-ui/core'

import axios from 'axios'

import { PreviewCard } from '../../components/preview_card'

import { DOCS_URL } from '../../consts/urls'
import { useTranslation } from 'react-i18next'
import { useSettings } from '../../context'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  bomDiv: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  cardContent: {
    height: 50,
  },
}))

interface IInfo {
  selectedNode: IJSTreeNode
}

const Info = ({ selectedNode }: IInfo) => {
  const [loadingDocs, setLoadingDocs] = useState(false)

  const { settings } = useSettings()
  const classes = useStyles()

  const _infoSelectedNode = settings.value.infoSelectedNode
  const _propsSelectedNode = selectedNode.data.properties
    ? selectedNode.data.properties
    : {}

  let _docs: any[] = []
  if (selectedNode.data.docs) {
    selectedNode.data.docs.forEach((doc: any) => {
      _docs.push(doc)
    })
  }

  console.log(_docs)

  const { t } = useTranslation()

  const prefersDarkMode = false // useMediaQuery('(prefers-color-scheme: dark)')

  const downloadFile = (id: number, name: string) => {
    axios
      .get(`${DOCS_URL}/document/${id}/download`, {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        if (response.data) {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', name)
          document.body.appendChild(link)
          link.click()
          // link.parentNode?.removeChild(link)
        }
      })
      .catch(() => console.log('ERROR DOWNLOADDING!!!'))
  }

  return (
    <div id='home-info-node'>
      {selectedNode && (
        <>
          <h1>{selectedNode.data.model}</h1>
          <div className='info-val'>
            {t('quantity')}:{' '}
            {selectedNode.data.quantity ? selectedNode.data.quantity : 1}
          </div>
          <hr />
          <div className='info-val'>
            {t('description')}: {selectedNode.data.desc}
          </div>
          {_infoSelectedNode
            ? _infoSelectedNode.map((value, i) => {
                return (
                  <div key={i} className='info-val'>
                    {value}:{' '}
                    {value in _propsSelectedNode
                      ? _propsSelectedNode[value]
                      : '-'}
                  </div>
                )
              })
            : ''}
          <hr />
          {t('documents')}:
          <Grid container spacing={3}>
            {_docs?.length ? (
              _docs.map((d, i) => (
                <Grid item key={i}>
                  <Card className={classes.paper}>
                    <CardActionArea
                      title='Download'
                      onClick={() => downloadFile(d.id, d.name)}
                    >
                      <PreviewCard
                        url={`${DOCS_URL}/document/${d.id}/thumbnail`}
                        width={250}
                        height={200}
                        prefersDarkMode={prefersDarkMode}
                      />
                      <CardContent className={classes.cardContent}>
                        {d.name}
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))
            ) : loadingDocs ? (
              <Grid item>
                <Grid item>{t('loading')}</Grid>
              </Grid>
            ) : (
              <Grid item>{t('noDocuments')}</Grid>
            )}
          </Grid>
        </>
      )}
    </div>
  )
}

export default Info
