import React, { useState, useEffect, useContext } from 'react'
import SearchSidebar from './search_sidebar'
import { ID_TREE, ID_TREE_NODE } from '../../../consts'
import { intializeMainTree, initializeNodeTree } from '../trees'
import { CubeLoader } from '../../../components/loader'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useCartItems, useSettings } from '../../../context'

const $ = window.$

const useStyles = makeStyles((theme) => ({
  hidden: {
    display: 'none',
  },
  treeContainer: {
    width: '100%',
    height: 'calc(100vh - 120px)',
    overflow: 'hidden',
  },
  treeBody: {
    width: '100%',
    height: '100%',
    overflowY: 'scroll',
    paddingRight: '24px',
    boxSizing: 'content-box',
  },
}))

interface ISelectedMachine {
  value: number
}

interface ISidebar {
  machineId: number | undefined
  setMenuSelected: (arg0: string) => void
  menuSelected: string
  fullScreen: boolean
  // setSelectedMachine: (arg0: ISelectedMachine) => void
  showMenu: boolean
  loading: boolean
  updateSelectedNode: (arg0: IJSTreeNode) => void
}

const Sidebar = (props: ISidebar) => {
  const [SearchTextNode, setSearchTextNode] = useState('')
  const { addCartItems } = useCartItems()
  const { t } = useTranslation()
  const classes = useStyles()
  const { settings } = useSettings()

  const {
    machineId,
    setMenuSelected,
    menuSelected,
    fullScreen,
    // setSelectedMachine,
    showMenu,
    loading,
  } = props

  useEffect(() => {
    intializeMainTree(settings.value, props, addCartItems)
    initializeNodeTree(settings.value, addCartItems)

    // setTimeout(() => {
    //   if (machineId) setSelectedMachine({ value: machineId })
    // }, 500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings])

  useEffect(() => {
    if (!menuSelected) return

    if (menuSelected === ID_TREE && fullScreen) setMenuSelected(ID_TREE_NODE)
    else if (menuSelected === ID_TREE_NODE && !fullScreen)
      setMenuSelected(ID_TREE)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullScreen])

  const handleSearchNode = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTextNode(value)

    const _nodeTree = $(`#${menuSelected}`).jstree(true)
    _nodeTree.search(value)
  }

  const _menus = [
    {
      id: ID_TREE,
      header: (
        <SearchSidebar
          placeholder={`${t('search')}..`}
          value={SearchTextNode}
          handleChange={handleSearchNode}
        />
      ),
      content: <div className={classes.treeBody} id={ID_TREE} />,
    },
    {
      id: ID_TREE_NODE,
      header: (
        <SearchSidebar
          placeholder={`${t('search')}..`}
          value={SearchTextNode}
          handleChange={handleSearchNode}
        />
      ),
      content: <div className={classes.treeBody} id={ID_TREE_NODE} />,
    },
  ]

  return (
    <>
      {_menus.map((menu) => (
        <div
          key={menu.id}
          className={showMenu && menu.id === menuSelected ? '' : classes.hidden}
        >
          {menu.header}
          {loading ? <CubeLoader /> : ''}
          <div className={classes.treeContainer}>{menu.content}</div>
        </div>
      ))}
    </>
  )
}

export default Sidebar
