import React from 'react'
import { Typography } from '@material-ui/core'

// import { useUserInfo } from '../../context'
import { useTranslation } from 'react-i18next'

interface ICartItemInfoProps {
  item: ICartItem
}

const CartItemInfo = ({ item }: ICartItemInfoProps) => {
  const { t } = useTranslation()

  const _infoCartItems = [
    {
      key: `${t<string>('description')}:`,
      value: item.componentDescription ? item.componentDescription : '-',
    },
  ]

  return (
    <>
      {_infoCartItems.map((item, k) => (
        <Typography variant='subtitle2' key={item.key}>
          {item.key} {item.value}
        </Typography>
      ))}
    </>
  )
}

export { CartItemInfo }
