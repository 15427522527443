const Autodesk = window.Autodesk
const $ = window.$

export default class NewExplodeExtension extends Autodesk.Viewing.Extension {
  constructor(viewer, options) {
    super(viewer, options)

    this.Matching = []
    this.InsIds = []
    this.onInputChange = this.onInputChange.bind(this)
  }

  setMatching(matching) {
    this.Matching = matching
  }

  setInsIds(insIds) {
    this.InsIds = insIds
  }

  customSelectiveExplode(scale) {
    const model = this.viewer.model
    const instanceTree = model.getInstanceTree()
    const mc = model.getVisibleBounds(true).center()
    const fragList = model.getFragmentList()
    const pt = new window.THREE.Vector3()
    const _matching = this.Matching
    const _insIds = this.InsIds

    //Input scale is in the range 0-1, where 0
    //means no displacement, and 1 maximum reasonable displacement.
    scale *= 2

    if (scale) {
      const scaledExplodeDepth = scale * (instanceTree.maxDepth - 1) + 1
      const explodeDepth = 0 | scaledExplodeDepth
      const currentSegmentFraction = scaledExplodeDepth - explodeDepth

      const tmpBox = new Float32Array(6)

      ;(function explodeRec(nodeId, depth, cx, cy, cz, ox, oy, oz) {
        let oscale = scale * 2
        // smooth transition of this tree depth
        // from non-exploded to exploded state
        if (depth === explodeDepth) oscale *= currentSegmentFraction

        instanceTree.getNodeBox(nodeId, tmpBox)

        const mycx = 0.5 * (tmpBox[0] + tmpBox[3])
        const mycy = 0.5 * (tmpBox[1] + tmpBox[4])
        const mycz = 0.5 * (tmpBox[2] + tmpBox[5])

        if (
          depth > 0 &&
          depth <= explodeDepth &&
          !_insIds.includes(nodeId) &&
          (!_matching.length || _matching.includes(`${nodeId}`))
        ) {
          const dx = (mycx - cx) * oscale
          const dy = (mycy - cy) * oscale
          const dz = (mycz - cz) * oscale

          //var omax = Math.max(dx, Math.max(dy, dz));
          ox += dx
          oy += dy
          oz += dz
        }

        if(!_insIds.includes(nodeId)) {
          instanceTree.enumNodeChildren(
            nodeId,
            function (dbId) {
              explodeRec(dbId, depth + 1, mycx, mycy, mycz, ox, oy, oz)
            },
            false
          )
        }

        instanceTree.enumNodeFragments(
          nodeId,
          function (fragId) {
            pt.x = ox
            pt.y = oy
            pt.z = oz

            fragList.updateAnimTransform(fragId, null, null, pt)
          },
          false
        )
      })(instanceTree.getRootId(), 0, mc.x, mc.y, mc.z, 0, 0, 0)
    } else {
      for (let fragId = 0; fragId < fragList.getCount(); ++fragId) {
        fragList.updateAnimTransform(fragId)
      }
    }

    const _isolatedNodes = this.viewer.getIsolatedNodes()
    this.viewer.fitToView(_isolatedNodes)
  }

  onInputChange({ target: { value } }) {
    this.customSelectiveExplode(value)
    this.viewer.impl.sceneUpdated(true)
  }

  load() {
    const _slider = $('#custom-explode-slider')[0]
    if (_slider) {
      _slider.addEventListener('input', this.onInputChange)
    }
    return true
  }

  unload() {
    const _slider = $('#custom-explode-slider')[0]
    if (_slider) {
      _slider.removeEventListener('input', this.onInputChange)
    }
    return true
  }
}
