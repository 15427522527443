import React from 'react'

import { useUserInfo } from '../../context'
import { TableModal } from '../../components/modal'
import { IBaseTable, IColumn } from '../../components/table/types'
import { useTranslation } from 'react-i18next'
import { gql, useQuery } from '@apollo/client'
import { useHistory } from 'react-router-dom'

interface ICartHistoryModalProps {
  show: boolean
  onHide: () => void
}

interface ICustomRefTable {
  table: {
    getData: () => IRowTable[]
  }
  selectionContext: {
    selected: number[]
  }
}

interface IRowTable {
  Id: string
  Value: string
}

interface IDataTable {
  orderId: number
  itemId: number
  date: string
  userId: number
  user?: string
  businessPartnerId?: number
  businessPartner?: string
  customerId?: number
  customer?: string
  componentId: number
  componentCode: string
  componentDescription: string
  machineUrl: string
  quantity: number
  dbId?: number
  relationId: number
  serialNumber: string
}

interface IQueryPO {
  purchaseOrdersQuery: {
    purchaseOrders: IPurchaseOrder[]
  }
}

const QUERY_PO = gql`
  query getPo($superAdmin: Boolean!, $admin: Boolean!) {
    purchaseOrdersQuery {
      purchaseOrders {
        id
        date
        userId
        user {
          email
        }
        businessPartnerId @include(if: $admin)
        businessPartner @include(if: $admin) {
          name
        }
        customerId @include(if: $superAdmin)
        customer @include(if: $superAdmin) {
          name
        }
        purchaseOrderItems {
          id
          componentId
          componentCode
          componentDescription
          machineUrl
          quantity
          dbId
          relationId
          serialNumber {
            number
            salesOrder
          }
        }
      }
    }
  }
`

const CartHistoryModal = ({ show, onHide }: ICartHistoryModalProps) => {
  const { userInfo } = useUserInfo()
  const { t } = useTranslation()
  const { push } = useHistory()

  const _userType = parseInt(userInfo.type)

  const { data, loading } = useQuery<
    IQueryPO,
    { superAdmin: boolean; admin: boolean }
  >(QUERY_PO, {
    variables: {
      superAdmin: _userType === 1,
      admin: _userType <= 2,
    },
  })

  let _columns: IColumn[] = [
    {
      title: t('date'),
      field: 'date',
    },
    {
      title: t('component'),
      field: 'componentCode',
    },
    {
      title: t('description'),
      field: 'componentDescription',
    },
    {
      title: t('serialNumber'),
      field: 'serialNumber',
    },
    {
      title: t('qty'),
      field: 'quantity',
    },
    {
      title: t('user'),
      field: 'user',
    },
  ]

  if (_userType <= 2) {
    _columns.push({
      title: 'Business Partner',
      field: 'businessPartner',
    })
  }

  if (_userType === 1) {
    _columns.push({
      title: t('customer'),
      field: 'customer',
    })
  }

  const _dataTable: IDataTable[] = []

  if (data) {
    data.purchaseOrdersQuery.purchaseOrders.forEach(po => {
      po.purchaseOrderItems.forEach(item => {
        const _serialNumber =
          userInfo.companyName === 'GAI'
            ? item.serialNumber?.salesOrder
            : item.serialNumber?.number

        _dataTable.push({
          orderId: po.id,
          itemId: item.id ?? 0,
          date: po.date,
          userId: po.userId,
          user: po.user?.email,
          businessPartnerId: po.businessPartnerId,
          businessPartner: po.businessPartner?.name,
          customerId: po.customerId,
          customer: po.customer?.name,
          componentId: item.componentId,
          componentCode: item.componentCode,
          componentDescription: item.componentDescription ?? '',
          machineUrl: item.machineUrl,
          quantity: item.quantity,
          dbId: item.dbId,
          relationId: item.relationId,
          serialNumber: _serialNumber ?? '',
        })
      })
    })
  }

  const _tableProps: IBaseTable = {
    baseProps: {
      columns: _columns,
      title: t('purchaseOrders'),
      data: _dataTable,
      actions: [
        {
          icon: 'info',
          onClick: (_, item: IDataTable) => {
            if (item.dbId && item.machineUrl.includes('viewer')) {
              push(`${item.machineUrl}?ids=${item.dbId}`)
            } else if (item.machineUrl.includes('machine')) {
              push(`${item.machineUrl}?id=${item.relationId}`)
            } else {
              push(item.machineUrl)
            }
          },
          tooltip: t<string>('showItem'),
          iconProps: {
            color: 'primary',
          },
        },
      ],
      loading: loading,
    },
    selection: false,
  }

  return (
    <>
      <TableModal open={show} onClose={onHide} tableProps={_tableProps} />
    </>
  )
}

export { CartHistoryModal }
