import React from 'react'
import { BrowserRouter, Route } from 'react-router-dom'

import {
  Home,
  Login,
  Product,
  Viewer,
  Machine,
  Settings,
  Cart,
  ElectricBom,
} from './pages'

import { PrivateRoute } from './components/private_route'

import {
  UserInfoContextProvider,
  FullScreenContextProvider,
  CartItemsContextProvider,
  SettingsContextProvider,
} from './context'

import { pdfjs } from 'react-pdf'

import { MuiPickersUtilsProvider } from '@material-ui/pickers'

import MomentUtils from '@date-io/moment'
import { SendOrder } from './pages/send_order'
import { LogoContextProvider } from './context/logo_context'

import './i18next'
import { NewPassword, ResetPassword } from './pages/login'
import { createMuiTheme, ThemeProvider, useMediaQuery } from '@material-ui/core'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const App = () => {
  const prefersDarkMode = false // useMediaQuery('(prefers-color-scheme: dark)')

  const theme = createMuiTheme({
    palette: {
      type: prefersDarkMode ? 'dark' : 'light',
      primary: {
        main: '#114f8e',
      },
      secondary: {
        main: '#fdb813',
      },
    },
  })

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <LogoContextProvider>
          <UserInfoContextProvider>
            <FullScreenContextProvider>
              <CartItemsContextProvider>
                <SettingsContextProvider>
                  <BrowserRouter>
                    <Route path='/login' component={Login} />
                    <Route path='/newpassword' component={NewPassword} />
                    <Route path='/resetpassword' component={ResetPassword} />
                    <PrivateRoute
                      exact
                      path='/product/:serialNumberId'
                      component={Product}
                    />
                    <PrivateRoute
                      exact
                      path='/viewer/:serialNumberId/:revBomId'
                      component={Viewer}
                    />
                    <PrivateRoute
                      exact
                      path='/machine/:serialNumberId/:revBomId'
                      component={Machine}
                    />
                    <PrivateRoute
                      exact
                      path='/ebom/:revBomId'
                      component={ElectricBom}
                    />
                    <PrivateRoute exact path='/' component={Home} />
                    <PrivateRoute exact path='/cart' component={Cart} />
                    <PrivateRoute exact path='/settings' component={Settings} />
                    <PrivateRoute exact path='/order' component={SendOrder} />
                  </BrowserRouter>
                </SettingsContextProvider>
              </CartItemsContextProvider>
            </FullScreenContextProvider>
          </UserInfoContextProvider>
        </LogoContextProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  )
}

export default App
