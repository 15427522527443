import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { find } from 'lodash'
import { useParams, useHistory, useLocation } from 'react-router-dom'

import { BOMS_URL } from '../../consts/urls'
import { useUserInfo, useCartItems, useSettings } from '../../context'
import { getSpCodeFromTreeData, updateNodeSettings } from '../../utils/viewer'
import {
  NewExplodeExtension,
  MyContextMenuExtension,
  CustomToolbarExtensionName,
} from '../../extensions'

import { CustomToolbarExtension } from '../../extensions/custom_toolbar/custom_toolbar'
import DialogSettings from './dialog_settings'
import { gql, useLazyQuery } from '@apollo/client'
import { Backdrop } from '@material-ui/core'
import { CubeLoader } from '../../components/loader'

const $ = window.$
const Autodesk = window.Autodesk
Autodesk.Viewing.theExtensionManager.registerExtension(
  'CustomMenu',
  MyContextMenuExtension
)
Autodesk.Viewing.theExtensionManager.registerExtension(
  'CustomExplodeExtension',
  NewExplodeExtension
)

interface IParams {
  revBomId: string | undefined
  serialNumberId: string | undefined
}

interface IQueryTree {
  bomsQuery: {
    bom: IBom
  }
}

const GET_TREE = gql`
  query getTree($id: Int!) {
    bomsQuery {
      bom(id: $id) {
        id
        revProduct
        revProductId
        tree
      }
    }
  }
`

const Viewer = () => {
  const [Urn, setUrn] = useState('')
  const [Viewer, setViewer] = useState<Autodesk.Viewing.Viewer3D>()
  const [TreeData, setTreeData] = useState<IJSTreeNode[]>([])
  const [Matching, setMatching] = useState<IMatching>({})
  const [OpenDialog, setOpenDialog] = useState(false)

  const prefersDarkMode = false // useMediaQuery('(prefers-color-scheme: dark)')

  const { revBomId, serialNumberId } = useParams<IParams>()
  const { push } = useHistory()
  const { search } = useLocation()
  const { userInfo } = useUserInfo()
  const { cartItems, addCartItems } = useCartItems()
  const { settings } = useSettings()

  const [getTree, { loading }] = useLazyQuery<IQueryTree, { id: number }>(
    GET_TREE,
    {
      onCompleted: res => {
        const _bom = JSON.parse(JSON.stringify(res.bomsQuery.bom))
        const _urn = `urn:${btoa(_bom.tree.data.urn ?? '')}`

        if (_urn !== Urn) {
          setTreeData([_bom.tree])
          setUrn(_urn)
        } else if (Viewer) {
          geometryLoaded({ target: Viewer, model: Viewer.model }, [_bom.tree])
        }
      },
    }
  )

  useEffect(() => {
    if (revBomId) getTree({ variables: { id: parseInt(revBomId) } })

    if (Viewer) {
      const _cte = Viewer.getExtension(
        'CustomToolbarExtension'
      ) as CustomToolbarExtension
      _cte.setUserInfo(userInfo)
      if (_cte.InfoPanel) _cte.InfoPanel.setSettings(settings.value)
      if (_cte.DocPanel) _cte.DocPanel.setSettings(settings.value)
      if (_cte.TreePanel) _cte.TreePanel.Settings = settings.value
    }
  }, [revBomId])

  useEffect(() => {
    if (Viewer != undefined) {
      Viewer.tearDown()
      Viewer.finish()
      setViewer(undefined)
    }

    if (Urn) {
      const _options = {
        env: 'AutodeskProduction',
        getAccessToken: getForgeToken,
      }

      Autodesk.Viewing.Initializer(_options, () => onInitialized(Urn))
    } else {
      setViewer(undefined)
    }
  }, [Urn])

  useEffect(() => {
    if (Viewer) {
      const _mcme = Viewer.getExtension('CustomMenu') as MyContextMenuExtension
      const _cee = Viewer.getExtension(
        'CustomExplodeExtension'
      ) as NewExplodeExtension
      const _cte = Viewer.getExtension(
        'CustomToolbarExtension'
      ) as CustomToolbarExtension
      _mcme.setMatching(Matching)
      _cee.setMatching(Object.keys(Matching))
      _cte.setMatching(Matching)
    }
  }, [Matching])

  useEffect(() => {
    if (Viewer) {
      const _mcme = Viewer.getExtension('CustomMenu') as MyContextMenuExtension
      const _cee = Viewer.getExtension(
        'CustomExplodeExtension'
      ) as NewExplodeExtension
      const _cte = Viewer.getExtension(
        'CustomToolbarExtension'
      ) as CustomToolbarExtension
      const _settings = settings.value
      _cte.setTreeData(TreeData)

      //Calculate spareparts code
      const _ids = getSpCodeFromTreeData(TreeData[0])
      if (_settings.spareparts === 'prop')
        _mcme.setSparePartsIds([..._ids['spIds'], ..._ids['recIds']])

      _cee.setInsIds(_ids['insIds'])

      _cte.setSpIds(_ids['spIds'])
      _cte.setRecSpIds(_ids['recIds'])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TreeData])

  useEffect(() => {
    if (Viewer) {
      const _cte = Viewer.getExtension(
        'CustomToolbarExtension'
      ) as CustomToolbarExtension
      // _cte.CartPanel.setCartItems(cartItems)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItems])

  useEffect(() => {
    if (Viewer) {
      const _cte = Viewer.getExtension(
        'CustomToolbarExtension'
      ) as CustomToolbarExtension

      if (_cte.InfoPanel) _cte.InfoPanel.setSettings(settings.value)
      if (_cte.DocPanel) _cte.DocPanel.setSettings(settings.value)
      if (_cte.TreePanel) _cte.TreePanel.Settings = settings.value
    }

    if (TreeData.length) {
      const _root = TreeData[0]
      _root.text = updateNodeText(_root, true, true)

      setTreeData([_root])
    }
  }, [settings, TreeData.length])

  useEffect(() => {
    const _cte = Viewer?.getExtension(
      'CustomToolbarExtension'
    ) as CustomToolbarExtension

    if (_cte) {
      if (settings?.value.openTree != false) _cte.TreePanel.setVisible(true)
      _cte.DocPanel.setVisible(Boolean(settings?.value.openDoc))
      _cte.InfoPanel.setVisible(Boolean(settings?.value.openInfo))
    }
  }, [Viewer])

  const getForgeToken = (callback: any) => {
    axios.get(`${BOMS_URL}/forge/oauth/token`).then(({ data }) => {
      callback(data.access_token, data.expires_in)
    })
  }

  const onInitialized = (documentId: string) => {
    const config3d = {
      extensions: [
        'CustomMenu',
        'CustomExplodeExtension',
        'Autodesk.ViewCubeUi',
        'Autodesk.NPR',
      ],
      theme: 'bim-theme',
    }

    const _viewer = new Autodesk.Viewing.Viewer3D(
      document.getElementById('forgeViewer') as HTMLElement,
      config3d
    )

    _viewer.loadExtension(CustomToolbarExtensionName, { userInfo })
    _viewer.loadExtension('Autodesk.Explode')
    _viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, props =>
      geometryLoaded(props, TreeData)
    )
    _viewer.addEventListener(
      Autodesk.Viewing.EXTENSION_LOADED_EVENT,
      extensionLoaded
    )

    const startedCode = _viewer.start()

    if (prefersDarkMode) {
      _viewer.setBackgroundColor(48, 48, 48, 48, 48, 48)
    } else {
      _viewer.setBackgroundColor(240, 240, 240, 240, 240, 240)
    }

    if (startedCode) {
      console.error('Failed to create a Viewer: WebGL not supported.')
      return
    }
    Autodesk.Viewing.Document.load(
      documentId,
      viewerDocument => onDocumentLoadSuccess(viewerDocument, _viewer),
      onDocumentLoadFailure
    )
  }

  const onDocumentLoadSuccess = async (
    viewerDocument: Autodesk.Viewing.Document,
    viewer: Autodesk.Viewing.Viewer3D
  ) => {
    const allModels = viewerDocument.getRoot().search({ type: 'geometry' })
    await viewer.loadDocumentNode(viewerDocument, allModels[0])

    const ext = viewer.getExtension('Autodesk.NPR') as any
    ext.setParameter('style', 'edging')
    ext.setParameter('depthEdges', false)
    ext.setParameter('idEdges', false)

    viewer.setGhosting(false)
    viewer.setGroundShadow(false)
    viewer.setLightPreset(8)

    if (prefersDarkMode) {
      viewer.setBackgroundColor(48, 48, 48, 48, 48, 48)
    } else {
      viewer.setBackgroundColor(240, 240, 240, 240, 240, 240)
    }

    viewer.setOptimizeNavigation(true)
    viewer.setQualityLevel(false, false)
    viewer.setProgressiveRendering(false)
    viewer.setReverseZoomDirection(true)

    setViewer(viewer)
  }

  const onDocumentLoadFailure = () => {
    console.error('Failed fetching Forge manifest')
  }

  const extensionLoaded = ({
    extensionId,
    target,
  }: {
    extensionId: string
    target: Autodesk.Viewing.Viewer3D
  }) => {
    if (extensionId === 'CustomMenu') {
      const _mcme = target.getExtension('CustomMenu') as MyContextMenuExtension
      _mcme.setFullScreen(true)
      _mcme.setSetCart(addCartItems)
      _mcme.setMatching(Matching)
    } else if (extensionId === 'CustomExplodeExtension') {
      const _nee = target.getExtension(
        'CustomExplodeExtension'
      ) as NewExplodeExtension
      _nee.setMatching(Matching)
    } else if (extensionId === CustomToolbarExtensionName) {
      const _cte = target.getExtension(
        'CustomToolbarExtension'
      ) as CustomToolbarExtension
      _cte.setAddToCart(addCartItems)
      _cte.setPushFunc(push)
      // _cte.CartPanel.setPushFunc(push)
      // _cte.CartPanel.setUpdateCartItemsFunc(setCartItems)
      // _cte.CartPanel.setRemoveCartItemsFunc(removeCartItems)
      // _cte.CartPanel.setParamId(revBomId ?? '')
      _cte.OpenSettings = OpenSettings
    }
  }

  const geometryLoaded = (
    { target, model }: { target: Autodesk.Viewing.Viewer3D; model: any },
    treeData: IJSTreeNode[]
  ) => {
    if (search) {
      const _stringIds = search.split('=')[1]
      const _strIds = _stringIds.split(',')
      const _ids = _strIds.map(strId => parseInt(strId))

      fixTopView(target, _ids)
    } else {
      fixTopView(target)
    }

    const _iTree = model.getInstanceTree()
    const _tree: ITreeNode[] = []
    if (_iTree) {
      model.getBulkProperties2(
        [],
        { ignoreHidden: true },
        (allProps: Autodesk.Viewing.PropertyResult[]) => {
          addNodeToTree(_iTree.getRootId(), _tree, _iTree, allProps)
          initializeNodeTree(_tree, treeData)
        }
      )

      // set cart items only when the geometry is loaded
      // otherwise the extension crashes
      const _cte = target.getExtension(
        'CustomToolbarExtension'
      ) as CustomToolbarExtension
      // _cte.CartPanel.setCartItems(cartItems)
      _cte.setUserInfo(userInfo)
      if (_cte.InfoPanel) _cte.InfoPanel.setSettings(settings.value)
      if (_cte.DocPanel) _cte.DocPanel.setSettings(settings.value)
      if (_cte.TreePanel) _cte.TreePanel.Settings = settings.value
    } else {
      setTimeout(() => {
        geometryLoaded({ target, model }, treeData)
      }, 1000)
    }
  }

  const addNodeToTree = (
    dbId: number,
    tree: ITreeNode[],
    iTree: Autodesk.Viewing.InstanceTree,
    allProps: Autodesk.Viewing.PropertyResult[]
  ) => {
    const _nodeProps = find(allProps, { dbId: dbId })

    let _name = ''
    if (_nodeProps) {
      if (settings.value.pnProp && settings.value.pnProp !== '') {
        const _customProp = find(_nodeProps.properties, {
          displayName: settings.value.pnProp,
        })
        _name = _customProp?.displayValue ?? ''
      } else {
        _name = _nodeProps.properties[0].displayValue
      }
    }

    const _newNode = { dbId: dbId, name: _name, children: [] }
    tree.push(_newNode)
    iTree.enumNodeChildren(
      dbId,
      childId => addNodeToTree(childId, _newNode.children, iTree, allProps),
      false
    )
  }

  const initializeNodeTree = (
    modelTree: ITreeNode[],
    treeData: IJSTreeNode[]
  ) => {
    const _newSelectedNode = JSON.parse(JSON.stringify(treeData[0]))

    const _root = modelTree[0]
    _newSelectedNode.data.dbId = [_root.dbId]
    _newSelectedNode.text = updateNodeText(_newSelectedNode, true)
    const _matching: IMatching = assignNodeToComponent(
      _newSelectedNode.children,
      _root.children
    )

    _matching[_root.dbId] = _newSelectedNode.id
    setMatching(_matching)

    const _newTreeData: IJSTreeNode[] = [
      {
        id: _newSelectedNode.id,
        text: _newSelectedNode.text,
        type: _newSelectedNode.type,
        data: _newSelectedNode.data,
        children: _newSelectedNode.children,
        li_attr: _newSelectedNode.li_attr,
        parent: _newSelectedNode.parent,
      },
    ]

    setTreeData(_newTreeData)
  }

  const fixTopView = (target: any, ids?: any) => {
    // TODO!!!! Replace with data saved in db when we understad how to save camera info in db
    const _camera = target.impl.camera
    const _worldDirection = new window.THREE.Vector3()
    _camera.getWorldDirection(_worldDirection)

    if (
      _worldDirection.equals(new window.THREE.Vector3(0, 0, -1)) &&
      _camera.worldup.equals(new window.THREE.Vector3(0, 1, 0))
    ) {
      var cube = target.getExtension('Autodesk.ViewCubeUi')
      target.autocam.setCurrentViewAsTop()
      cube.setViewCube('front top right')
      target.impl.sceneUpdated()

      setTimeout(() => {
        target.fitToView(ids)
        setTimeout(() => {
          target.autocam.setCurrentViewAsHome()
        }, 800)
      }, 800)
    } else {
      target.fitToView(ids)
    }
    // End code to replace
  }

  const assignNodeToComponent = (
    nodes: IJSTreeNode[],
    components: ITreeNode[]
  ) => {
    if (nodes) {
      nodes.forEach(node => {
        node = updateNodeSettings(node, settings.value)
        if (node.data.urn) {
          node.type = 'model'
        } else if (!node.data.dbId) {
          node.type = 'no-component'
        }
      })
    }

    let _matching: IMatching = {}
    components.forEach(cmp => {
      let _cmpName = cleanString(cmp.name.split(':')[0])
      let node = find(nodes, n => {
        return _cmpName.includes(cleanString(n.data.model))
      }) as IJSTreeNode
      if (node) {
        const _dbId = node.data.dbId ? node.data.dbId : []
        _dbId.push(cmp.dbId)
        node.data.dbId = _dbId
        if (!node.data.urn)
          node.type = _dbId.length > 1 ? 'components' : 'component'

        _matching[cmp.dbId] = node.id
        let _childrenMatching = {}
        if (!node.data.urn && node.children && !node.data.inseparable) {
          _childrenMatching = assignNodeToComponent(node.children, cmp.children)
        } else {
          // remove children because the node has a different model
          node.children = []
        }
        _matching = { ..._matching, ..._childrenMatching }
      } else if (cmp.children.length) {
        let _childrenMatching = {}
        _childrenMatching = assignNodeToComponent(nodes, cmp.children)

        _matching = { ..._matching, ..._childrenMatching }
      }
    })

    return _matching
  }

  const updateNodeText = (
    node: IJSTreeNode,
    isRoot: boolean = false,
    recOnChild: boolean = false
  ) => {
    node = updateNodeSettings(node, settings.value)
    let _res
    const _settingsValue = settings.value

    if (_settingsValue.treeFields.length) {
      let _values: string[]
      if (isRoot) _values = [`<strong>${node.data.model}</strong>`]
      else
        _values = [
          `<small>${node.data.position}</small>`,
          `<small>${node.data.quantity}</small>`,
          `<strong>${node.data.model}</strong>`,
        ]

      _settingsValue.treeFields.forEach(f => {
        const _propertis = node.data.properties ? node.data.properties : {}
        _values.push(_propertis[f] ? _propertis[f] : '')
      })

      _res = _values.join(' - ')
      _res = `${_res} <i class="fas fa-paperclip icon-attach"></i>`
    } else {
      if (isRoot)
        _res = `<strong>${node.data.model}</strong> - ${node.data.desc} <i class="fas fa-paperclip icon-attach"></i>`
      else
        _res = `<small>${node.data.position}</small> - <small>${node.data.quantity}</small> - <strong>${node.data.model}</strong> - ${node.data.desc} <i class="fas fa-paperclip icon-attach"></i>`
    }

    if (node.children && recOnChild) {
      node.children.forEach(child => {
        child.text = updateNodeText(child, false, recOnChild)
      })
    }

    return _res
  }

  const cleanString = (str: string) => {
    str = str.replace(/-/g, '')
    str = str.replace(/\//g, '')
    str = str.replace(/"/g, '')
    str = str.replace(/\\/g, '')
    str = str.replace(/_/g, '')
    str = str.replace(/ /g, '')

    return str
  }

  const OpenSettings = () => setOpenDialog(true)
  const CloseSettings = () => setOpenDialog(false)

  return (
    <React.Fragment>
      <div
        style={{
          height: 'calc(100vh - 64px)',
          width: '100%',
          position: 'relative',
        }}
      >
        <div
          id='forgeViewer'
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            fontSize: '55px',
            background: prefersDarkMode
              ? 'rgb(48, 48, 48)'
              : 'rgb(240, 240, 240)',
          }}
        />
      </div>
      <DialogSettings
        open={OpenDialog}
        onClose={CloseSettings}
        customerId={parseInt(userInfo.customerId)}
      />
      <Backdrop open={loading} style={{ zIndex: 1 }}>
        <CubeLoader />
      </Backdrop>
    </React.Fragment>
  )
}

export default Viewer
