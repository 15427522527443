import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Fab, makeStyles, Tooltip } from '@material-ui/core'

import { useUserInfo, useCartItems } from '../../context'

import HistoryIcon from '@material-ui/icons/History'
import SendIcon from '@material-ui/icons/Send'

import { useTranslation } from 'react-i18next'
import { CartHistoryModal } from './cart_history_modal'

const useStyles = makeStyles(theme => ({
  fab: {
    marginRight: '5px',
    marginBottom: '10px',
  },
  fabContaier: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  }
}))

const CartTitle = () => {
  const [show, setShow] = useState(false)

  const { cartItems } = useCartItems()
  const { push } = useHistory()
  const { t } = useTranslation()
  const classes = useStyles()

  const prepareDataRequest = (blob: BlobPart | null) => {
    const _res: {
      data: { Value: string } | FormData
      headers: { contentType: string }
    } = {
      data: { Value: '' },
      headers: { contentType: 'application/json' },
    }
    if (blob) {
      const _file = new File([blob], 'SPP_RDO.pdf')

      const _formData = new FormData()
      _formData.append('file', _file)
      _formData.append('value', JSON.stringify(cartItems))

      _res.data = _formData
      _res.headers = { contentType: 'multipart/form-data' }
    } else {
      _res.data = { Value: JSON.stringify(cartItems) }
    }

    return _res
  }

  const sendOrder = (blob = null) => {
    if (cartItems.length) {
      prepareDataRequest(blob)
      push(`/order`)
    }
  }

  return (
    <div className={classes.fabContaier}>
      <Tooltip title={t<string>('sendOrder')} placement='bottom' arrow>
        <Fab
          color='primary'
          size='small'
          className={classes.fab}
          onClick={() => {
            sendOrder()
          }}
        >
          <SendIcon />
        </Fab>
      </Tooltip>
      <Tooltip title={t<string>('orderHistory')} placement='bottom' arrow>
        <Fab
          color='primary'
          size='small'
          className={classes.fab}
          onClick={() => setShow(true)}
        >
          <HistoryIcon />
        </Fab>
      </Tooltip>
      <CartHistoryModal show={show} onHide={() => setShow(false)} />
    </div>
  )
}

export { CartTitle }
