import React from 'react'

import { CartItem } from './cart_item'
import { useCartItems, useUserInfo } from '../../context'
import { Grid, Paper, Typography, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

interface ICartImageProps {
  updateItem: (item: ICartItem, qty: number | null) => void
}

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
}))

const CartImage = ({ updateItem }: ICartImageProps) => {
  const { cartItems } = useCartItems()
  const { userInfo } = useUserInfo()

  const classes = useStyles()
  const { t } = useTranslation()
  let _tot = 0
  return (
    <React.Fragment>
      {cartItems.map((item, k) => {
        _tot += item.quantity ? item.quantity : 0
        const _serialNumber =
          userInfo.companyName === 'GAI'
            ? item.serialNumber?.salesOrder
            : item.serialNumber?.number
        return (
          <Grid item xs={12} key={k}>
            <Paper className={classes.paper} key={k}>
              <CartItem
                item={item}
                key={k}
                updateItem={updateItem}
                serialNumber={_serialNumber}
              />
            </Paper>
          </Grid>
        )
      })}
      <Grid item xs={12}>
        <Typography variant='h6'>
          {t<string>('total')}: {_tot} {t<string>('pcs')}
        </Typography>
      </Grid>
    </React.Fragment>
  )
}

export { CartImage }
