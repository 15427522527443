import React, { useState } from 'react'
import { BlobProvider } from '@react-pdf/renderer'
import { Fab, makeStyles, Tooltip } from '@material-ui/core'
import SendIcon from '@material-ui/icons/Send'

import { OrderPdf } from './order_pdf'

import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  fab: {
    marginRight: '5px',
    marginBottom: '10px',
  },
}))

const OrderBlob = ({ sendOrder, orderPdfProps }) => {
  const [Sending, setSending] = useState(false)
  const classes = useStyles()
  const { t } = useTranslation()
  return orderPdfProps.items.length ? (
    <BlobProvider document={<OrderPdf {...orderPdfProps} />}>
      {({ blob }) => (
        <Tooltip title={t('sendOrder')} placement='bottom' arrow>
          <Fab
            color='primary'
            style={{
              position: 'absolute',
              bottom: '65px',
              left: '35px',
            }}
            className={classes.fab}
            onClick={() => {
              setSending(true)
              sendOrder(blob)
            }}
          >
            <SendIcon />
          </Fab>
        </Tooltip>
      )}
    </BlobProvider>
  ) : (
    <Tooltip title={t('sendOrder')} placement='bottom' arrow>
      <Fab color='primary' size='small' className={classes.fab}>
        <SendIcon />
      </Fab>
    </Tooltip>
  )
}

export { OrderBlob }
