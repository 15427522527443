import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  makeStyles,
  Grid,
  Typography,
  Paper,
  Container,
  Fab,
  Card,
  CardActionArea,
  Icon,
  Tooltip,
} from '@material-ui/core'
import { DOCS_URL } from '../../consts'

import axios from 'axios'
import { PreviewCard } from '../../components/preview_card'

import { useTranslation } from 'react-i18next'
import { gql, useLazyQuery, useQuery } from '@apollo/client'

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  card: {
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    height: 350,
  },
  cardAction: {
    // '&:hover': {
    //   background: theme.palette.secondary.light,
    // },
    height: '100%',
    minWidth: 250,
  },
  bomDiv: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  cardContent: {
    height: 50,
  },
}))

interface ISNQuery {
  salesOrdersQuery: {
    serialNumber: ISN
  }
}

interface IRevBomQuery {
  bomsQuery: {
    revProduct: IRevProduct
  }
}

interface IDocsQuery {
  bomsQuery: {
    docs: ILink[]
  }
}

const SN = gql`
  query getSerialNumber($id: Int!) {
    salesOrdersQuery {
      serialNumber(id: $id) {
        id
        number
        revBomId
        salesOrder
      }
    }
  }
`

const REVBOM = gql`
  query getRevBom($id: Int!) {
    bomsQuery {
      revProduct(id: $id) {
        id
        number
        preview
        boms {
          id
          viewableItem
          typeId
        }
      }
    }
  }
`

const GET_DOCS = gql`
  query getDocs($id: Int!) {
    bomsQuery {
      docs: getDocs(id: $id) {
        documentId
        urn
        rootDoc
      }
    }
  }
`

const downloadFile = (id: number, name: string) => {
  axios
    .get(`${DOCS_URL}/document/${id}/download`, {
      responseType: 'arraybuffer',
    })
    .then(response => {
      if (response.data) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', name)
        document.body.appendChild(link)
        link.click()
        link.parentNode?.removeChild(link)
      }
    })
    .catch(() => console.log('ERROR DOWNLOADDING!!!'))
}

const Product = () => {
  const history = useHistory()
  const { serialNumberId } = useParams<any>()

  const { t } = useTranslation()
  const classes = useStyles()
  const [sn, setSn] = useState<ISN>()
  const [RevProduct, setRevProduct] = useState<IRevProduct>()
  const [docs, setDocs] = useState<ILink[]>()

  const prefersDarkMode = false // useMediaQuery('(prefers-color-scheme: dark)')

  const { loading: snLoading, data: snData } = useQuery<
    ISNQuery,
    { id: number }
  >(SN, {
    variables: { id: serialNumberId },
  })

  const [
    getRevBom,
    { loading: revBomLoading, data: revBomData },
  ] = useLazyQuery<IRevBomQuery, { id: number }>(REVBOM, {
    variables: { id: snData?.salesOrdersQuery.serialNumber.revBomId ?? 0 },
  })
  const [getDocs, { loading: docsLoading, data: docsData }] = useLazyQuery<
    IDocsQuery,
    { id: number }
  >(GET_DOCS)

  useEffect(() => {
    if (snLoading) {
      return
    }

    setSn(snData?.salesOrdersQuery.serialNumber)

    getRevBom()
  }, [snData, snLoading])

  useEffect(() => {
    if (revBomLoading) {
      return
    }

    setRevProduct(revBomData?.bomsQuery.revProduct)
    if (revBomData?.bomsQuery.revProduct.boms) {
      const _mechBom = revBomData.bomsQuery.revProduct?.boms?.find(
        b => b.typeId == 1
      )
      if (_mechBom) getDocs({ variables: { id: _mechBom.id } })
    }
  }, [revBomData, revBomLoading])

  useEffect(() => {
    if (docsLoading) return

    setDocs(docsData?.bomsQuery.docs?.filter(d => d.rootDoc))
  }, [docsData, docsLoading])

  let _mecBom: IBom | undefined
  if (revBomData) {
    _mecBom = revBomData.bomsQuery.revProduct?.boms?.find(b => b.typeId == 1)
  }

  return (
    <Container maxWidth='lg' className={classes.container}>
      <Grid container spacing={3} className={classes.container}>
        <Grid item xs={4}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Typography variant='h5' gutterBottom>
                  {t<string>('salesOrder')}: {sn?.salesOrder} <br />
                  {t<string>('serialNumber')}: {sn?.number}
                </Typography>
                <br />
                <PreviewCard
                  url={RevProduct?.preview ?? ''}
                  height={300}
                  prefersDarkMode={prefersDarkMode}
                />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Typography variant='h5' gutterBottom>
                  {t('boms')}
                </Typography>
                <div className={classes.bomDiv}>
                  {_mecBom?.id && (
                    <Tooltip
                      title={t<string>('mechanicalBom')}
                      placement='bottom'
                      arrow
                    >
                      <Fab
                        color='primary'
                        onClick={() =>
                          history.push(
                            `/${
                              _mecBom?.viewableItem ? 'viewer' : 'machine'
                            }/${serialNumberId}/${_mecBom?.id ?? 0}`
                          )
                        }
                      >
                        <Icon className='fas fa-cog' />
                      </Fab>
                    </Tooltip>
                  )}
                  {sn?.eRevBomId ? (
                    <Tooltip
                      title={t<string>('electricBom')}
                      placement='bottom'
                      arrow
                    >
                      <Fab
                        color='secondary'
                        aria-label='Electric Bom'
                        onClick={() =>
                          history.push(`/ebom/${sn?.eRevBomId ?? 0}`)
                        }
                      >
                        <Icon className='fas fa-plug' />
                      </Fab>
                    </Tooltip>
                  ) : null}
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <Paper className={classes.paper}>
            <Typography variant='h5' gutterBottom>
              {t<string>('documents')}
            </Typography>
            <Grid container spacing={3}>
              {docs?.length ? (
                docs?.map((d: ILink, i) => (
                  <Grid item key={i}>
                    <Card className={classes.card}>
                      <CardActionArea
                        className={classes.cardAction}
                        title={`Download - ${d.urn}`}
                        onClick={() => downloadFile(d.documentId, d.urn)}
                      >
                        <PreviewCard
                          url={`${DOCS_URL}/document/${d.documentId}/thumbnail`}
                          height={350}
                          width={250}
                          prefersDarkMode={prefersDarkMode}
                        />
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))
              ) : revBomLoading || docsLoading ? (
                <Grid item>
                  <h2>{t<string>('loading')}</h2>
                </Grid>
              ) : (
                <Grid item>
                  <h2>{t<string>('noDocuments')}</h2>
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Product
