import React, { useEffect } from 'react'
import jwtDecode from 'jwt-decode'

interface IUserInfoContext {
  userInfo: IUserInfo
  setUserInfo: (arg: IUserInfo) => void
}

const defaultUserInfo: IUserInfo = {
  email: '',
  region: '',
  companyName: '',
  type: '',
  loginId: '',
  customerId: '',
  businessPartner: '',
  businessPartnerId: '',
  businessPartnerCode: '',
  crmCustomerId: '',
}

const UserInfoContext = React.createContext<IUserInfoContext>({
  userInfo: defaultUserInfo,
  setUserInfo: () => {},
})

type Props = {
  children: React.ReactNode
}

const UserInfoContextProvider = ({ children }: Props) => {
  const [userInfo, setUserInfo] = React.useState(defaultUserInfo)

  useEffect(() => {
    const _token = localStorage.getItem('token')
    if (_token) {
      const _userInfo = jwtDecode<IUserInfo>(_token)
      setUserInfo(_userInfo)
    }
  }, [])

  return (
    <UserInfoContext.Provider value={{ userInfo, setUserInfo }}>
      {children}
    </UserInfoContext.Provider>
  )
}

const useUserInfo = () => React.useContext(UserInfoContext)

export { UserInfoContext, UserInfoContextProvider, useUserInfo }
