export default {
  en: {
    translation: {
      both: 'Both',
      purchaseOrdersItems: 'Order details',
      component: 'Component',
      user: 'User',
      sendInvitationMail: 'Send invitation mail',
      save: 'Save',
      cancel: 'Cancel',
      details: 'Details',
      addSalesOrder: 'Add sales order',
      deleteSelectedSalesOrders: 'Delete selcted sales orders',
      salesOrdersWillDeleted: 'Sales order that will be deleted',
      userWillDeleted: 'Users that will be deleted',
      editUser: 'Edit user',
      addUser: 'Add user',
      deleteSelectedUsers: 'Delete selected users',
      userType: 'User type',
      deleteSelectedBp: 'Delete selected business partners',
      addSerialNumber: 'Add serial number',
      deleteSelectedSerialNumbers: 'Delete selected serial numbers',
      number: 'Number',
      firstPage: 'First page',
      nextPage: 'Next page',
      lastPage: 'Last page',
      rows: 'Rows',
      actions: 'Actions',
      name: 'Name',
      customer: 'Customer',
      addBp: 'Add business partner',
      editBp: 'Edit business partner',
      noRecords: 'No records to display',
      customerUser: 'Customer & Users',
      products: 'Products',
      emailAddress: 'Email Address',
      username: 'Username',
      password: 'Password',
      invalidCredentials: 'Invalid Credentials',
      login: 'Login',
      forgotPassword: 'Forgot your password?',
      addCustomLogo: 'Add custom logo',
      settings: 'Settings',
      users: 'Users',
      logout: 'Logout',
      addLogo: 'Add logo',
      selectFile: 'Select a file',
      reset: 'Reset',
      dragFile: 'Drag a file here',
      confirm: 'Confirm',
      backHome: 'Back to home',
      pdf: 'PDF',
      jsonAPI: 'Json API',
      all: 'All',
      file: 'File',
      files: 'Files',
      search: 'Search',
      creationDate: 'Creation date',
      revision: 'Revision',
      newFile: 'New file',
      revisionPlaceholder: 'Revision (default 0)',
      deleteDialog: 'Are you sure?',
      noSelectedRow: 'No selected row',
      deleteSelectedRow: 'Delete',
      bom: 'BOM',
      autoSet: 'Auto set',
      setFiles: 'Set files',
      newBom: 'New BOM',
      bomName: 'BOM name',
      bomValue: 'BOM value',
      delete: 'Delete',
      refresh: 'Refresh',
      reload: 'Reload',
      properties: 'Properties',
      property: 'Property',
      newSalesOrder: 'New sales order',
      new: 'New',
      add: 'Add',
      edit: 'Edit',
      editBom: 'Edit BOM',
      editSalesOrder: 'Edit sales order',
      newSerialNumber: 'New serial number',
      editSerialNumber: 'Edit serial number',
      back: 'Back',
      send: 'Send',
      imageView: 'Image view',
      listView: 'List view',
      cartEmpty: 'Your cart is empty',
      orderHistory: 'Order history',
      update: 'Update',
      upload: 'Upload',
      date: 'Date',
      from: 'From',
      to: 'To',
      items: 'Items',
      Item: 'Item',
      uploadUsers: 'Upload users',
      uploadUsersTempate: 'Upload users template',
      dragCsvFile: 'Drag a CSV file here',
      newUser: 'New user',
      companyName: 'Company Name',
      type: 'Type',
      region: 'Region',
      customerId: 'Customer ID',
      retailer: 'Retailer',
      verified: 'Verified',
      salesOrder: 'Sales order',
      salesOrders: 'Sales orders',
      serialNumber: 'Serial number',
      serialNumbers: 'Serial numbers',
      machine: 'Machine',
      spareParts: 'Spare parts',
      customProperty: 'Custom property',
      propertySpareParts: 'Property spare parts',
      propertyRecommendedSpareParts: 'Property recommended spare parts',
      propertyInseparableGroup: 'Property inseparable group',
      assignMachine: 'Assign machine',
      singleMachine: 'Single machine',
      allMachine: 'All machine',
      order: 'Order',
      orders: 'Orders',
      create: 'Create',
      selectPlaceholder: 'Select..',
      selectPropertyPlaceholder: 'Select Property..',
      salesOrderDescription: 'Select a sales order',
      serialNumberDescription: 'Select a serial number',
      machineDescription: 'Select a machine',
      customerDescription: 'Select a customer',
      settingsSaved: 'Settings saved',
      settingsSavedDescription: 'Settings saved correctly',
      sendOrder: 'Send order',
      noMachines: 'No machines found',
      fileName: 'File name',
      orderNotSent: 'Order not sent',
      errorOnSending: 'Error on sending',
      apiError: 'API error',
      errorSendingApi: 'Error on sending data to API',
      orderSent: 'Order sent',
      orderSentSuccess: 'Order sent successfully',
      orderNotSaved: 'Order not saved',
      errorSaving: 'Error on saving',
      quantity: 'Quantity',
      description: 'Description',
      parentNode: 'Parent node',
      total: 'Total',
      serial: 'Serial',
      remove: 'Remove',
      noDataToDisplay: 'No data to display',
      surname: 'Surname',
      model: 'Model',
      parent: 'Parent',
      level: 'Level',
      enable: 'Enable',
      partNumber: 'Part number',
      fromUser: 'From user',
      toUser: 'To user',
      value: 'Value',
      qty: 'Qty.',
      pcs: 'PCs.',
      treeModel: 'Tree model',
      infoSelectedNode: 'Info selected node',
      infoPanel: 'Info panel',
      cart: 'Cart',
      highlightSpareParts: 'Highligh spare parts',
      addRecommendedSparePartsToCart: 'Add recommended spare parts to cart',
      addSelectionToCart: 'Add selection to cart',
      hideSelectedElements: 'Hide selected elements',
      isolateSelectedElements: 'Isolate selected elements',
      explode: 'Explode',
      showHiddenElements: 'Show hidden elements',
      userMenu: 'User menu',
      pageSettings: 'Page settings',
      imageDeleted: 'Image deleted',
      uploadCompleted: 'Upload completed',
      addMachine: 'Add machine',
      regionEnglish: 'English',
      regionItalian: 'Italian',
      regionFrench: 'French',
      connectionError: 'Connection error',
      bucketNotDb: 'Bucket existing but not in db',
      bucketNotExisting: 'Bucket in db but not existing',
      bucketDeletedExisting: 'Bucket deleted but still existing',
      fileNotDb: 'File existing but not in db',
      fileNotExisting: 'File in db but not existing',
      fileDeletedExisting: 'File deleted but still existing',
      fileNullBucket: 'File with null bucket',
      manifestError: 'Error with the manifest',
      newBucket: 'New bucket',
      deleteBucket: 'Delete bucket',
      checkBucket: 'Check bucket',
      deleteFile: 'Delete file',
      checkFile: 'Check file',
      deleteBom: 'Delete BOM',
      buckets: 'Buckets',
      updateProperties: 'Update properties',
      deleteSalesOrder: 'Delete sales order',
      deleteSerialNumber: 'Delete serial number',
      key: 'Key',
      avaible: 'Avaible',
      bucket: 'Bucket',
      saveSettings: 'Save settings',
      loading: 'Loading..',
      errors: 'Errors',
      bucketName: 'Bucket name',
      transient: 'Transient',
      persistent: 'Persistent',
      temporary: 'Temporary',
      confirmPassword: 'Confirm password',
      propertyValue: 'Property value',
      settingsNotSaved: 'Settings not saved',
      settingsNotSavedDescription: 'Error while saving the settings',
      previousPage: 'Previous page',
      showAll: 'Show all elements',
      goToCart: 'Go to cart page',
      goToRoot: 'Go to root element',
      goBack: 'Go back',
      goForward: 'Go forward',
      enableFull: 'Enable full screen',
      disableFull: 'Disable full screen',
      backToParent: 'Back to parent ',
      colChildTable: 'Columns child table',
      vis: 'Visualization',
      infoCartItem: 'Info cart item',
      visImage: 'Image',
      visList: 'List',
      selectBucket: 'Select a bucket',
      fileRev: 'Revision (defualt 0)',
      dragText: 'Drag here a file or click to select it',
      language: 'Language',
      selectLanguage: 'Select a language',
      serialNumbersWillDeleted: 'Serial number that will be deleted',
      product: 'Product',
      goTo: 'Go to',
      documents: 'Documents',
      productsWillDeleted: 'Products that will be deleted',
      addProduct: 'Add product',
      goToDetailPage: 'Go to detail page',
      deleteSelectedProducts: 'Delete selected products',
      addDwf: 'Add DWF',
      changeVisualization: 'Change visualization',
      deleteSelectedDwf: 'Delete selcted DWF',
      dwfWillDeleted: 'DWF that will be deleted',
      documentsWillDeleted: 'Documents that willl be deleted',
      deleteSelectedDocuments: 'Delete selected documents',
      addDocument: 'Add document',
      extension: 'Extension',
      selectSalesOrder: 'Select sales order',
      selectSerialNumber: 'Select serial number',
      selectBusinessPartner: 'Select business partner',
      emailOrder: 'Email order',
      custom: 'Custom',
      port: 'Port',
      emailFrom: 'Email from',
      showItem: 'Show item',
      removeItem: 'Remove item',
      mechanicalBom: 'Mechanical bom',
      electricBom: 'Electric bom',
      noDocuments: 'No documents',
      documentsPanel: 'Documents panel',
      documentsSelectedNode: 'Documents selected node',
      isolateCartItems: 'Isolate cart items',
      noSelectedNode: 'No selected node',
      purchaseOrders: 'Purchase orders',
      loadPurchaseOrder: 'Load purchase order ',
      boms: 'Bill of materials',
      pnProp: 'Part number property',
      reportBug: 'Report a bug',
    },
  },
  it: {
    translation: {
      both: 'Entrambi',
      component: 'Componente',
      noSelectedNode: 'Nessun nodo selezionato',
      documentsSelectedNode: 'Documenti selezione',
      documentsPanel: 'Pannello documenti',
      noDocuments: 'Nessun documento',
      mechanicalBom: 'Bom meccanica',
      electricBom: 'Bom elettrica',
      showItem: 'Mostra elemento',
      removeItem: 'Rimuovi elemento',
      emailFrom: 'Email invio',
      port: 'Porta',
      emailOrder: 'Email ordine',
      custom: 'Personalizzato',
      selectSalesOrder: 'Seleziona ordine di vendita',
      selectSerialNumber: 'Seleziona numero di serie',
      selectBusinessPartner: 'Seleziona business partner',
      extension: 'Estensione',
      documentsWillDeleted: 'Documenti che verranno eliminati',
      deleteSelectedDocuments: 'Elimina documenti selezionati',
      addDocument: 'Aggiungi documento',
      productsWillDeleted: 'Prodotti che verranno eliminati',
      addProduct: 'Aggiungi prodotto',
      goToDetailPage: 'Vai al dettaglio',
      deleteSelectedProducts: 'Elimina prodotti selezionati',
      addDwf: 'Aggiungi DWF',
      changeVisualization: 'Cambia visualizzazione',
      deleteSelectedDwf: 'Elimina DWF selezionati',
      dwfWillDeleted: 'DWF che verranno eliminati',
      documents: 'Documenti',
      goTo: 'Vai a',
      product: 'Prodotto',
      user: 'Utente',
      serialNumbersWillDeleted: 'Numeri di serie che verranno eliminati',
      sendInvitationMail: 'Invia mail di invito',
      save: 'Salva',
      details: 'Dettaglio',
      addSalesOrder: 'Aggiungi ordine di vendita',
      deleteSelectedSalesOrders: 'Elimina ordini di vendita selezionati',
      salesOrdersWillDeleted: 'Ordini di vendita che verranno eliminati',
      userWillDeleted: 'Utenti che verranno eliminati',
      editUser: 'Modifica utente',
      addUser: 'Aggiungi utente',
      deleteSelectedUsers: 'Elimina utenti selezionati',
      userType: 'Tipo utente',
      deleteSelectedBp: 'Elimina business partner selezionati',
      addSerialNumber: 'Aggiungi numero di serie',
      deleteSelectedSerialNumbers: 'Elimina numeri di serie selezionati',
      number: 'Numero',
      firstPage: 'Prima pagina',
      nextPage: 'Pagina successiva',
      lastPage: 'Ultima pagina',
      rows: 'Righe',
      actions: 'Azioni',
      name: 'Nome',
      customer: 'Cliente',
      addBp: 'Aggiungi business partner',
      editBp: 'Modifica business partner',
      noRecords: 'Nessun dato da visualizzare',
      customerUser: 'Clienti e Utenti',
      products: 'Prodotti',
      emailAddress: 'Indirizzo Email',
      username: 'Utente',
      password: 'Password',
      invalidCredential: 'Credeziali non valide',
      login: 'Login',
      forgotPassword: 'Password dimenticata?',
      addCustomLogo: 'Aggiungi logo personalizzato',
      settings: 'Impostazioni',
      users: 'Utenti',
      logout: 'Logout',
      addLogo: 'Aggiungi logo',
      selectFile: 'Seleziona un file',
      reset: 'Reset',
      dragFile: 'Trascina un file qui',
      cancel: 'Annulla',
      confirm: 'Conferma',
      backHome: 'Torna alla home',
      pdf: 'PDF',
      jsonAPI: 'API Json',
      all: 'Tutti',
      file: 'File',
      files: 'File',
      search: 'Cerca',
      creationDate: 'Data di creazione',
      revision: 'Revisione',
      newFile: 'Nuovo file',
      revisionPlaceholder: 'Revisione (default 0)',
      deleteDialog: 'Sei sicuro?',
      noSelectedRow: 'Nessuna riga selezionata',
      deleteSelectedRow: 'Elimina',
      bom: 'BOM',
      autoSet: 'Settaggio automatico',
      setFiles: 'Imposta file',
      newBom: 'Nuova BOM',
      bomName: 'Nome BOM',
      bomValue: 'Valore BOM',
      delete: 'Elimina',
      refresh: 'Ricarica',
      reload: 'Ricarica',
      properties: 'Proprietà',
      property: 'Proprietà',
      newSalesOrder: 'Nuovo ordine di vendita',
      new: 'Nuovo',
      add: 'Aggiungi',
      edit: 'Modifica',
      editBom: 'Modifica BOM',
      editSalesOrder: 'Modifica ordine di vendita',
      newSerialNumber: 'Nuovo numero di serie',
      editSerialNumber: 'Modifica numero di serie',
      back: 'Indietro',
      send: 'Invia',
      imageView: 'Visualizzazione per immagini',
      listView: 'Visualizzazione per lista',
      cartEmpty: 'Il tuo carrello è vuoto',
      orderHistory: 'Storico ordini',
      update: 'Aggiorna',
      upload: 'Carica',
      date: 'Data',
      from: 'Mittente',
      to: 'Destinatario',
      items: 'Items',
      Item: 'Item',
      uploadUsers: 'Carica utenti',
      uploadUsersTempate: 'Template per caricamento utenti',
      dragCsvFile: 'Trascina un file CSV qui',
      newUser: 'Nuovo utente',
      companyName: 'Ragione sociale',
      type: 'Tipo',
      region: 'Regione',
      customerId: 'ID Cliente',
      retailer: 'Rivenditore',
      verified: 'Verificato',
      salesOrder: 'Ordine di vendita',
      salesOrders: 'Ordini di vendita',
      serialNumber: 'Numero di serie',
      serialNumbers: 'Numeri di serie',
      machine: 'Macchina',
      spareParts: 'Parti di ricambio',
      customProperty: 'Proprietà personalizzata',
      propertySpareParts: 'Proprietà parti di ricambio',
      propertyRecommendedSpareParts: 'Proprietà parti di ricambio consigliate',
      propertyInseparableGroup: 'Proprietà gruppi inseparabili',
      assignMachine: 'Assegna una macchina',
      singleMachine: 'Singola macchina',
      allMachine: 'Tutte le macchine',
      order: 'Ordine',
      orders: 'Ordini',
      create: 'Crea',
      selectPlaceholder: 'Seleziona..',
      selectPropertyPlaceholder: 'Seleziona una proprietà..',
      salesOrderDescription: 'Seleziona un ordine di vendita',
      serialNumberDescription: 'Seleziona un numero di serie',
      machineDescription: 'Seleziona una macchina',
      customerDescription: 'Seleziona un cliente',
      settingsSaved: 'Impostazioni salvate',
      settingsSavedDescription: 'Impostazioni salvate correttamente',
      sendOrder: 'Invia ordine',
      noMachines: 'Nessuna macchina trovata',
      fileName: 'Nome file',
      orderNotSent: 'Ordine non inviato',
      errorOnSending: "Errore durante l'invio",
      apiError: 'Error API',
      errorSendingApi: "Errore durante l'invio dei dati all'API",
      orderSent: 'Ordine inviato',
      orderSentSuccess: 'Ordini inviato correttamente',
      orderNotSaved: 'Ordine non salvato',
      errorSaving: 'Errore durante il salvataggio',
      quantity: 'Quantità',
      description: 'Descrizione',
      parentNode: 'Nodo padre',
      total: 'Totale',
      serial: 'Seriale',
      remove: 'Rimuovi',
      noDataToDisplay: 'Nessun dato da visualizzare',
      surname: 'Cognome',
      model: 'Modello',
      parent: 'Padre',
      level: 'Livello',
      enable: 'Abilita',
      partNumber: 'Numero parte',
      fromUser: 'Utente mittente',
      toUser: 'Utente destinatario',
      value: 'Valore',
      qty: 'Qta.',
      pcs: 'Pz.',
      treeModel: 'Albero componenti',
      infoSelectedNode: 'Informazioni selezione',
      infoPanel: 'Panello informazioni',
      cart: 'Carrello',
      highlightSpareParts: 'Evidenzia parti di ricambio',
      addRecommendedSparePartsToCart:
        'Aggiungi parti di ricambio consigliate al carrello',
      addSelectionToCart: 'Aggiungi selezione al carrello',
      hideSelectedElements: 'Nascondi elementi selezionati',
      isolateSelectedElements: 'Isola elementi selezionati',
      explode: 'Esplodi',
      showHiddenElements: 'Mostra elementi nascosti',
      userMenu: 'Menu utente',
      pageSettings: 'Impostazioni pagina',
      imageDeleted: 'Immagine cancellata',
      uploadCompleted: 'Caricamento completato',
      addMachine: 'Aggiungi macchina',
      regionEnglish: 'Inglese',
      regionItalian: 'Italiana',
      regionFrench: 'Francese',
      connectionError: 'Errore di connessione',
      bucketNotDb: 'Bucket esistente ma non nel database',
      bucketNotExisting: 'Bucket nel db ma non esistente',
      bucketDeletedExisting: 'Bucket eliminato ma ancora presente',
      fileNotDb: 'File esistente ma non nel db',
      fileNotExisting: 'File nel db ma non esistente',
      fileDeletedExisting: 'File eliminato ma ancora presente',
      fileNullBucket: 'File senza bucket',
      manifestError: 'Errore nel manifest',
      newBucket: 'Nuovo bucket',
      deleteBucket: 'Elimina bucket',
      checkBucket: 'Controlla bucket',
      deleteFile: 'Elimina file',
      checkFile: 'Controlla file',
      deleteBom: 'Elimina BOM',
      buckets: 'Bucket',
      updateProperties: 'Aggiorna proprietà',
      deleteSalesOrder: 'Elimina ordine di vendita',
      deleteSerialNumber: 'Eimina numero di serie',
      key: 'Chiave',
      avaible: 'Disponibile',
      saveSettings: 'Salva Impostazioni',
      loading: 'Caricamento..',
      errors: 'Errori',
      bucketName: 'Nome bucket',
      transient: 'Transitorio',
      persistent: 'Persistente',
      temporary: 'Temporaneo',
      confirmPassword: 'Conferma password',
      propertyValue: 'Valore proprietà',
      settingsNotSaved: 'Impostazioni non salvate',
      settingsNotSavedDescription:
        'Errore durante il salvataggio delle impostazioni',
      previousPage: 'Pagina precedente',
      showAll: 'Visualizza tutti gli elementi',
      goToCart: 'Vai alla pagina del carrello',
      goToRoot: 'Vai al primo elemento',
      goBack: 'Torna indietro',
      goForward: 'Vai avanti',
      enableFull: 'Attiva schermo intero',
      disableFull: 'Disattiva schermo intero',
      backToParent: 'Ritorna al padre',
      colChildTable: 'Colonne della tabella figli',
      vis: 'Visualizzazione',
      infoCartItem: 'Informazioni elementi nel carrello',
      visImage: 'Immagine',
      visList: 'Lista',
      selectBucket: 'Seleziona un bucket',
      fileRev: 'Revisione (predefinito 0)',
      dragText: 'Trascina qui un file o clicca per selezionarlo',
      language: 'Linguaggio',
      selectLanguage: 'Seleziona una lingua',
      isolateCartItems: 'Isola elementi del carrello',
      purchaseOrders: 'Richieste di offerta',
      loadPurchaseOrder: 'Carica richiesta di offerta',
      boms: 'Distinta componenti',
      pnProp: 'Proprietà numero parte',
      purchaseOrdersItems: 'Dettaglio offerta',
      reportBug: 'Segnala un bug',
    },
  },
  es: {
    translation: {
      username: 'Usuario',
      password: 'Contraseña',
      invalidCredentials: 'Credenciales no válidas',
      login: 'Iniciar sesión',
      forgotPassword: '¿Olvidaste tu contraseña?',
      addCustomLogo: 'Añadir logotipo personalizado',
      settings: 'Configuracion',
      users: 'Usuarios',
      logout: 'Cerrar sesión',
      addLogo: 'Agregar logo',
      selectFile: 'Seleccione un archivo',
      reset: 'Reiniciar',
      dragFile: 'Arrastra un archivo aquí',
      cancel: 'Cancelar',
      confirm: 'Confirmar',
      backHome: 'inicio',
      pdf: 'PDF',
      jsonAPI: 'API  Json',
      all: 'Todas',
      file: 'Archivo',
      files: 'Archivos',
      search: 'Buscar',
      name: 'Nombre',
      creationDate: 'Fecha de creación',
      revision: 'Revisión',
      newFile: 'Archivo nuevo',
      revisionPlaceholder: 'Revisión (por defecto 0)',
      deleteDialog: '¿Estás seguro?',
      noSelectedRow: 'Ninguna fila seleccionada',
      deleteSelectedRow: 'Eliminar',
      bom: 'BOM',
      autoSet: 'Ajuste automático',
      setFiles: 'Establecer archivos',
      newBom: 'Nueva lista de materiales',
      bomName: 'Nombre de la lista de materiales',
      bomValue: 'Valor de la lista de materiales',
      delete: 'Eliminar',
      refresh: 'Actualizar',
      reload: 'Recargar',
      properties: 'Propiedades',
      property: 'Propiedad',
      newSalesOrder: 'Nuevo pedido de ventas',
      new: 'Nuevo',
      add: 'Añadir',
      edit: 'Editar',
      editBom: 'Editar lista de materiales',
      editSalesOrder: 'Editar pedido de ventas',
      newSerialNumber: 'Nuevo número de serie',
      editSerialNumber: 'Editar número de serie',
      back: 'atrás',
      send: 'Enviar',
      imageView: 'Vista de la imagen',
      listView: 'Vista de lista',
      cartEmpty: 'Carrito vacío',
      orderHistory: 'Historial de pedidos',
      update: 'Actualizar',
      upload: 'Subir',
      date: 'Fecha',
      from: 'Desde',
      to: 'A',
      items: 'Artículos',
      Item: 'Articulo',
      uploadUsers: 'Cargar usuarios',
      uploadUsersTempate: 'Cargar plantilla de usuarios',
      dragCsvFile: 'Arrastra archivo CSV aquí',
      newUser: 'Nuevo Usuario',
      companyName: 'Nombre de empresa',
      type: 'Tipo',
      region: 'Región',
      customerId: 'Identificación del cliente',
      retailer: 'Detallista',
      verified: 'Verificado',
      salesOrder: 'Órdenes de venta',
      salesOrders: 'Ordenes de venta',
      serialNumber: 'Número de serie',
      serialNumbers: 'Números de serie',
      machine: 'Máquina',
      customer: 'Cliente',
      spareParts: 'Piezas de repuesto',
      customProperty: 'Propiedad personalizada',
      propertySpareParts: 'Repuestos de propiedad',
      propertyRecommendedSpareParts: 'Propiedad repuestos recomendados',
      propertyInseparableGroup: 'Grupo inseparable de propiedad',
      assignMachine: 'Asignar máquina',
      singleMachine: 'Sola máquina',
      allMachine: 'Todas las máquinas',
      order: 'Pedido',
      orders: 'Pedidos',
      create: 'Crear',
      selectPlaceholder: 'Seleccione..',
      selectPropertyPlaceholder: 'Seleccionar propiedad ..',
      salesOrderDescription: 'Seleccione un pedido de ventas',
      serialNumberDescription: 'Seleccione un número de serie',
      machineDescription: 'Seleccione una máquina',
      customerDescription: 'Seleccione un cliente',
      settingsSaved: 'Configuración guardada',
      settingsSavedDescription: 'Configuraciones guardadas correctamente',
      sendOrder: 'Enviar orden',
      noMachines: 'Ninguna maquinas',
      fileName: 'Nombre de archivo',
      orderNotSent: 'Pedido no enviado',
      errorOnSending: 'Error al enviar',
      apiError: 'Error de API',
      errorSendingApi: 'Error al enviar datos a la API',
      orderSent: 'Pedido enviado',
      orderSentSuccess: 'Pedido enviado con éxito',
      orderNotSaved: 'Orden no guardada',
      errorSaving: 'Error al guardar',
      quantity: 'Cantidad',
      description: 'Descripción',
      parentNode: 'Nodo principal',
      total: 'Total',
      serial: 'Serial',
      remove: 'Eliminar',
      noDataToDisplay: 'No hay información para mostrar',
      surname: 'Apellido',
      model: 'Modelo',
      parent: 'Padre',
      level: 'Nivel',
      enable: 'Habilitar',
      partNumber: 'Número de pieza',
      fromUser: 'Del usuario',
      toUser: 'Al usuario',
      value: 'Valor',
      qty: 'Cant.',
      pcs: 'PC',
      treeModel: 'Modelo de árbol',
      infoSelectedNode: 'Información seleccionada nodo',
      infoPanel: 'Panel de información',
      cart: 'Carro',
      highlightSpareParts: 'Repuestos destacados',
      addRecommendedSparePartsToCart:
        'Añadir repuestos recomendados al carrito',
      addSelectionToCart: 'Añadir selección al carrito',
      hideSelectedElements: 'Ocultar elementos seleccionados',
      isolateSelectedElements: 'Aísla elementos seleccionados',
      explode: 'Explotar',
      showHiddenElements: 'Mostrar elementos ocultos',
      userMenu: 'Menú del Usuario',
      pageSettings: 'Configuración de página',
      imageDeleted: 'Imagen eliminada',
      uploadCompleted: 'Subida completada',
      addMachine: 'Agregar máquina',
      regionEnglish: 'Inglés',
      regionItalian: 'italiano',
      regionFrench: 'francés',
      connectionError: 'Error de conexión',
      bucketNotDb: 'Contenedor existentes pero no en db',
      bucketNotExisting: 'Contenedor  en db pero no existentes',
      bucketDeletedExisting: 'Contenedor eliminados pero aún existentes',
      fileNotDb: 'Archivos existentes pero no en db',
      fileNotExisting: 'Archivos en db pero no existentes',
      fileDeletedExisting: 'Archivos eliminados pero aún existentes',
      fileNullBucket: 'Archivos con cubo nulo',
      manifestError: 'Error con el manifiesto',
      newBucket: 'Nuevo contenedor',
      deleteBucket: 'Eliminar contenedor',
      checkBucket: 'Comprobar contenedor',
      deleteFile: 'Borrar archivo',
      checkFile: 'Verificar archivo',
      deleteBom: 'Eliminar lista de materiales',
      buckets: 'Contenedor',
      updateProperties: 'Actualizar propiedades',
      deleteSalesOrder: 'Eliminar pedido de ventas',
      deleteSerialNumber: 'Eliminar número de serie',
      key: 'Key',
      avaible: 'Disponible',
      bucket: 'Contenedor',
      saveSettings: 'Guardar ajustes',
      loading: 'Cargando..',
      errors: 'Errores',
      bucketName: 'Nombre del contenedor',
      transient: 'Transitorio',
      persistent: 'Definitivo',
      temporary: 'Temporal',
      confirmPassword: 'Confirmar contraseña',
      propertyValue: 'Valor de la propiedad',
      settingsNotSaved: 'Configuraciones no guardadas',
      settingsNotSavedDescription: 'Error al guardar la configuración',
      previousPage: 'Pagina anterior',
      showAll: 'Mostrar todos los elementos',
      goToCart: 'Ir a la página del carrito',
      goToRoot: 'Ir al elemento raíz',
      goBack: 'Regresa',
      goForward: 'Avanzar',
      enableFull: 'Habilitar pantalla completa',
      disableFull: 'Desactivar pantalla completa',
      backToParent: 'Volver al nivel superior',
      colChildTable: 'Mesa secundaria de columnas',
      vis: 'Visualización',
      infoCartItem: 'Informacion del articulo en el carrito',
      visImage: 'Imagen',
      visList: 'Lista',
      selectBucket: 'Selecciona un cubo',
      fileRev: 'Revisión (defualt 0)',
      dragText: 'Arrastre aquí un archivo o haga clic para seleccionarlo',
      language: 'Idioma',
      selectLanguage: 'Selecciona un idioma',
    },
  },
}
