import React, { useState, useEffect } from 'react'
import { gql, StoreObject, useLazyQuery, useMutation } from '@apollo/client'

const defaultSettings: ISettingsApi = {
  id: null,
  customerId: -1,
  deleted: false,
  date: new Date(),
  value: {
    sendOrder: 'pdf',
    emailPdf: '',
    apiUri: '',
    smtp: 'default',
    emailFrom: '',
    smtpUrl: '',
    smtpPort: '',
    smtpUser: '',
    smtpPassword: '',
    treeFields: [],
    infoSelectedNode: [],
    spareparts: 'all',
    pnProp: '',
  },
}

interface ISettingsInput {
  id: number | null
  customerId: number
  value: string
  date: Date
  deleted: boolean
}

interface ISettingsQuery {
  settingsQuery: {
    settings: ISettingsInput
  }
}

interface ISettingsUpdate {
  settingsMutation: {
    updateSettings: StoreObject
  }
}

interface ISettingsCreate {
  settingsMutation: {
    createSettings: StoreObject
  }
}

interface ISettingsDelete {
  settingsMutation: {
    deleteSettings: StoreObject
  }
}

const GET = gql`
  query {
    settingsQuery {
      settings {
        customerId
        date
        deleted
        id
        value
      }
    }
  }
`

const POST = gql`
  mutation createSettings($settings: SettingsInput!) {
    settingsMutation {
      createSettings(settings: $settings) {
        id
      }
    }
  }
`

const UPDATE = gql`
  mutation updateSettings($id: Int!, $settings: SettingsInput!) {
    settingsMutation {
      updateSettings(id: $id, settings: $settings) {
        id
      }
    }
  }
`

const DELETE = gql`
  mutation deleteSettings($id: Int!) {
    settingsMutation {
      deleteSettings(id: $id) {
        id
      }
    }
  }
`

interface ISettingsContext {
  settings: ISettingsApi
  setSettings: (settings: ISettingsApi) => void
  reloadSettings: () => void
  updateSettings: (settings: ISettingsApi) => void
  createSettings: (settings: ISettingsApi) => void
  deleteSettings: (settings: ISettingsApi) => void
}

const SettingsContext = React.createContext<ISettingsContext>({
  settings: defaultSettings,
  setSettings: () => {},
  reloadSettings: () => {},
  updateSettings: () => {},
  createSettings: () => {},
  deleteSettings: () => {},
})

const SettingsContextProvider: React.FunctionComponent = ({ children }) => {
  const [settings, setSettings] = useState<ISettingsApi>(defaultSettings)

  const [getQuery] = useLazyQuery<ISettingsQuery>(GET, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data?.settingsQuery.settings) {
        setSettings({
          ...data?.settingsQuery.settings,
          value: JSON.parse(data?.settingsQuery.settings.value),
        })
      } else {
        setSettings(defaultSettings)
      }
    },
  })

  const [updateMutation] = useMutation<
    ISettingsUpdate,
    { id: number; settings: ISettingsInput }
  >(UPDATE, {
    onCompleted: () => getQuery(),
  })

  const [createMutation] = useMutation<
    ISettingsCreate,
    { settings: ISettingsInput }
  >(POST, {
    onCompleted: () => getQuery(),
  })

  const [deleteMutation] = useMutation<ISettingsDelete, { id: number }>(
    DELETE,
    {
      onCompleted: () => getQuery(),
    }
  )

  useEffect(() => {
    getQuery()
  }, [])

  const reloadSettings = () => {
    getQuery()
  }

  const updateSettings = (newSettings: ISettingsApi) => {
    updateMutation({
      variables: {
        id: newSettings.id ?? 0,
        settings: {
          id: newSettings.id,
          customerId: newSettings.customerId,
          date: newSettings.date,
          deleted: newSettings.deleted,
          value: JSON.stringify(newSettings.value),
        },
      },
    })
  }

  const createSettings = (newSettings: ISettingsApi) => {
    createMutation({
      variables: {
        settings: {
          id: newSettings.id,
          customerId: newSettings.customerId,
          date: newSettings.date,
          deleted: newSettings.deleted,
          value: JSON.stringify(newSettings.value),
        },
      },
    })
  }

  const deleteSettings = (deleteSettings: ISettingsApi) => {
    deleteMutation({
      variables: {
        id: deleteSettings.id ?? 0,
      },
    })
  }

  return (
    <SettingsContext.Provider
      value={{
        settings,
        setSettings,
        reloadSettings,
        updateSettings,
        createSettings,
        deleteSettings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  )
}

const useSettings = () => React.useContext(SettingsContext)

export { SettingsContext, SettingsContextProvider, useSettings }
