import React, { useState, useContext, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import JwtDecode from 'jwt-decode'
import axios from 'axios'

import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

import { CUSTOMERS_URL, SETTINGS_URL } from '../../consts/'
import {
  UserInfoContext,
  useSettings,
  useLogo,
  useCartItems,
} from '../../context'

import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const Login = () => {
  const classes = useStyles()
  const history = useHistory()
  const [inputData, setInputData] = useState({ email: '', password: '' })
  const [submitting, setSubmitting] = useState(false)

  const { setUserInfo } = useContext(UserInfoContext)
  const { setSettings } = useSettings()
  const { search } = useLocation()
  const { refreshImg } = useLogo()
  const { t } = useTranslation()
  const { reloadCartItems } = useCartItems()
  const { reloadSettings } = useSettings()

  useEffect(() => {
    const params = new URLSearchParams(search)
    const token = params.get('token') ?? ''
    if (token !== '') {
      localStorage.setItem('token', token)
      history.push('/')
      window.location.reload()
    }
  }, [])

  const handleChange = (event: any) => {
    const { name, value } = event.target
    setInputData((prevInputData) => ({ ...prevInputData, [name]: value }))
  }

  const submitLogin = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setSubmitting(true)

    return axios
      .post(CUSTOMERS_URL + '/auth/loginuser', inputData)
      .then(({ data }) => {
        if (data) {
          localStorage.setItem('token', data.Token)
          axios.defaults.headers.common['Authorization'] = 'JWT ' + data.Token

          const _userInfo = JwtDecode<IUserInfo>(data.Token)
          setUserInfo(_userInfo)
          refreshImg()
          reloadCartItems()
          reloadSettings()

          history.push('/', { from: 'login' })
        }
      })
      .catch(({ response }) => {
        let error
        if (response) {
          error = t('invalidCredentials')
        } else {
          error = t('connectionError')
        }

        setSubmitting(false)
        throw new Error(error).message
      })
  }

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          SPP
        </Typography>
        <form className={classes.form} noValidate onSubmit={submitLogin}>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='email'
            label={t('emailAddress')}
            name='email'
            autoComplete='email'
            autoFocus
            value={inputData.email}
            onChange={handleChange}
          />
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            name='password'
            label='Password'
            type='password'
            id='password'
            autoComplete='current-password'
            value={inputData.password}
            onChange={handleChange}
          />
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            className={classes.submit}
            disabled={submitting}
          >
            {t('login')}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href='/resetpassword' variant='body2'>
                {t('forgotPassword')}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  )
}

export default Login
