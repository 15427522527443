import React from 'react'
import { PDFViewer } from '@react-pdf/renderer'
import { useHistory } from 'react-router-dom'
import { Grid } from '@material-ui/core'

import { useLogo, useUserInfo, useCartItems, useSettings } from '../../context'
import { OrderPdf } from '../../components/pdf'
import { cartItemsToPdf, getColPdf } from '../../utils/pdf'

import { OrderBlob } from '../../components/pdf/order_blob'

import { sendNotification } from '../../utils'

import axios from 'axios'

import { GATEWAY_URL } from '../../consts/urls'
import { gql, StoreObject, useMutation } from '@apollo/client'

interface IDataRequest {
  data: FormData | { Value: string }
  headers: { contentType: string }
}

interface ISaveOrder {
  purchaseOrdersMutation: {
    saveOrder: StoreObject
  }
}

const SAVE_ORDER = gql`
  mutation saveOrder($po: PurchaseOrderInput!) {
    purchaseOrdersMutation {
      saveOrder(po: $po) {
        id
      }
    }
  }
`

const SendOrder = () => {
  const { userInfo } = useUserInfo()
  const { img } = useLogo()
  const { cartItems, reloadCartItems } = useCartItems()
  const { push } = useHistory()
  const { settings } = useSettings()

  const [saveOrder] = useMutation<ISaveOrder, { po: IPurchaseOrderInput }>(
    SAVE_ORDER,
    {
      onCompleted: () => {
        reloadCartItems()
        push(`/`)
      },
      onError: error => {
        sendNotification(
          'Order not saved',
          `Error while saving: ${error.message}`,
          'danger'
        )
      },
    }
  )

  let _itemsPdf
  let _colsPdf
  if (cartItems.length) {
    _itemsPdf = cartItemsToPdf(cartItems, userInfo)
    _colsPdf = getColPdf(userInfo)
  } else {
    push('/')
  }

  const prepareDataRequest = (blob: any) => {
    const _res: IDataRequest = {
      data: { Value: '' },
      headers: { contentType: 'application/json' },
    }
    if (blob) {
      const _file = new File([blob], 'SPP_RDO.pdf')

      const _formData = new FormData()
      _formData.append('file', _file)
      _formData.append('value', JSON.stringify(cartItems))

      _res.data = _formData
      _res.headers = { contentType: 'multipart/form-data' }
    } else {
      _res.data = { Value: JSON.stringify(cartItems) }
    }

    return _res
  }

  const sendOrder = (blob = null) => {
    if (cartItems.length) {
      const _settings = settings.value
      const _promises = []

      if (_settings && _settings.sendOrder !== 'pdf') {
        _promises.push(
          axios.post(_settings.apiUri, { value: JSON.stringify(cartItems) })
        )
      }

      if (!_settings || _settings.sendOrder !== 'json') {
        const _reqParams = prepareDataRequest(blob)
        _promises.push(
          axios.post(`${GATEWAY_URL}/purchaseorders/email`, _reqParams.data, {
            headers: _reqParams.headers,
          })
        )
      }

      Promise.all(_promises)
        .then(_ => {
          saveOrder({
            variables: {
              po: {
                customerId: parseInt(userInfo.customerId),
                businessPartnerId:
                  userInfo.businessPartnerId !== ''
                    ? parseInt(userInfo.businessPartnerId)
                    : undefined,
                userId: parseInt(userInfo.loginId),
                purchaseOrderItems: cartItems.map(item => ({
                  componentId: item.componentId,
                  componentCode: item.componentCode,
                  componentDescription: item.componentDescription ?? '',
                  quantity: item.quantity,
                  serialNumberId: item.serialNumberId,
                  revBomId: item.revBomId,
                  img: item.img,
                  dbId: item.dbId,
                  relationId: item.relationId,
                  machineUrl: item.machineUrl,
                })),
              },
            },
          })
        })
        .catch(() => {
          sendNotification('Order not saved', `Error while sending`, 'danger')
        })
    }
  }

  return _itemsPdf ? (
    <React.Fragment>
      <PDFViewer
        style={{
          width: '100vw',
          height: 'calc(100vh - 64px)',
          marginBottom: '-9px',
        }}
      >
        <OrderPdf
          items={_itemsPdf}
          userInfo={userInfo}
          cols={_colsPdf}
          img={img}
        />
      </PDFViewer>
      <OrderBlob
        sendOrder={sendOrder}
        orderPdfProps={{
          userInfo: userInfo,
          items: _itemsPdf,
          cols: _colsPdf,
          img: img,
        }}
      />
    </React.Fragment>
  ) : (
    <Grid container style={{ height: 'calc(100vh - 30px)' }}>
      <div
        style={{ margin: 'auto', display: 'flex', flexDirection: 'column' }}
        onClick={() => push('/')}
      >
        <i
          style={{
            fontSize: '102px',
            color: 'black',
            margin: 'auto',
          }}
          className='far fa-paper-plane'
        />
        <h1 style={{ margin: 'auto' }}>RDO sent!</h1>
      </div>
    </Grid>
  )
}

export { SendOrder }
