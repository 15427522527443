import React, { useState, useEffect } from 'react'
import {
  Container,
  Card,
  makeStyles,
  CardHeader,
  CardContent,
  Button,
  CardActions,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
} from '@material-ui/core'

import {
  Query,
  Builder,
  BasicConfig,
  Utils as QbUtils,
  ImmutableTree,
  Config,
  BuilderProps,
  Fields,
} from 'react-awesome-query-builder'
import 'react-awesome-query-builder/lib/css/styles.css'
import 'react-awesome-query-builder/lib/css/compact_styles.css' //optional, for more compact styles

import { Formik, Field, FieldProps as FormFieldProps } from 'formik'
import { useSettings, useUserInfo } from '../../context'

import { useTranslation } from 'react-i18next'
import { gql, useLazyQuery } from '@apollo/client'

const InitialConfig = BasicConfig // or BasicConfig

const useStyle = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
  },
  actions: {
    flexDirection: 'row-reverse',
  },
}))

const config = {
  ...InitialConfig,
  fields: {},
}

interface IInitValues {
  sendOrder: string
  emailPdf: string
  apiUri: string
  smtp: string
  emailFrom: string
  smtpUrl: string
  smtpPort: string
  smtpUser: string
  smtpPassword: string
  spareparts: string
  spp_prop: ImmutableTree
  spp_rec: ImmutableTree
  ins_group: ImmutableTree
  pnProp: string
}

interface IPropsQuery {
  bomsQuery: {
    bomProps: IProperty[]
  }
}

const GET = gql`
  query {
    bomsQuery {
      bomProps {
        key
      }
    }
  }
`

const Settings = () => {
  const [QueryConfig, setQueryConfig] = useState<Config>(config)
  const [InitValues, setInitValues] = useState<IInitValues>({
    sendOrder: 'pdf',
    emailPdf: '',
    apiUri: '',
    smtp: 'default',
    emailFrom: '',
    smtpUrl: '',
    smtpPort: '',
    smtpUser: '',
    smtpPassword: '',
    spareparts: 'all',
    pnProp: '',
    spp_prop: QbUtils.checkTree(
      QbUtils.loadTree({ id: QbUtils.uuid(), type: 'group' }),
      config
    ),
    spp_rec: QbUtils.checkTree(
      QbUtils.loadTree({ id: QbUtils.uuid(), type: 'group' }),
      config
    ),
    ins_group: QbUtils.checkTree(
      QbUtils.loadTree({ id: QbUtils.uuid(), type: 'group' }),
      config
    ),
  })

  const { t } = useTranslation()

  const { settings, updateSettings, createSettings } = useSettings()

  const {
    userInfo: { customerId },
  } = useUserInfo()
  const _classes = useStyle()

  const [getQuery] = useLazyQuery<IPropsQuery>(GET, {
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      if (data?.bomsQuery.bomProps) {
        const _fields: Fields = {}

        data?.bomsQuery.bomProps.forEach(d => {
          const _key = d.key.replace(/\./g, '|')
          _fields[_key] = {
            type: 'text',
            label: d.key,
            excludeOperators: [
              'equal',
              'not_equal',
              'proximity',
              'starts_with',
              'ends_with',
            ],
          }
        })

        const _newConfig = {
          ...QueryConfig,
          fields: _fields,
        }

        setQueryConfig(_newConfig)
      }
    },
  })

  useEffect(() => {
    getQuery()
  }, [])

  useEffect(() => {
    const _newInitValues: IInitValues = {
      ...InitValues,
      ...settings.value,
      spp_prop: QbUtils.checkTree(
        QbUtils.loadTree({ id: QbUtils.uuid(), type: 'group' }),
        config
      ),
      spp_rec: QbUtils.checkTree(
        QbUtils.loadTree({ id: QbUtils.uuid(), type: 'group' }),
        config
      ),
      ins_group: QbUtils.checkTree(
        QbUtils.loadTree({ id: QbUtils.uuid(), type: 'group' }),
        config
      ),
    }

    if (Object.keys(QueryConfig.fields).length) {
      if (settings.value.spp_prop)
        _newInitValues.spp_prop = QbUtils.checkTree(
          QbUtils.loadFromJsonLogic(settings.value.spp_prop, QueryConfig),
          QueryConfig
        )

      if (settings.value.spp_rec)
        _newInitValues.spp_rec = QbUtils.checkTree(
          QbUtils.loadFromJsonLogic(settings.value.spp_rec, QueryConfig),
          QueryConfig
        )

      if (settings.value.ins_group)
        _newInitValues.ins_group = QbUtils.checkTree(
          QbUtils.loadFromJsonLogic(settings.value.ins_group, QueryConfig),
          QueryConfig
        )
    }

    setInitValues(_newInitValues)
  }, [settings, QueryConfig])

  const renderBuilder = (props: BuilderProps) => (
    <div className='query-builder-container'>
      <div className='query-builder qb-lite'>
        <Builder {...props} />
      </div>
    </div>
  )

  const save = (values: IInitValues) => {
    const _sppPropValue =
      values.spareparts === 'prop' || values.spareparts === 'both'
        ? QbUtils.jsonLogicFormat(values.spp_prop, config)
        : {}
    const _sppRecValue = QbUtils.jsonLogicFormat(values.spp_rec, config)
    const _insGroupValue = QbUtils.jsonLogicFormat(values.ins_group, config)

    const _newSettings: ISettingsApi = {
      ...settings,
      value: {
        ...settings.value,
        ...values,
        spp_prop: _sppPropValue.logic ? _sppPropValue.logic : undefined,
        spp_rec: _sppRecValue.logic ? _sppRecValue.logic : undefined,
        ins_group: _insGroupValue.logic ? _insGroupValue.logic : undefined,
      },
    }

    if (settings.id) {
      updateSettings(_newSettings)
    } else {
      _newSettings.customerId = parseInt(customerId)
      createSettings(_newSettings)
    }
  }

  return (
    <Container className={_classes.container}>
      <Formik initialValues={InitValues} enableReinitialize onSubmit={save}>
        {({ values, setFieldValue, handleSubmit }) => {
          return (
            <form
              onSubmit={ev => {
                ev.preventDefault()
                const _submitButton = document.getElementById(
                  'submit-settings'
                ) as HTMLButtonElement
                if (_submitButton === document.activeElement) {
                  handleSubmit(ev)
                }
              }}
            >
              <Card className={_classes.paper}>
                <CardHeader title={t<string>('settings')} />
                <CardContent>
                  <Field name='sendOrder'>
                    {({ field }: FormFieldProps) => (
                      <FormControl
                        component='fieldset'
                        fullWidth
                        margin='normal'
                      >
                        <FormLabel component='legend'>
                          {t<string>('sendOrder')}
                        </FormLabel>
                        <RadioGroup
                          name={field.name}
                          value={field.value}
                          onChange={field.onChange}
                          row
                        >
                          <FormControlLabel
                            value='pdf'
                            control={<Radio />}
                            label='PDF'
                          />
                          <FormControlLabel
                            value='json'
                            control={<Radio />}
                            label='API Json'
                          />
                          <FormControlLabel
                            value='all'
                            control={<Radio />}
                            label='All'
                          />
                        </RadioGroup>
                      </FormControl>
                    )}
                  </Field>
                  {values.sendOrder !== 'json' && (
                    <Field name='emailPdf'>
                      {({ field }: FormFieldProps) => (
                        <TextField
                          label={t<string>('emailOrder')}
                          variant='outlined'
                          name={field.name}
                          value={field.value}
                          onChange={field.onChange}
                          fullWidth
                          margin='normal'
                        />
                      )}
                    </Field>
                  )}
                  {values.sendOrder !== 'pdf' && (
                    <Field name='apiUri'>
                      {({ field }: FormFieldProps) => (
                        <TextField
                          label='API uri'
                          variant='outlined'
                          value={field.value}
                          onChange={field.onChange}
                          fullWidth
                          margin='normal'
                          name={field.name}
                        />
                      )}
                    </Field>
                  )}
                  <Field name='smtp'>
                    {({ field }: FormFieldProps) => (
                      <FormControl
                        component='fieldset'
                        fullWidth
                        margin='normal'
                      >
                        <FormLabel component='legend'>SMTP</FormLabel>
                        <RadioGroup
                          name={field.name}
                          value={field.value}
                          onChange={field.onChange}
                          row
                        >
                          <FormControlLabel
                            value='default'
                            control={<Radio />}
                            label='Default'
                          />
                          <FormControlLabel
                            value='custom'
                            control={<Radio />}
                            label='Custom'
                          />
                        </RadioGroup>
                      </FormControl>
                    )}
                  </Field>
                  {values.smtp === 'custom' && (
                    <>
                      <Field name='emailFrom'>
                        {({ field }: FormFieldProps) => (
                          <TextField
                            label={t<string>('emailFrom')}
                            variant='outlined'
                            value={field.value}
                            onChange={field.onChange}
                            fullWidth
                            margin='normal'
                            name={field.name}
                          />
                        )}
                      </Field>
                      <Field name='smtpUrl'>
                        {({ field }: FormFieldProps) => (
                          <TextField
                            label='Server url'
                            variant='outlined'
                            value={field.value}
                            onChange={field.onChange}
                            fullWidth
                            margin='normal'
                            name={field.name}
                          />
                        )}
                      </Field>
                      <Field name='smtpPort'>
                        {({ field }: FormFieldProps) => (
                          <TextField
                            label={t<string>('port')}
                            variant='outlined'
                            value={field.value}
                            onChange={field.onChange}
                            fullWidth
                            margin='normal'
                            name={field.name}
                          />
                        )}
                      </Field>
                      <Field name='smtpUser'>
                        {({ field }: FormFieldProps) => (
                          <TextField
                            label={t<string>('user')}
                            variant='outlined'
                            value={field.value}
                            onChange={field.onChange}
                            fullWidth
                            margin='normal'
                            name={field.name}
                          />
                        )}
                      </Field>
                      <Field name='smtpPassword'>
                        {({ field }: FormFieldProps) => (
                          <TextField
                            label='Password'
                            variant='outlined'
                            value={field.value}
                            onChange={field.onChange}
                            fullWidth
                            margin='normal'
                            type='password'
                            name={field.name}
                          />
                        )}
                      </Field>
                    </>
                  )}
                  <Field name='pnProp'>
                    {({ field }: FormFieldProps) => (
                      <TextField
                        label={t<string>('pnProp')}
                        variant='outlined'
                        name={field.name}
                        value={field.value}
                        onChange={field.onChange}
                        fullWidth
                        margin='normal'
                      />
                    )}
                  </Field>
                  <Field name='spareparts'>
                    {({ field }: FormFieldProps) => (
                      <FormControl
                        component='fieldset'
                        fullWidth
                        margin='normal'
                      >
                        <FormLabel component='legend'>
                          {t<string>('spareParts')}
                        </FormLabel>
                        <RadioGroup
                          name={field.name}
                          value={field.value}
                          onChange={field.onChange}
                          row
                        >
                          <FormControlLabel
                            value='all'
                            control={<Radio />}
                            label='All'
                          />
                          <FormControlLabel
                            value='prop'
                            control={<Radio />}
                            label={t<string>('customProperty')}
                          />
                          <FormControlLabel
                            value='both'
                            control={<Radio />}
                            label={t<string>('both')}
                          />
                        </RadioGroup>
                      </FormControl>
                    )}
                  </Field>
                  {values.spareparts === 'prop' ||
                  values.spareparts === 'both' ? (
                    <Query
                      {...QueryConfig}
                      value={
                        values.spp_prop
                          ? values.spp_prop
                          : ({} as ImmutableTree)
                      }
                      onChange={(tree: ImmutableTree, config: Config) => {
                        setFieldValue('spp_prop', tree)
                      }}
                      renderBuilder={renderBuilder}
                    />
                  ) : (
                    <div />
                  )}
                  <FormControl component='fieldset' fullWidth margin='normal'>
                    <FormLabel component='legend'>
                      {t<string>('propertyRecommendedSpareParts')}
                    </FormLabel>
                    <Query
                      {...QueryConfig}
                      value={
                        values.spp_rec ? values.spp_rec : ({} as ImmutableTree)
                      }
                      onChange={(tree: ImmutableTree, config: Config) => {
                        setFieldValue('spp_rec', tree)
                      }}
                      renderBuilder={renderBuilder}
                    />
                  </FormControl>
                  <FormControl component='fieldset' fullWidth margin='normal'>
                    <FormLabel component='legend'>
                      {t<string>('propertyInseparableGroup')}
                    </FormLabel>
                    <Query
                      {...QueryConfig}
                      value={
                        values.ins_group
                          ? values.ins_group
                          : ({} as ImmutableTree)
                      }
                      onChange={(tree: ImmutableTree, config: Config) => {
                        setFieldValue('ins_group', tree)
                      }}
                      renderBuilder={renderBuilder}
                    />
                  </FormControl>
                </CardContent>
                <CardActions className={_classes.actions}>
                  <Button
                    id='submit-settings'
                    color='primary'
                    variant='outlined'
                    type='submit'
                  >
                    {t<string>('save')}
                  </Button>
                </CardActions>
              </Card>
            </form>
          )
        }}
      </Formik>
    </Container>
  )
}

export default Settings
