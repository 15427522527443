import React from 'react'
import Modal from '@material-ui/core/Modal'
import { makeStyles } from '@material-ui/core'

import { IModalTable } from './types'
import { BaseTable } from '../table'

const useStyles = makeStyles(theme => ({
  paperTableModal: {
    width: 1400,
    maxHeight: 800,
    marginTop: '10rem',
    overflow: 'auto',
    outline: 'none',
  },
  tableModal: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

const TableModal = ({ open, onClose, tableProps }: IModalTable) => {
  const _classes = useStyles()
  return (
    <Modal open={open} onClose={onClose} className={_classes.tableModal}>
      <div className={_classes.paperTableModal}>
        <BaseTable {...tableProps} />
      </div>
    </Modal>
  )
}

export default TableModal
