import React from 'react'
import { find } from 'lodash'

import { CartTitle } from './cart_title'
import { CartImage } from './cart_image'
import { useCartItems } from '../../context'
import { Grid, makeStyles, Container } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

interface ICartProps {
  showSettings: () => void
}

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}))

const Cart = ({ showSettings }: ICartProps) => {
  const { cartItems, updateCartItems } = useCartItems()

  const classes = useStyles()
  const { t } = useTranslation()
  const updateItem = (item: ICartItem, qty: number | null) => {
    if (qty && qty > 0) {
      const _items = cartItems
      const _item = {
        ..._items.find(
          (x) =>
            x.componentId === item.componentId &&
            x.serialNumberId === item.serialNumberId
        ),
      } as ICartItem
      if (_item) {
        _item.quantity = qty
        updateCartItems(_item)
      }
    }
  }

  return (
    <Container className={classes.container}>
      <CartTitle />
      <Grid container spacing={2} id='cart-container'>
        {cartItems?.length ? (
          <CartImage updateItem={updateItem} />
        ) : (
          <div id='empty-cart'>
            <h1>{t<string>('cartEmpty')}</h1>
          </div>
        )}
      </Grid>
    </Container>
  )
}

export default Cart
