import React from 'react'
import { TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '10px',
    paddingLeft: '14px',
    marginBottom: '10px',
  },
}))

interface ISearchSidebar {
  placeholder: string
  value: string
  handleChange: (arg0: React.ChangeEvent<HTMLInputElement>) => void
}

const SearchSidebar = ({ placeholder, handleChange, value }: ISearchSidebar) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div id='search-sidebar' className={classes.root}>
      <Typography variant='h6' gutterBottom>
        {t('treeModel')}
      </Typography>
      <TextField
        fullWidth
        placeholder={placeholder}
        onChange={handleChange}
        value={value}
      />
    </div>
  )
}

export default SearchSidebar
