import React from 'react'
import { SimpleModal } from '../modal'
import { ISimpleModal } from '../modal/types'

const ModalReportBug = (props: ISimpleModal) => {
  return (
    <SimpleModal {...props}>
      <iframe
        src='https://forms.monday.com/forms/embed/0154b559cf16a2e873929ced0e07b0d4'
        height='750'
        style={{
          border: 0,
          boxShadow: '5px 5px 56px 0px rgba(0,0,0,0.25)',
          width: '100%',
        }}
      ></iframe>
    </SimpleModal>
  )
}

export default ModalReportBug
