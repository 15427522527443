import { ID_TREE } from '../consts/viewer'
import { isolateItems, addToCartSS } from '../utils/viewer'
import i18n from '../i18next'

const $ = window.$
const Autodesk = window.Autodesk

interface IMatching {
  [key: number]: string
}

class MyContextMenu extends Autodesk.Viewing.UI.ObjectContextMenu {
  viewer: Autodesk.Viewing.Viewer3D
  FullScreen: boolean
  Matching: IMatching
  SetCart: (arg0: ICartItem[]) => void
  hiddenElements: number[]
  SparePartsIds: number[] | null

  constructor(viewer: Autodesk.Viewing.Viewer3D) {
    super(viewer)

    // this.contextMenu = new Autodesk.Viewing.Conte(viewer);
    this.viewer = viewer
    this.FullScreen = false
    this.Matching = {}
    this.SetCart = () => {}
    this.hiddenElements = []
    this.SparePartsIds = null

    this.viewAll = this.viewAll.bind(this)
    this.hideItems = this.hideItems.bind(this)
    this.addToCart = this.addToCart.bind(this)
  }

  focusItem(node: string) {
    const _tree = $(`#${ID_TREE}`).jstree(true)
    _tree.deselect_all()
    _tree.select_node(node)
  }

  addToCart() {
    const _selection = this.viewer.getSelection()
    const _isolatedNodes = this.viewer.getIsolatedNodes()
    const _hiddenNodes = this.viewer.getHiddenNodes()
    const _nodesId: number[] = []
    _selection.forEach(cmpId => {
      const _nodeId = this.Matching[cmpId]
      if (
        _nodeId &&
        _nodesId.indexOf(cmpId) === -1 &&
        (this.SparePartsIds == null || this.SparePartsIds.includes(cmpId))
      ) {
        _nodesId.push(cmpId)
      }
    })

    let _tree = $(`#${ID_TREE}`).jstree(true)
    if (!_tree) _tree = $('#spp-viewer-tree').jstree(true)
    if (!_tree) return

    // const _itemToAdd = _nodesId.map(id => {
    //   const _node = _tree.get_node(id);
    //   _node['parentUrn'] = this.viewer.model.myData.urn;
    //   return _node;
    // });
    // this.SetCart(_itemToAdd);

    this.viewer.select([])
    const that = this
    _nodesId.forEach(id => {
      const _node = _tree.get_node(this.Matching[id])
      this.viewer.isolate(id)
      this.viewer.fitToView([id], this.viewer.model, true)
      addToCartSS(this.viewer, _node, window.location.pathname, that.SetCart)
    })
    this.viewer.select(_selection)
    this.viewer.isolate(_isolatedNodes)
    this.viewer.hide(_hiddenNodes)
    this.viewer.fitToView(_isolatedNodes, this.viewer.model, true)
  }

  hideItems() {
    const _selection = this.viewer.getSelection()
    this.viewer.hide(_selection)
    this.hiddenElements = this.hiddenElements.concat(_selection)
  }

  viewAll() {
    // this.viewer.show(this.hiddenElements);
    let _isolatedItems = this.viewer.getIsolatedNodes()
    _isolatedItems = _isolatedItems.filter(
      node => !this.hiddenElements.includes(node)
    )
    this.viewer.isolate(_isolatedItems)
    this.hiddenElements = []
  }

  buildMenu(event: Event, status: Autodesk.Viewing.ContextMenuCallbackStatus) {
    // Get defulat menu items from the super class
    const _menu: Autodesk.Viewing.ContextMenuItem[] = []
    if (!this.FullScreen) return _menu

    if (status.hasSelected) {
      _menu.push({
        title: i18n.t('isolateSelectedElements'),
        target: isolateItems,
      })
      _menu.push({
        title: i18n.t('hideSelectedElements'),
        target: this.hideItems,
      })

      const _selection = this.viewer.getSelection()
      if (
        this.SparePartsIds === null ||
        _selection.some(
          dbId =>
            this.SparePartsIds !== null && this.SparePartsIds.includes(dbId)
        )
      ) {
        _menu.push({
          title: i18n.t('addSelectionToCart'),
          target: this.addToCart,
        })
      }
    }

    if (this.hiddenElements.length > 0) {
      _menu.push({
        title: i18n.t('showHiddenElements'),
        target: this.viewAll,
      })
    }
    return _menu
  }

  /**
   * @override
   */
  show(event: Event) {
    super.show(event)
  }
}

export default class MyContextMenuExtension extends Autodesk.Viewing.Extension {
  myMenu: MyContextMenu
  constructor(viewer: Autodesk.Viewing.GuiViewer3D, options: object) {
    super(viewer, options)

    this.myMenu = new MyContextMenu(this.viewer)
  }

  setFullScreen(fullScreen: boolean) {
    this.myMenu.FullScreen = fullScreen
  }

  setMatching(matching: IMatching) {
    this.myMenu.Matching = matching
  }

  setSetCart(setCart: (arg0: ICartItem[]) => void) {
    this.myMenu.SetCart = setCart
  }

  getHiddenElements() {
    return this.myMenu.hiddenElements
  }

  setHiddenElements(els: number[]) {
    this.myMenu.hiddenElements = els
  }

  setSparePartsIds(ids: number[]) {
    this.myMenu.SparePartsIds = ids
  }

  load() {
    // Use my owned context menu.
    this.viewer.setContextMenu(this.myMenu)
    return true
  }

  unload() {
    // Restore default context menu
    this.viewer.setContextMenu(
      new Autodesk.Viewing.UI.ObjectContextMenu(this.viewer)
    )
    return true
  }
}
