import React, { useEffect, useState } from 'react'
import { Button, TextField, Grid, Tooltip } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useCartItems } from '../../context'

import InfoIcon from '@material-ui/icons/Info'
import DeleteIcon from '@material-ui/icons/Delete'
import { useTranslation } from 'react-i18next'

interface ICartItemCommandsProps {
  item: ICartItem
  updateItem: (item: ICartItem, qty: number | null) => void
}

const CartItemCommands = ({ item, updateItem }: ICartItemCommandsProps) => {
  const { push } = useHistory()
  const { t } = useTranslation()
  const { removeCartItems } = useCartItems()
  const [quantity, setQuantity] = useState<string>(item.quantity.toString())

  useEffect(() => {
    if (quantity && parseInt(quantity) > 0) {
      const q = parseInt(quantity)
      updateItem(item, q)
    }
  }, [quantity])

  return (
    <>
      <Grid item xs={12}>
        <TextField
          label={t<string>('quantity')}
          type='number'
          inputProps={{
            min: 1,
          }}
          value={quantity}
          onChange={(e: React.FormEvent) => {
            const _target = e.target as HTMLInputElement
            setQuantity(_target.value.toString())
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Tooltip title={t<string>('showItem')} placement='bottom' arrow>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              if (item.dbId && item.machineUrl.includes('viewer')) {
                push(`${item.machineUrl}?ids=${item.dbId}`)
              } else if (item.machineUrl.includes('machine')) {
                push(`${item.machineUrl}?id=${item.relationId}`)
              } else {
                push(item.machineUrl)
              }
            }}
            startIcon={<InfoIcon />}
          >
            {t<string>('machine')}
          </Button>
        </Tooltip>
        <Tooltip title={t<string>('removeItem')} placement='bottom' arrow>
          <Button
            variant='contained'
            color='secondary'
            onClick={() => removeCartItems(item)}
          >
            <DeleteIcon />
          </Button>
        </Tooltip>
      </Grid>
    </>
  )
}

export { CartItemCommands }
