export const cartItemsToPdf = (cartItems, { settings = {}, companyName }) => {
  const _infoCartItem = settings.infoCartItem ? settings.infoCartItem : []

  const _res = []
  cartItems.forEach(item => {
    // const _props = _data.properties ? _data.properties : {};

    const _itemRes = [
      companyName === 'GAI'
        ? item.serialNumber?.salesOrder
        : item.serialNumber?.number,
      item.componentCode,
      item.componentDescription,
    ]

    // _infoCartItem.forEach(({value}) => {
    //   _itemRes.push(_props[value] ? _props[value] : '-');
    // })

    _itemRes.push(item.quantity)

    _res.push(_itemRes)
  })

  return _res
}

export const getColPdf = ({ settings = {} }) => {
  const _infoCartItem = settings.infoCartItem ? settings.infoCartItem : []
  const _res = ['Serial', 'Article', 'Description']

  _infoCartItem.forEach(({ value }) => _res.push(value))

  _res.push('Qty')
  return _res
}
