import React, { useEffect } from 'react'
import { gql, StoreObject, useLazyQuery, useMutation } from '@apollo/client'
import { isArray } from 'lodash'

interface ICartQuery {
  purchaseOrdersQuery: {
    cartItems: ICartItem[]
  }
}

interface ICartUpdate {
  purchaseOrdersMutation: {
    updateCartItem: StoreObject
  }
}

interface ICartCreate {
  purchaseOrdersMutation: {
    createCartItem: StoreObject
  }
}

interface ICartDelete {
  purchaseOrdersMutation: {
    deleteCartItem: StoreObject
  }
}

interface ICartInput {
  id: number
  cartId: number
  componentId: number
  componentCode: string
  componentDescription: string
  quantity: number
  serialNumberId: number
  revBomId: number
  img: string
  dbId: number
  relationId: number
  machineUrl: string
}

const GET = gql`
  query {
    purchaseOrdersQuery {
      cartItems: cart {
        id
        componentId
        componentCode
        componentDescription
        quantity
        serialNumberId
        revBomId
        img
        dbId
        relationId
        machineUrl
        serialNumber {
          number
          salesOrder
        }
      }
    }
  }
`

const POST = gql`
  mutation updateCartItem($cartItem: CartItemInput!) {
    purchaseOrdersMutation {
      createCartItem(cartItem: $cartItem) {
        id
      }
    }
  }
`

const UPDATE = gql`
  mutation updateCartItem($id: Int!, $cartItem: CartItemInput!) {
    purchaseOrdersMutation {
      updateCartItem(id: $id, cartItem: $cartItem) {
        id
      }
    }
  }
`

const DELETE = gql`
  mutation updateCartItem($id: Int!) {
    purchaseOrdersMutation {
      deleteCartItem(id: $id) {
        id
      }
    }
  }
`

const findById: (
  items: ICartItem[],
  componentdId: number,
  serialNumberId: number
) => ICartItem | null = (
  items: ICartItem[],
  componentId: number,
  serialNumberId: number
) => {
  let _res = null

  items.forEach(item => {
    if (
      item.componentId === componentId &&
      item.serialNumberId === serialNumberId
    ) {
      _res = item
    }
  })

  return _res
}

interface ICartItemsContext {
  cartItems: ICartItem[]
  setCartItems: (items: ICartItem[]) => void
  reloadCartItems: () => void
  updateCartItems: (items: ICartItem[] | ICartItem) => void
  addCartItems: (items: ICartItem[]) => void
  removeCartItems: (items: ICartItem) => void
}

const defaulCartItems: ICartItem[] = []

const CartItemsContext = React.createContext<ICartItemsContext>({
  cartItems: defaulCartItems,
  setCartItems: () => {},
  reloadCartItems: () => {},
  updateCartItems: () => {},
  addCartItems: () => {},
  removeCartItems: () => {},
})

type Props = {
  children: React.ReactNode
}

const CartItemsContextProvider = ({ children }: Props) => {
  const [cartItems, setCartItems] = React.useState<ICartItem[]>(defaulCartItems)

  const [getCartItems] = useLazyQuery<ICartQuery>(GET, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      if (data?.purchaseOrdersQuery.cartItems) {
        setCartItems(
          data?.purchaseOrdersQuery.cartItems.map(cartItem => ({
            ...cartItem,
          }))
        )
      } else {
        setCartItems([])
      }
    },
  })

  const [updateCartItem] = useMutation<
    ICartUpdate,
    { id: number; cartItem: ICartInput }
  >(UPDATE, {
    onCompleted: () => getCartItems(),
  })

  const [createCartItem] = useMutation<ICartCreate, { cartItem: ICartInput }>(
    POST,
    {
      onCompleted: () => getCartItems(),
    }
  )

  const [deleteCartItem] = useMutation<ICartDelete, { id: number }>(DELETE, {
    onCompleted: () => getCartItems(),
  })

  useEffect(() => {
    getCartItems()
  }, [])

  const reloadCartItems = () => {
    getCartItems()
  }

  const updateCartItems = (items: ICartItem[] | ICartItem) => {
    if (isArray(items)) {
      items.forEach(item => {
        updateCartItem({
          variables: {
            id: item.id ?? 0,
            cartItem: {
              id: item.id ?? 0,
              cartId: 0,
              componentId: item.componentId,
              componentCode: item.componentCode,
              componentDescription: item.componentDescription ?? '',
              quantity: item.quantity,
              serialNumberId: item.serialNumberId,
              revBomId: item.revBomId,
              img: item.img,
              dbId: item.dbId,
              relationId: item.relationId,
              machineUrl: item.machineUrl,
            },
          },
        })
      })
    } else {
      updateCartItem({
        variables: {
          id: items.id ?? 0,
          cartItem: {
            id: items.id ?? 0,
            cartId: 0,
            componentId: items.componentId,
            componentCode: items.componentCode,
            componentDescription: items.componentDescription ?? '',
            quantity: items.quantity,
            serialNumberId: items.serialNumberId,
            revBomId: items.revBomId,
            img: items.img,
            dbId: items.dbId,
            relationId: items.relationId,
            machineUrl: items.machineUrl,
          },
        },
      })
    }
  }

  const addCartItems = (newCartItems: ICartItem[]) => {
    const _cartItems = cartItems.map(cartItem => ({ ...cartItem }))

    newCartItems.forEach(item => {
      const _check = findById(_cartItems, item.componentId, item.serialNumberId)
      if (!_check) {
        item.quantity = 1
        createCartItem({
          variables: {
            cartItem: {
              id: item.id ?? 0,
              cartId: 0,
              componentId: item.componentId,
              componentCode: item.componentCode,
              componentDescription: item.componentDescription ?? '',
              quantity: item.quantity,
              serialNumberId: item.serialNumberId,
              revBomId: item.revBomId,
              img: item.img,
              dbId: item.dbId,
              relationId: item.relationId,
              machineUrl: item.machineUrl,
            },
          },
        })
      } else if (_check) {
        _check.quantity = _check.quantity + 1

        updateCartItem({
          variables: {
            id: _check.id ?? 0,
            cartItem: {
              id: _check.id ?? 0,
              cartId: 0,
              componentId: _check.componentId,
              componentCode: _check.componentCode,
              componentDescription: _check.componentDescription ?? '',
              quantity: _check.quantity,
              serialNumberId: _check.serialNumberId,
              revBomId: _check.revBomId,
              img: _check.img,
              dbId: _check.dbId,
              relationId: _check.relationId,
              machineUrl: _check.machineUrl,
            },
          },
        })
      }
    })
  }

  const removeCartItems = (deleteItem: ICartItem) => {
    const _cartItems = cartItems.map(cartItem => ({ ...cartItem }))
    const _updatedItems = _cartItems.filter(val => {
      const ret =
        val.componentId === deleteItem.componentId &&
        val.serialNumberId === deleteItem.serialNumberId

      return ret
    })

    _updatedItems.forEach(item => {
      deleteCartItem({
        variables: {
          id: item.id ?? 0,
        },
      })
    })
  }

  return (
    <CartItemsContext.Provider
      value={{
        cartItems,
        setCartItems,
        reloadCartItems,
        updateCartItems,
        addCartItems,
        removeCartItems,
      }}
    >
      {children}
    </CartItemsContext.Provider>
  )
}

const useCartItems = () => React.useContext(CartItemsContext)

export { CartItemsContext, CartItemsContextProvider, useCartItems }
