import React, { useEffect, useState } from 'react'
import {
  Document,
  StyleSheet,
  Text,
  View,
  Page,
  Image,
} from '@react-pdf/renderer'

import LOGO_NKE from '../../icons/NKE_Autodesk.png'

const BORDER_COLOR = '#bfbfbf'
const TEXT_COLOR = '#555'
const BORDER_STYLE = 'solid'
// const COL1_WIDTH = 55
// const COLN_WIDTH = (100 - COL1_WIDTH) / 3
// const COL_LG = 35
// const COL_SM = 15

const _now = new Date()

const OrderPdf = ({ userInfo, img, items, cols }) => {
  const _image = img !== '' ? `data:image/png;base64,${img}` : LOGO_NKE
  const _colQtyWidth = 5
  const _colWidth = (100 - _colQtyWidth) / (cols.length - 1)

  const styles = StyleSheet.create({
    body: {
      padding: 10,
    },
    table: {
      display: 'table',
      width: 'auto',
      borderStyle: BORDER_STYLE,
      borderColor: BORDER_COLOR,
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0,
    },
    tableRow: {
      margin: 'auto',
      flexDirection: 'row',
    },
    tableColQtyHeader: {
      width: _colQtyWidth + '%',
      borderStyle: BORDER_STYLE,
      borderColor: BORDER_COLOR,
      borderBottomColor: '#000',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      textAlign: 'center',
    },
    tableColHeader: {
      width: _colWidth + '%',
      borderStyle: BORDER_STYLE,
      borderColor: BORDER_COLOR,
      borderBottomColor: '#000',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    tableColQty: {
      width: _colQtyWidth + '%',
      borderStyle: BORDER_STYLE,
      borderColor: BORDER_COLOR,
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      textAlign: 'center',
    },
    tableCol: {
      width: _colWidth + '%',
      borderStyle: BORDER_STYLE,
      borderColor: BORDER_COLOR,
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    tableCellHeader: {
      margin: 5,
      fontSize: 12,
      fontWeight: 500,
    },
    tableCell: {
      margin: 5,
      fontSize: 10,
      color: TEXT_COLOR,
    },
    title: {
      textAlign: 'center',
      fontSize: 22,
      marginBottom: 20,
      marginTop: 20,
    },
    userInfo: {
      textAlign: 'left',
      fontSize: 10,
      color: TEXT_COLOR,
      marginBottom: 5,
    },
    userInfoTitle: {
      color: 'black',
      fontSize: 11,
    },
    containerUserInfo: {
      marginBottom: 15,
    },
    logo: {
      maxWidth: '200px !important',
      maxHeight: '60px !important',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  })

  return (
    <Document title='RDO_SPP'>
      <Page style={styles.body}>
        <Image src={_image} style={styles.logo} />
        <Text style={styles.title}>SPAREPARTS ORDER REQUEST</Text>
        <View style={styles.containerUserInfo}>
          <Text style={styles.userInfo}>
            <Text style={styles.userInfoTitle}>User: </Text>
            {userInfo.email ? userInfo.email : '-'}
          </Text>
          <Text style={styles.userInfo}>
            <Text style={styles.userInfoTitle}>Customer id: </Text>
            {userInfo.businessPartner ? userInfo.businessPartner : '-'}
          </Text>
          <Text style={styles.userInfo}>
            <Text style={styles.userInfoTitle}>Date: </Text>
            {_now.toLocaleString()}
          </Text>
        </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            {cols.map((col, i) => {
              let _style =
                i === cols.length - 1
                  ? styles.tableColQtyHeader
                  : styles.tableColHeader

              return (
                <View key={i} style={_style}>
                  <Text style={styles.tableCellHeader}>{col}</Text>
                </View>
              )
            })}
          </View>
          {items.map((vals, i) => {
            return (
              <View key={i} style={styles.tableRow}>
                {vals.map((val, j) => {
                  let _style =
                    j === cols.length - 1 ? styles.tableColQty : styles.tableCol

                  return (
                    <View style={_style} key={`${i}-${j}`}>
                      <Text style={styles.tableCell}>{val}</Text>
                    </View>
                  )
                })}
              </View>
            )
          })}
        </View>
      </Page>
    </Document>
  )
}

export { OrderPdf }
