import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  makeStyles,
  createStyles,
  TextField,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Switch,
} from '@material-ui/core'

import { useSettings } from '../../context'
import { gql, useLazyQuery } from '@apollo/client'
import { Autocomplete } from '@material-ui/lab'

interface IPropsQuery {
  bomsQuery: {
    bomProps: IProperty[]
  }
}

const BOMPROPS = gql`
  query {
    bomsQuery {
      bomProps {
        key
      }
    }
  }
`

interface IDialogSettings {
  open: boolean
  onClose: () => void
  customerId: number
}

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
    },
    inputAutocomplete: {
      paddingTop: '27px !important',
      paddingBottom: '10px',
    },
    autocomplete: {
      marginBottom: theme.spacing(2),
      minWidth: 350,
    },
  })
)

const Dialogsettings = ({ open, onClose, customerId }: IDialogSettings) => {
  const [Props, setProps] = useState<string[]>([])
  const [TreeValues, setTreeValues] = useState<string[]>([])
  const [SelectedValues, setSelectedValues] = useState<string[]>([])
  const [OpenTree, setOpenTree] = useState(true)
  const [OpenDoc, setOpenDoc] = useState(false)
  const [OpenInfo, setOpenInfo] = useState(false)
  const _classes = useStyles()
  const { updateSettings, createSettings, settings } = useSettings()

  const [getBomProps] = useLazyQuery<IPropsQuery>(BOMPROPS, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      const _props = data.bomsQuery.bomProps.map(prop => {
        return prop.key
      })

      setProps(_props)
    },
  })

  useEffect(() => {
    getBomProps()
  }, [])

  useEffect(() => {
    setTreeValues(settings?.value.treeFields ?? [])
    setSelectedValues(settings?.value.infoSelectedNode ?? [])

    const _openTree = settings?.value.openTree
    setOpenTree(_openTree !== undefined ? _openTree : true)

    setOpenDoc(Boolean(settings?.value.openDoc))
    setOpenInfo(Boolean(settings?.value.openInfo))
  }, [settings])

  const saveSettings = () => {
    let _newSettings: ISettingsApi

    if (settings.id) {
      _newSettings = {
        ...settings,
        value: {
          ...settings.value,
          treeFields: TreeValues,
          infoSelectedNode: SelectedValues,
          openTree: OpenTree,
          openDoc: OpenDoc,
          openInfo: OpenInfo,
        },
      }
      updateSettings(_newSettings)
    } else {
      _newSettings = {
        id: null,
        date: new Date(),
        deleted: false,
        customerId: customerId,
        value: {
          ...settings.value,
          treeFields: TreeValues,
          infoSelectedNode: SelectedValues,
          openTree: OpenTree,
          openDoc: OpenDoc,
          openInfo: OpenInfo,
        },
      }
      createSettings(_newSettings)
    }

    onClose()
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='simple-Dialog-title'
      aria-describedby='simple-modal-description'
    >
      <DialogTitle>PAGE SETTINGS</DialogTitle>
      <DialogContent>
        <form className={_classes.container}>
          <FormControl component='fieldset' className={_classes.autocomplete}>
            <FormLabel component='legend'>Open on start</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={OpenTree}
                    onChange={(_: any, checked: boolean) =>
                      setOpenTree(checked)
                    }
                    name='gilad'
                  />
                }
                label='Tree panel'
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={OpenDoc}
                    onChange={(_: any, checked: boolean) => setOpenDoc(checked)}
                    name='jason'
                  />
                }
                label='Document panel'
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={OpenInfo}
                    onChange={(_: any, checked: boolean) =>
                      setOpenInfo(checked)
                    }
                    name='antoine'
                  />
                }
                label='Node info panel'
              />
            </FormGroup>
          </FormControl>
          <Autocomplete
            id='select-option-tree'
            fullWidth
            multiple
            value={TreeValues}
            onChange={(_: any, newValue: string[]) => {
              setTreeValues(newValue)
            }}
            options={Props}
            ChipProps={{
              color: 'primary',
            }}
            className={_classes.autocomplete}
            classes={{
              inputRoot: _classes.inputAutocomplete,
            }}
            renderInput={params => (
              <TextField {...params} variant='filled' label='Tree model' />
            )}
            disableCloseOnSelect
          />
          <Autocomplete
            id='select-option-node'
            fullWidth
            multiple
            value={SelectedValues}
            onChange={(_: any, newValue: string[]) => {
              setSelectedValues(newValue)
            }}
            options={Props}
            ChipProps={{
              color: 'primary',
            }}
            className={_classes.autocomplete}
            classes={{
              inputRoot: _classes.inputAutocomplete,
            }}
            renderInput={params => (
              <TextField
                {...params}
                variant='filled'
                label='Info selected node'
              />
            )}
            disableCloseOnSelect
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant='outlined'>
          Cancel
        </Button>
        <Button onClick={saveSettings} color='primary' variant='outlined'>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default Dialogsettings
