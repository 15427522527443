import React, { FunctionComponent, useState, useEffect } from 'react'
import { makeStyles, CardMedia, CircularProgress, Box } from '@material-ui/core'
import axios from 'axios'

import { Document, Page } from 'react-pdf'
import machine from '../../images/machinery-production-614374.svg'
import machineWhite from '../../images/machinery-production-614374 - white.svg'

const useStyles = makeStyles((theme) => ({
  loadingContent: {
    display: 'flex',
  },
  loading: {
    margin: 'auto',
  },
  cardMedia: {
    objectFit: 'scale-down',
    margin: '0 auto',
  },
}))

interface IPreviewCard {
  url: string
  width?: number
  height?: number
  prefersDarkMode: boolean
}

const PreviewCard: FunctionComponent<IPreviewCard> = ({
  url,
  width,
  height,
  prefersDarkMode,
}) => {
  const [Loading, setLoading] = useState(false)
  const [Image, setImage] = useState('')
  const [isPdf, setIsPdf] = useState(false)
  const _classes = useStyles()

  useEffect(() => {
    if ((url ?? '') !== '') {
      setLoading(true)
      setImage('')
      setIsPdf(false)

      axios
        .get(url)
        .then(({ data, headers }) => {
          if (headers['content-type'] === 'application/pdf') {
            setIsPdf(true)
            setImage(`data:application/pdf;base64,${data}`)
          } else {
            setImage(
              `data:image/gif;base64,${data._buffer ? data._buffer : data}`
            )
          }
        })
        .finally(() => setLoading(false))
    } else {
      if (prefersDarkMode) {
        setImage(machineWhite)
      } else {
        setImage(machine)
      }

      setIsPdf(false)
    }
  }, [url])

  return (
    <Box className={_classes.loadingContent} width={width} height={height}>
      {Loading ? (
        <CircularProgress className={_classes.loading} />
      ) : isPdf ? (
        <Document file={Image} className={_classes.cardMedia}>
          <Page
            width={width}
            height={width === undefined ? height : undefined}
            pageNumber={1}
          />
        </Document>
      ) : (
        <CardMedia
          className={_classes.cardMedia}
          component='img'
          image={Image}
        />
      )}
    </Box>
  )
}

export { PreviewCard }
