import React, { useState, useEffect } from 'react'

import Autocomplete from '@material-ui/lab/Autocomplete'

import {
  makeStyles,
  TextField,
  Container,
  Grid,
  Card,
  CardActionArea,
  CardContent,
  Typography,
  CircularProgress,
  Icon,
} from '@material-ui/core'

import { useHistory } from 'react-router-dom'
import { useLogo, useUserInfo } from '../../context'

import { PreviewCard } from '../../components/preview_card'
import { TitleCard } from '../../components/title_card'

import './home.scss'
import logoNke from '../../images/Logo.png'
import { useTranslation } from 'react-i18next'
import { gql, useQuery } from '@apollo/client'

import InfiniteScroll from 'react-infinite-scroller'

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  cardAction: {
    '&:hover': {
      background: theme.palette.secondary.light,
    },
    padding: theme.spacing(2),
  },
  cardContent: {
    minHeight: 100,
  },
  logo: {
    textAlign: 'center',
  },
  searchResultsBox: {
    height: 'calc(100vh - 400px)',
    overflowY: 'scroll',
  },
  infiniteScroll: {
    display: 'flex',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
  },
  searchResultsDiv: {
    padding: 12,
    flexGrow: 0,
    maxWidth: '25%',
    flexBasis: '25%',
    margin: 0,
    boxSizing: 'border-box',
    display: 'block',
  },
}))

interface ISNQuery {
  salesOrdersQuery: {
    serialNumbers: ISN[] | ISN
  }
  customersQuery: {
    businessPartners: IBP[]
  }
  bomsQuery: {
    revProducts: IRevProduct[]
  }
}

interface IComboQuery {
  salesOrdersQuery: {
    salesOrders: ISelect[]
    serialNumbers: ISelect[]
  }
  customersQuery: {
    businessPartners: ISelect[]
  }
}

const SEARCH = gql`
  query search($admin: Boolean!) {
    salesOrdersQuery {
      serialNumbers {
        id
        number
        salesOrder
        revBomId
        businessPartnerId
      }
    }
    customersQuery {
      businessPartners @include(if: $admin) {
        id
        name
      }
    }
    bomsQuery {
      revProducts {
        id
        preview
      }
    }
  }
`

const SEARCH_SO = gql`
  query searchBySo($id: Int!, $admin: Boolean!) {
    salesOrdersQuery {
      serialNumbers: serialNumbersBySo(id: $id) {
        id
        number
        salesOrder
        revBomId
        businessPartnerId
      }
    }
    customersQuery {
      businessPartners @include(if: $admin) {
        id
        name
      }
    }
    bomsQuery {
      revProducts {
        id
        preview
      }
    }
  }
`

const SEARCH_SN = gql`
  query searchBySn($id: Int!, $admin: Boolean!) {
    salesOrdersQuery {
      serialNumbers: serialNumber(id: $id) {
        id
        number
        salesOrder
        revBomId
        businessPartnerId
      }
    }
    customersQuery {
      businessPartners @include(if: $admin) {
        id
        name
      }
    }
    bomsQuery {
      revProducts {
        id
        preview
      }
    }
  }
`

const SEARCH_BP = gql`
  query searchByBp($id: Int!, $admin: Boolean!) {
    salesOrdersQuery {
      serialNumbers: serialNumbersByBp(id: $id) {
        id
        number
        salesOrder
        revBomId
        businessPartnerId
      }
    }
    customersQuery {
      businessPartners @include(if: $admin) {
        id
        name
      }
    }
    bomsQuery {
      revProducts {
        id
        preview
      }
    }
  }
`

const COMBO = gql`
  query combo($admin: Boolean!) {
    salesOrdersQuery {
      salesOrders {
        value: id
        label: number
      }
      serialNumbers {
        value: id
        label: number
      }
    }
    customersQuery {
      businessPartners @include(if: $admin) {
        value: id
        label: name
      }
    }
  }
`

export default function Home() {
  const classes = useStyles()
  const history = useHistory()
  const { img } = useLogo()
  const { userInfo } = useUserInfo()

  const [open, setOpen] = useState(false)
  const [searchType, setSearchType] = useState<
    'salesorders' | 'serialnumbers' | 'bp'
  >('salesorders')
  const [selectedValue, setSelectedValue] = useState<ISelect | null>(null)
  const [searchResults, setSearchResults] = useState<ISN[]>([])
  const [loadedResults, setLoadedResults] = useState<ISN[]>([])

  const changeSearch = (type: 'salesorders' | 'serialnumbers' | 'bp') => {
    setSelectedValue(null)
    setSearchType(type)
  }

  const perPage = 8

  const loadSearchResult = () => {
    console.log('scroll')
    setLoadedResults(prev => {
      const add: ISN[] = searchResults.slice(
        loadedResults.length,
        loadedResults.length + perPage
      )
      return [...prev, ...add]
    })
  }

  const { t } = useTranslation()

  let searchQuery = SEARCH
  let searchId = 0

  if (selectedValue) {
    if (searchType === 'salesorders') {
      searchQuery = SEARCH_SO
    } else if (searchType === 'serialnumbers') {
      searchQuery = SEARCH_SN
    } else {
      searchQuery = SEARCH_BP
    }
    searchId = selectedValue.value
  } else {
    searchQuery = SEARCH
  }

  const { loading: searchLoading, refetch: searchRefetch } = useQuery<
    ISNQuery,
    { id: number; admin: boolean }
  >(searchQuery, {
    variables: {
      id: searchId,
      admin: userInfo.type === '1' || userInfo.type === '2',
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      let res: ISN[] = []
      if (!Array.isArray(data.salesOrdersQuery.serialNumbers)) {
        res.push({ ...(data.salesOrdersQuery.serialNumbers as ISN) })
      } else {
        res = (data.salesOrdersQuery.serialNumbers as ISN[]).map(item => ({
          ...item,
        }))
      }

      const _bps = data.customersQuery.businessPartners ?? []
      const _previews = data.bomsQuery.revProducts ?? []

      res = res.map(sn => {
        sn.businessPartner =
          _bps.find(bp => bp.id === sn.businessPartnerId)?.name ?? ''

        sn.preview = _previews.find(p => p.id === sn.revBomId)?.preview ?? ''
        return sn
      })

      setSearchResults(res)
    },
  })

  const { loading: comboLoading, data: comboData } = useQuery<
    IComboQuery,
    { admin: boolean }
  >(COMBO, {
    variables: { admin: userInfo.type === '1' || userInfo.type === '2' },
  })

  useEffect(() => {
    searchRefetch()
  }, [searchRefetch])

  useEffect(() => {
    setLoadedResults([])
  }, [searchResults])

  const prefersDarkMode = false // useMediaQuery('(prefers-color-scheme: dark)')

  const cardClick = (id: number) => {
    history.push(`/product/${id}`)
  }

  return (
    <Container maxWidth='lg' className={classes.container}>
      <Grid container justify='center' alignItems='center' spacing={3}>
        <Grid item xs={12} className={classes.logo}>
          {img !== '' ? (
            <img
              src={`data:image/png;base64,${img}`}
              alt='logo'
              style={{ maxHeight: '60px' }}
            />
          ) : (
            <img src={logoNke} alt='logo' style={{ maxHeight: '60px' }} />
          )}
        </Grid>
        <br></br>
        <Grid item onClick={() => changeSearch('salesorders')}>
          <TitleCard
            selected={searchType === 'salesorders' ? true : false}
            title={t<string>('salesOrders')}
            icon={<Icon className='fas fa-tag' />}
          ></TitleCard>
        </Grid>
        <Grid item onClick={() => changeSearch('serialnumbers')}>
          <TitleCard
            selected={searchType === 'serialnumbers' ? true : false}
            title={t<string>('serialNumbers')}
            icon={<Icon className='fas fa-qrcode' />}
          ></TitleCard>
        </Grid>
        {userInfo.type === '1' || userInfo.type === '2' ? (
          <Grid item onClick={() => changeSearch('bp')}>
            <TitleCard
              selected={searchType === 'bp' ? true : false}
              title='Business Partners'
              icon={<Icon className='fas fa-suitcase' />}
            ></TitleCard>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <Autocomplete
            id='select-combo'
            fullWidth
            open={open}
            onOpen={() => {
              setOpen(true)
            }}
            onClose={() => {
              setOpen(false)
            }}
            value={selectedValue}
            onChange={(event: any, newValue: ISelect | null) => {
              setSelectedValue(newValue)
            }}
            getOptionSelected={(option: ISelect, value: ISelect) => {
              return option?.value === value?.value
            }}
            getOptionLabel={(option: ISelect) => option?.label}
            options={
              searchType === 'salesorders'
                ? comboData?.salesOrdersQuery.salesOrders ?? []
                : searchType === 'serialnumbers'
                ? comboData?.salesOrdersQuery.serialNumbers ?? []
                : comboData?.customersQuery.businessPartners ?? []
            }
            loading={comboLoading}
            renderInput={params => (
              <TextField
                {...params}
                label={
                  searchType === 'salesorders'
                    ? t<string>('selectSalesOrder')
                    : searchType === 'serialnumbers'
                    ? t<string>('selectSerialNumber')
                    : t<string>('selectBusinessPartner')
                }
                variant='outlined'
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {comboLoading ? (
                        <CircularProgress color='inherit' size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <div className={classes.searchResultsBox}>
            {searchResults?.length ? (
              <InfiniteScroll
                className={classes.infiniteScroll}
                pageStart={0}
                loadMore={loadSearchResult}
                hasMore={loadedResults.length < searchResults.length}
                loader={<CircularProgress color='inherit' size={10} />}
                useWindow={false}
              >
                {loadedResults?.map((d: ISN, i) => (
                  <div key={i} className={classes.searchResultsDiv}>
                    <Card key={i} className={classes.paper}>
                      <CardActionArea
                        className={classes.cardAction}
                        onClick={() => cardClick(d.id)}
                      >
                        <PreviewCard
                          url={d.preview}
                          width={250}
                          height={200}
                          prefersDarkMode={prefersDarkMode}
                        />
                        <CardContent className={classes.cardContent}>
                          {userInfo.type === '1' || userInfo.type === '2' ? (
                            <Typography variant='subtitle2'>
                              Business partner: {d.businessPartner}
                            </Typography>
                          ) : null}
                          <Typography variant='subtitle2'>
                            {t<string>('salesOrder')}: {d.salesOrder}
                          </Typography>
                          <Typography variant='subtitle2'>
                            {t<string>('serialNumber')}: {d.number}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </div>
                ))}
              </InfiniteScroll>
            ) : searchLoading ? (
              <Grid item>
                <h2>{t<string>('loading')}</h2>
              </Grid>
            ) : (
              <Grid item>
                <h2>{t<string>('noDataToDisplay')}</h2>
              </Grid>
            )}
          </div>
        </Grid>
      </Grid>
    </Container>
  )
}
