import React, { useState, useEffect, useContext, ReactElement } from 'react'
import axios from 'axios'

import { find, difference } from 'lodash'
import { Tooltip, Fab, useMediaQuery } from '@material-ui/core'

import AspectRatioIcon from '@material-ui/icons/AspectRatio'
import PhotoSizeSelectSmallIcon from '@material-ui/icons/PhotoSizeSelectSmall'
import PublishIcon from '@material-ui/icons/Publish'
import WidgetsIcon from '@material-ui/icons/Widgets'
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart'

import { BOMS_URL } from '../../consts/urls'
import { createNewJsonNode } from '../../utils/tree'
import { ID_TREE_NODE, ID_TREE } from '../../consts/viewer'
import { MyContextMenuExtension, NewExplodeExtension } from '../../extensions'
import { CartItemsContext, UserInfoContext, useSettings } from '../../context'
import {
  backToParent,
  highlightsSpareparts,
  getSparepartsCode,
  updateNodeSettings,
} from '../../utils/viewer'

import { useTranslation } from 'react-i18next'

const $ = window.$
const Autodesk = window.Autodesk
Autodesk.Viewing.theExtensionManager.registerExtension(
  'CustomMenu',
  MyContextMenuExtension
)
Autodesk.Viewing.theExtensionManager.registerExtension(
  'CustomExplodeExtension',
  NewExplodeExtension
)

interface IViewerButton {
  onClick?: () => void
  id: string
  display: boolean
  content: ReactElement
  tooltip?: string
  exploder?: boolean
  disabled?: boolean
}

interface ICustomViewer {
  urn: string
  fullScreen: boolean
  setFullScreen: (arg0: boolean) => void
  selectedNode: IJSTreeNode
  matching: IMatching | undefined
  setMatching: (arg0: IMatching) => void
  recomendedIds: number[]
  setRecomendedIds: (arg0: number[]) => void
  addRecToCart: () => void
}

const CustomViewer = (props: ICustomViewer) => {
  const [ModelTree, setModelTree] = useState<ITreeNode[]>([])
  const [SelectedNode, setSelectedNode] = useState<number[]>([])
  const [OldNodesId, setOldNodesId] = useState<string[]>([])
  const [Viewer, setViewer] = useState<Autodesk.Viewing.Viewer3D>()
  const [ShowExploder, setShowExploder] = useState(false)
  const [ExploderValue, setExploderValue] = useState('0')
  const [HLSpareparts, setHLSpareparts] = useState(false)
  const [SparePartsIds, setSparePartsIds] = useState<number[]>([])
  const [InseparableIds, setInseparableIds] = useState<number[]>([])

  const { userInfo } = useContext(UserInfoContext)
  const { addCartItems } = useContext(CartItemsContext)
  const { t } = useTranslation()
  const { settings } = useSettings()

  const prefersDarkMode = false // useMediaQuery('(prefers-color-scheme: dark)')

  const {
    urn,
    fullScreen,
    setFullScreen,
    selectedNode,
    matching,
    setMatching,
    recomendedIds,
    setRecomendedIds,
    addRecToCart,
  } = props

  useEffect(() => {
    if (Viewer) {
      Viewer.tearDown()
      Viewer.finish()
      setViewer(undefined)
      setExploderValue('0')
    }

    if (urn) {
      const _options = {
        env: 'AutodeskProduction',
        getAccessToken: getForgeToken,
      }

      Autodesk.Viewing.Initializer(_options, () => onInitialized(urn))
    } else {
      setViewer(undefined)
      setFullScreen(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urn])

  useEffect(() => {
    if (Viewer) {
      setTimeout(() => Viewer.resize(), 1000)
      setTimeout(() => Viewer.fitToView([100]), 1100)

      //Viewer.isolate();
      Viewer.select([])
      const _mcme = Viewer.getExtension('CustomMenu') as MyContextMenuExtension
      _mcme.setFullScreen(fullScreen)
      _mcme.setSetCart(addCartItems)
      setExploderValue('0')
      setShowExploder(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullScreen])

  useEffect(() => {
    const _tree = $(`#${ID_TREE_NODE}`).jstree(true)
    if (_tree && fullScreen) {
      _tree.deselect_all()
      const _nodesId = []
      let _selectedNodes = [...SelectedNode]
      let _reselectViewer = false
      while (_selectedNodes.length && !_reselectViewer && matching) {
        const _node = _selectedNodes.shift()
        const _nodeId = matching[_node ?? -1]

        if (!_nodeId && _node) {
          _reselectViewer = true
          _selectedNodes = [...SelectedNode]
          const _iTree = Viewer?.model.getInstanceTree()
          const _parentId = _iTree?.getNodeParentId(_node)
          const _rootId = _iTree?.getRootId()
          const _index = _selectedNodes.indexOf(_node)
          if (_parentId && _parentId !== _rootId) {
            _selectedNodes[_index] = _parentId
          } else {
            _selectedNodes.splice(_index, 1)
          }
        } else {
          _nodesId.push(_nodeId)
        }
      }

      if (_reselectViewer && Viewer) {
        Viewer.select(_selectedNodes)
      } else {
        _tree.select_node(_nodesId)
        let _nodesToFocus
        if (_nodesId.length === 1 && !OldNodesId.includes(_nodesId[0])) {
          _nodesToFocus = _nodesId[0]
        } else {
          _nodesToFocus = difference(_nodesId, OldNodesId)[0]
        }

        if (_nodesToFocus) {
          _tree
            .get_node(`#${_nodesToFocus}`, true)
            .children('.jstree-anchor')
            .focus()
        }
        setOldNodesId(_nodesId)
      }
    } else if (!fullScreen && Viewer) {
      Viewer.select([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SelectedNode])

  useEffect(() => {
    if (Viewer) {
      const _mcme = Viewer.getExtension('CustomMenu') as MyContextMenuExtension
      const _nee = Viewer.getExtension(
        'CustomExplodeExtension'
      ) as NewExplodeExtension

      if (matching) {
        _mcme.setMatching(matching)
        _nee.setMatching(Object.keys(matching))

        //Calculate spareparts code
        const _ids = getSparepartsCode(matching)

        const _spIds = _ids['_spIds'] ?? []
        const _recIds = _ids['_recIds'] ?? []

        setSparePartsIds(_spIds)
        setRecomendedIds(_recIds)
        setInseparableIds(_ids['_insIds'] ?? [])

        if (settings.value.spareparts === 'prop')
          _mcme.setSparePartsIds([..._spIds, ..._recIds])

        _nee.setInsIds(_ids['_insIds'])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matching, settings])

  // useEffect(() => {
  //   if(Viewer) Viewer.explode(ExploderValue);
  // }, [ExploderValue])

  useEffect(() => {
    if (!ShowExploder) setExploderValue('0')
  }, [ShowExploder])

  useEffect(() => {
    if (Viewer && ExploderValue === '0') {
      Viewer.explode(0)
      Viewer.impl.sceneUpdated(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ExploderValue])

  const initializeNodeTree = (modelTree: ITreeNode[]) => {
    const _newTree = $(`#${ID_TREE_NODE}`).jstree(true)
    const _tree = $(`#${ID_TREE}`).jstree(true)

    const _selectNode = selectedNode

    const _newSelectedNode = createNewJsonNode(_selectNode, _tree)
    // const _newSelectedNode = JSON.parse(JSON.stringify(tree.get_json(_selectNode)));

    const _root: ITreeNode = modelTree[0]
    _newSelectedNode.data.dbId = [_root.dbId]
    const _matching: IMatching = assignNodeToComponent(
      _newSelectedNode.children,
      _root.children
    )
    _matching[_root.dbId] = _newSelectedNode.id
    setMatching(_matching)

    _newTree.settings.core.data = [
      {
        id: _newSelectedNode.id,
        text: _newSelectedNode.text,
        type: _newSelectedNode.type,
        data: _newSelectedNode.data,
        children: _newSelectedNode.children,
        li_attr: _newSelectedNode.li_attr,
      },
    ]
    _newTree.refresh()
  }

  const getForgeToken = (callback: any) => {
    axios.get(`${BOMS_URL}/forge/oauth/token`).then(({ data }) => {
      callback(data.access_token, data.expires_in)
    })
  }

  const onInitialized = (documentId: string) => {
    const config3d = {
      extensions: [
        'CustomMenu',
        'CustomExplodeExtension',
        'Autodesk.ViewCubeUi',
        'Autodesk.NPR',
      ],
    }

    const _viewer = new Autodesk.Viewing.Viewer3D(
      document.getElementById('forgeViewer') as HTMLElement,
      config3d
    )
    _viewer.addEventListener(
      Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
      geometryLoaded
    )
    _viewer.addEventListener(
      Autodesk.Viewing.SELECTION_CHANGED_EVENT,
      updateSelected
    )
    _viewer.addEventListener(
      Autodesk.Viewing.EXTENSION_LOADED_EVENT,
      extensionLoaded
    )
    const startedCode = _viewer.start()

    if (startedCode) {
      console.error('Failed to create a Viewer: WebGL not supported.')
      return
    }
    // this.viewerApp.registerViewer(this.viewerApp.k3D, Autodesk.Viewing.GuiViewer3D, config3d);
    Autodesk.Viewing.Document.load(
      documentId,
      (viewerDocument) => onDocumentLoadSuccess(viewerDocument, _viewer),
      onDocumentLoadFailure
    )
  }

  const onDocumentLoadSuccess = async (
    viewerDocument: Autodesk.Viewing.Document,
    viewer: Autodesk.Viewing.Viewer3D
  ) => {
    const allModels = viewerDocument.getRoot().search({ type: 'geometry' })
    await viewer.loadDocumentNode(viewerDocument, allModels[0])

    //viewer.registerContextMenuCallback('MyCustomContext', customMenuCallback);
    const ext = viewer.getExtension('Autodesk.NPR') as any
    ext.setParameter('style', 'edging')
    ext.setParameter('depthEdges', false)
    ext.setParameter('idEdges', false)

    viewer.setGhosting(false)
    viewer.setGroundShadow(false)
    viewer.setLightPreset(8)

    if (prefersDarkMode) {
      viewer.setBackgroundColor(48, 48, 48, 48, 48, 48)
    } else {
      viewer.setBackgroundColor(240, 240, 240, 240, 240, 240)
    }

    viewer.setTheme('bim-theme')
    viewer.setOptimizeNavigation(true)
    viewer.setQualityLevel(false, false)

    viewer.setProgressiveRendering(false)
    setViewer(viewer)
  }

  const updateSelected = ({ nodeArray }: { nodeArray: number[] }) => {
    if (nodeArray.length) {
      setSelectedNode(nodeArray)
    } else {
      const _tree = $(`#${ID_TREE_NODE}`).jstree(true)
      _tree.deselect_all()
    }
  }

  const extensionLoaded = ({
    extensionId,
    target,
  }: {
    extensionId: string
    target: Autodesk.Viewing.Viewer3D
  }) => {
    if (extensionId === 'CustomMenu') {
      const _mcme = target.getExtension('CustomMenu') as MyContextMenuExtension
      _mcme.setFullScreen(fullScreen)
      _mcme.setSetCart(addCartItems)
      if (matching) _mcme.setMatching(matching)
    } else if (extensionId === 'CustomExplodeExtension') {
      const _nee = target.getExtension(
        'CustomExplodeExtension'
      ) as NewExplodeExtension
      _nee.setMatching(matching)
    }
  }

  const onDocumentLoadFailure = () => {
    console.error('Failed fetching Forge manifest')
  }

  const geometryLoaded = ({
    target,
    model,
  }: {
    target: Autodesk.Viewing.Viewer3D
    model: any
  }) => {
    const _iTree = model.getInstanceTree()
    const _tree: ITreeNode[] = []
    if (_iTree) {
      model.getBulkProperties2(
        [],
        { ignoreHidden: true },
        (allProps: Autodesk.Viewing.PropertyResult[]) => {
          addNodeToTree(_iTree.getRootId(), _tree, _iTree, allProps)
          setModelTree(_tree)
          initializeNodeTree(_tree)
        }
      )

      fixTopView(target)
    } else {
      setTimeout(() => {
        geometryLoaded({ target, model })
        fixTopView(target)
      }, 1000)
    }
  }

  const fixTopView = (target: any) => {
    // TODO!!!! Replace with data saved in db when we understad how to save camera info in db
    const _camera = target.impl.camera
    const _worldDirection = new window.THREE.Vector3()
    _camera.getWorldDirection(_worldDirection)

    if (
      _worldDirection.equals(new window.THREE.Vector3(0, 0, -1)) &&
      _camera.worldup.equals(new window.THREE.Vector3(0, 1, 0))
    ) {
      var cube = target.getExtension('Autodesk.ViewCubeUi')
      target.autocam.setCurrentViewAsTop()
      cube.setViewCube('front top right')
      target.impl.sceneUpdated()

      setTimeout(() => {
        target.fitToView()
        setTimeout(() => {
          target.autocam.setCurrentViewAsHome()
        }, 800)
      }, 800)
    }
    // End code to replace
  }

  const addNodeToTree = (
    dbId: number,
    tree: ITreeNode[],
    iTree: any,
    allProps: Autodesk.Viewing.PropertyResult[]
  ) => {
    const _nodeProps = find(allProps, { dbId: dbId })

    let _name = iTree.getNodeName(dbId)
    if (_nodeProps) {
      if (settings.value.pnProp && settings.value.pnProp !== '') {
        const _customProp = find(_nodeProps.properties, {
          displayName: settings.value.pnProp,
        })
        _name = _customProp?.displayValue ?? ''
      } else {
        _name = _nodeProps.properties[0].displayValue
      }
    }

    const _newNode = { dbId: dbId, name: _name, children: [] }
    tree.push(_newNode)
    iTree.enumNodeChildren(
      dbId,
      (childId: number) =>
        addNodeToTree(childId, _newNode.children, iTree, allProps),
      false
    )
  }

  const btnFullScreen = () => {
    if (!ModelTree) return
    setFullScreen(!fullScreen)
  }

  const assignNodeToComponent = (
    nodes: IJSTreeNode[],
    components: ITreeNode[]
  ) => {
    if (nodes) {
      nodes.forEach((node) => {
        node = updateNodeSettings(node, settings.value)
        if (node.data.urn) {
          node.type = 'model'
        } else if (!node.data.dbId) {
          node.type = 'no-component'
        }
      })
    }

    let _matching: IMatching = {}
    components.forEach((cmp) => {
      let _cmpName = cleanString(cmp.name.split(':')[0])
      let node = find(nodes, (n) => {
        return _cmpName.includes(cleanString(n.data.model))
      }) as IJSTreeNode

      if (node) {
        const _dbId = node.data.dbId ? node.data.dbId : []
        _dbId.push(cmp.dbId)
        node.data.dbId = _dbId
        if (!node.data.urn)
          node.type = _dbId.length > 1 ? 'components' : 'component'

        _matching[cmp.dbId] = node.id
        let _childrenMatching = {}
        if (!node.data.urn && node.children && !node.data.inseparable) {
          _childrenMatching = assignNodeToComponent(node.children, cmp.children)
        } else {
          // remove children because the node has a different model
          node.children = []
        }
        _matching = { ..._matching, ..._childrenMatching }
      } else if (cmp.children.length) {
        // handle special components, es. Component Pattern
        cmp.children.forEach((childCmp) => {
          let _cmpChildName = cleanString(childCmp.name.split(':')[0])
          node = find(nodes, (n) => {
            return _cmpChildName.includes(cleanString(n.data.model))
          }) as IJSTreeNode

          if (node) {
            const _dbId = node.data.dbId ? node.data.dbId : []
            _dbId.push(childCmp.dbId)
            node.data.dbId = _dbId
            if (!node.data.urn)
              node.type = _dbId.length > 1 ? 'components' : 'component'

            _matching[childCmp.dbId] = node.id
            let _childrenMatching = {}
            if (!node.data.urn && node.children && !node.data.inseparable) {
              _childrenMatching = assignNodeToComponent(
                node.children,
                childCmp.children
              )
            } else {
              // remove children because the node has a different model
              node.children = []
            }
            _matching = { ..._matching, ..._childrenMatching }
          }
        })
      }
    })

    return _matching
  }

  const cleanString = (str: string) => {
    str = str.replace(/-/g, '')
    str = str.replace(/\//g, '')
    str = str.replace(/"/g, '')
    str = str.replace(/\\/g, '')
    str = str.replace(/_/g, '')
    str = str.replace(/ /g, '')

    return str
  }

  let _viewerButtons: IViewerButton[] = []
  if (urn) {
    _viewerButtons = [
      {
        onClick: btnFullScreen,
        id: 'btn-full-screen',
        display: true,
        content: fullScreen ? (
          <PhotoSizeSelectSmallIcon />
        ) : (
          <AspectRatioIcon />
        ),
        tooltip: fullScreen ? t('disableFull') : t('enableFull'),
      },
      {
        onClick: () => setShowExploder(!ShowExploder),
        id: 'btn-show-exploder',
        display: true,
        content: <i className='adsk-icon-explode' />,
        tooltip: t('explode'),
      },
      {
        id: 'btn-exploder',
        display: ShowExploder,
        exploder: true,
        content: (
          <input
            type='range'
            id='custom-explode-slider'
            min='0'
            max='1'
            step='0.01'
            value={ExploderValue}
            onChange={({ target }) => setExploderValue(target.value)}
          />
        ),
      },
      {
        onClick: () => (matching ? backToParent(matching) : null),
        id: 'btn-parent',
        display: true,
        content: <PublishIcon />,
        tooltip: t('backToParent'),
      },
    ]
    if (SparePartsIds.length || recomendedIds.length)
      _viewerButtons.push({
        onClick: () => {
          highlightsSpareparts(!HLSpareparts, SparePartsIds, recomendedIds)
          setHLSpareparts(!HLSpareparts)
        },
        id: 'btn-hlspp',
        display: true,
        content: <WidgetsIcon />,
        tooltip: 'Highlight spare parts',
      })
    if (recomendedIds.length)
      _viewerButtons.push({
        onClick: addRecToCart,
        id: 'btn-add-rec',
        display: true,
        content: <AddShoppingCartIcon />,
        tooltip: 'Add recommended spare parts to cart',
      })
  }

  return (
    <>
      <div
        style={{
          position: 'absolute',
          right: '0',
          bottom: '0',
          zIndex: 100,
          display: 'flex',
          paddingRight: '5px',
          paddingBottom: '10px',
        }}
      >
        {_viewerButtons.map((btn, i) =>
          btn.exploder ? (
            <div
              style={{
                marginRight: '5px',
                display: btn.display ? 'flex' : 'none',
              }}
              color='default'
              key={btn.id}
              id={btn.id}
              onClick={btn.onClick}
            >
              {btn.content}
            </div>
          ) : (
            <Tooltip
              key={btn.id}
              title={btn.tooltip ?? ''}
              placement='bottom'
              arrow
            >
              <Fab
                style={{
                  marginRight: '5px',
                  display: btn.display ? 'flex' : 'none',
                }}
                color='primary'
                size='small'
                key={btn.id}
                id={btn.id}
                disabled={btn.disabled}
                onClick={btn.onClick}
              >
                {btn.content}
              </Fab>
            </Tooltip>
          )
        )}
      </div>
      <div
        id='forgeViewer'
        style={
          selectedNode
            ? {
                height: '100%',
                width: '100%',
                display: 'flex',
                fontSize: '55px',
              }
            : {}
        }
      >
        {selectedNode && (
          <i className='fas fa-file' style={{ margin: 'auto' }} />
        )}
      </div>
    </>
  )
}

export { CustomViewer }
