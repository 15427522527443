import { isolateItems, addToCartSS } from '../../../utils/viewer'
import { ID_TREE, ID_TREE_NODE } from '../../../consts/viewer'

const $ = window.$

interface IAction {
  label: string
  icon: string
  action: () => void
}

interface IContextMenu {
  addToCart?: IAction
  isolate?: IAction
}

interface IProps {
  updateSelectedNode: (arg0: IJSTreeNode) => void
}

const intializeMainTree = (
  settings: ISettings,
  props: IProps,
  addCartItems: (arg0: ICartItem[]) => void
) => {
  const _tree: JSTree = $(`#${ID_TREE}`)
  if (_tree.jstree(true)) _tree.jstree(true).destroy()
  _tree.jstree({
    core: {
      data: [],
    },

    plugins: ['types', 'search', 'contextmenu'],
    types: {
      default: { icon: 'fas fa-file' },
      component: { icon: 'fas fa-cog' },
      components: { icon: 'fas fa-cogs' },
      model: { icon: 'fas fa-cubes' },
      'no-component': { icon: 'fas fa-exclamation-triangle' },
    },
    contextmenu: {
      items: (node: IJSTreeNode) => {
        const _items: IContextMenu = {}
        const _addToCartEl = checkAddToCart(settings, _tree, node, addCartItems)
        if (_addToCartEl) _items['addToCart'] = _addToCartEl
        return _items
      },
    },
  })
  _tree.on('select_node.jstree', (_, { node }) => {
    props.updateSelectedNode(node)
  })
  _tree.on('refresh.jstree', (_, { instance }) => {
    const _data = instance.settings.core.data
    const _rootNode = _data ? _data.id : null
    const _id = window.location.search
      ? window.location.search.split('=')[1]
      : null
    if (_id) {
      instance.select_node(_id)
    } else if (_rootNode) instance.select_node(_rootNode)
    else instance.select_node()
  })
}

const initializeNodeTree = (
  settings: ISettings,
  addCartItems: (arg0: ICartItem[]) => void
) => {
  const _treeNode: JSTree = $(`#${ID_TREE_NODE}`)
  if (_treeNode.jstree(true)) _treeNode.jstree(true).destroy()
  _treeNode.jstree({
    core: {
      data: [],
    },
    plugins: ['types', 'search', 'contextmenu'],
    types: {
      default: { icon: 'fas fa-exclamation-circle' },
      component: { icon: 'fas fa-cog' },
      components: { icon: 'fas fa-cogs' },
      model: { icon: 'fas fa-cubes' },
      'no-component': { icon: 'fas fa-question-circle' },
    },
    contextmenu: {
      items: (node: IJSTreeNode) => {
        const _selectedNodes = _treeNode.jstree(true).get_selected()
        // const _spSelected = _selectedNodes.filter(node => this.state.spareParts.includes(node));
        const _items: IContextMenu = {}
        const _addToCartEl = checkAddToCart(
          settings,
          _treeNode,
          node,
          addCartItems
        )
        if (_addToCartEl) _items['addToCart'] = _addToCartEl

        if (_selectedNodes.length) {
          _items['isolate'] = {
            label: 'Isola elementi selezionati',
            action: isolateItems,
            icon: 'fab fa-creative-commons-remix',
          }
        }

        return _items
      },
    },
    search: {
      show_only_matches: true,
    },
  })
  _treeNode.on('select_node.jstree', (_, { event, selected }) => {
    if (event) {
      let _dbIds = extractViewerId(_treeNode, selected)
      _dbIds = _dbIds.filter(dbId => window.NOP_VIEWER.isNodeVisible(dbId))
      window.NOP_VIEWER.select([_dbIds[0]])
      window.NOP_VIEWER.fitToView([_dbIds[0]])
    }
  })
  _treeNode.on('search.jstree', (_, { res }) => {
    let _dbIds = extractViewerId(_treeNode, res)
    window.NOP_VIEWER.isolate(_dbIds)
    window.NOP_VIEWER.fitToView(_dbIds)
  })
  _treeNode.on('clear_search.jstree', () => {
    window.NOP_VIEWER.isolate()
    window.NOP_VIEWER.fitToView()
  })
  _treeNode.on('refresh.jstree', (_, { instance }) => {
    const _data = instance.settings.core.data
    const _rootNode = _data.length ? _data[0].id : null
    if (_rootNode) instance.open_node(_rootNode)
  })
}

const addToCart = (
  tree: JSTree,
  node: IJSTreeNode,
  addCartItems: (arg0: ICartItem[]) => void,
  settings: ISettings
) => {
  const _selectedNodes = tree.jstree(true).get_selected()

  if (!node.data.jsonId && node.data.refs) {
    node.data.jsonId = parseInt(node.data.refs[0].jsonId)
  }
  let _nodeToAdd: ICartItem[] = []

  if (tree[0].id === ID_TREE) {
    if (node.data.urn) {
      addToCartSS(
        window.NOP_VIEWER,
        node,
        window.location.pathname,
        addCartItems
      )
    } else {
      _nodeToAdd = [
        {
          id: 0,
          componentId: node.data.componentId,
          componentCode: node.data.name ? node.data.name : '',
          componentDescription: node.data.desc ?? '',
          quantity: 1,
          img: '',
          machineUrl: window.location.pathname,
          dbId: node.data.dbId ? node.data.dbId[0] : 0,
          relationId: parseInt(node.id),
          revBomId: parseInt(window.location.pathname.split('/')[3]),
          serialNumberId: parseInt(window.location.pathname.split('/')[2]),
          serialNumber: undefined,
        },
      ]
    }

    _selectedNodes.forEach(nodeId => {
      if (nodeId !== node.id) {
        const _node = tree.jstree(true).get_node(nodeId)
        if (checkNodeAddToCart(settings, _node)) {
          if (!_node.data.jsonId && _node.data.refs) {
            _node.data.jsonId = _node.data.refs[0].jsonId
          }
          _nodeToAdd.push(_node)
        }
      }
    })
  } else if (tree[0].id === ID_TREE_NODE) {
    let _nodeWithImage = []
    if (node.data.dbId) {
      _nodeWithImage.push(node)
    } else {
      _nodeToAdd.push({
        id: 0,
        componentId: node.data.componentId,
        componentCode: node.data.name ? node.data.name : '',
        componentDescription: node.data.desc ?? '',
        quantity: 1,
        img: '',
        machineUrl: window.location.pathname,
        dbId: node.data.dbId ? node.data.dbId[0] : 0,
        relationId: parseInt(node.id),
        revBomId: parseInt(window.location.pathname.split('/')[3]),
        serialNumberId: parseInt(window.location.pathname.split('/')[2]),
        serialNumber: undefined,
      })
    }

    _selectedNodes.forEach(nodeId => {
      if (nodeId !== node.id) {
        const _node = tree.jstree(true).get_node(nodeId)

        if (checkNodeAddToCart(settings, _node)) {
          if (!_node.data.jsonId && _node.data.refs) {
            _node.data.jsonId = _node.data.refs[0].jsonId
          }

          if (_node.data.dbId) {
            _nodeWithImage.push(_node)
          } else {
            _nodeToAdd.push(_node)
          }
        }
      }
    })

    const _selection = window.NOP_VIEWER.getSelection()
    const _isolatedNodes = window.NOP_VIEWER.getIsolatedNodes()
    window.NOP_VIEWER.select([])
    _nodeWithImage.forEach(node => {
      const _dbId = node.data.dbId
      window.NOP_VIEWER.isolate(_dbId)
      window.NOP_VIEWER.fitToView(_dbId, window.NOP_VIEWER.model, true)
      addToCartSS(
        window.NOP_VIEWER,
        node,
        window.location.pathname,
        addCartItems
      )
    })
    window.NOP_VIEWER.select(_selection)
    window.NOP_VIEWER.isolate(_isolatedNodes)
    window.NOP_VIEWER.fitToView(_isolatedNodes, window.NOP_VIEWER.model, true)
  }
  addCartItems(_nodeToAdd)
}

const extractViewerId = (tree: JSTree, nodesId: string[]) => {
  let _dbIds: number[] = []
  nodesId.forEach(nodeId => {
    const _node = tree.jstree(true).get_node(nodeId)
    const _dbId = _node.data.dbId
    if (_dbId) {
      _dbIds = _dbIds.concat(_dbId)
    }
  })
  return _dbIds
}

const checkAddToCart = (
  settings: ISettings,
  tree: JSTree,
  node: IJSTreeNode,
  addCartItems: (arg0: ICartItem[]) => void
) => {
  if (checkNodeAddToCart(settings, node)) {
    return {
      label: 'Aggiungi selezione al carrello',
      action: () => addToCart(tree, node, addCartItems, settings),
      icon: 'fas fa-cart-plus',
    }
  } else return null
}

const checkNodeAddToCart = (settings: ISettings, node: IJSTreeNode) => {
  return (
    settings.spareparts !== 'prop' ||
    node.li_attr?.class?.trim().includes('jstree-sp') ||
    node.li_attr?.class?.trim().includes('jstree-sp-rec')
  )
}

export { intializeMainTree, initializeNodeTree }
