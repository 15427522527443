import React from 'react'

interface IFullScreenContext {
  fullScreen: boolean
  setFullScreen: (arg: boolean) => void
}

const defaultFullScreen = false
const FullScreenContext = React.createContext<IFullScreenContext>({
  fullScreen: defaultFullScreen,
  setFullScreen: () => {},
})

type Props = {
  children: React.ReactNode
}

const FullScreenContextProvider = ({ children }: Props) => {
  const [fullScreen, setFullScreen] = React.useState(defaultFullScreen)

  return (
    <FullScreenContext.Provider value={{ fullScreen, setFullScreen }}>
      {children}
    </FullScreenContext.Provider>
  )
}

const useFullScreen = () => React.useContext(FullScreenContext)

export { FullScreenContext, FullScreenContextProvider, useFullScreen }
