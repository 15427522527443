import { Dialog } from '@material-ui/core'
import React from 'react'
import { ISimpleModal } from './types'

const SimpleModal: React.FunctionComponent<ISimpleModal> = ({
  open,
  onClose,
  fullWidth,
  maxWidth,
  children,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={fullWidth ?? false}
      maxWidth={maxWidth ?? 'sm'}
    >
      {children}
    </Dialog>
  )
}

export default SimpleModal
