export const DEFAULT_SETTINGS: ISettings = {
  sendOrder: 'pdf',
  emailPdf: '',
  apiUri: '',
  smtp: 'default',
  emailFrom: '',
  smtpUrl: '',
  smtpPort: '',
  smtpUser: '',
  smtpPassword: '',
  treeFields: [],
  infoSelectedNode: [],
  spareparts: 'all',
  pnProp: '',
}
