import InfoPanel from './info_panel'
import CustomTreeModelPanel from './tree_model_panel'
import DocPanel from './doc_panel'
import i18n from '../../i18next'
import { addToCartSS } from '../../utils/viewer'

const $ = window.$
const Autodesk = window.Autodesk

class CustomExplodeSubmenu extends Autodesk.Viewing.UI.DockingPanel {
  // Properties
  Viewer: Autodesk.Viewing.GuiViewer3D

  // Contructors
  constructor(
    viewer: Autodesk.Viewing.GuiViewer3D,
    parentContainer: HTMLElement,
    id: string,
    title: string,
    options?: Autodesk.Viewing.UI.DockingPanelOptions
  ) {
    super(parentContainer, id, title, options)
    this.Viewer = viewer
  }

  // Methods
  initialize() {
    const _inputRange =
      '<input id="custom-explode-slider" type="range" min="0" max="1" class="explode-slider" step="0.01" value="0" />'
    $(this.container).append(_inputRange)
  }
}

export class CustomToolbarExtension extends Autodesk.Viewing.Extension {
  // Porperties
  Toolbar: Autodesk.Viewing.UI.ToolBar
  Group: Autodesk.Viewing.UI.ControlGroup
  InfoPanel: InfoPanel
  DocPanel: DocPanel
  TreePanel: CustomTreeModelPanel
  BackToParent: Autodesk.Viewing.UI.Button
  Matching: IMatching
  TreeData: IJSTreeNode[]
  UserInfo: IUserInfo
  AddToCart: (arg0: ICartItem[]) => void
  SPIds: number[]
  RecSPIds: number[]
  SpIdsButton: Autodesk.Viewing.UI.Button | null
  RecSpIdsButton: Autodesk.Viewing.UI.Button | null
  CartIdsButton: Autodesk.Viewing.UI.Button | null
  isHighligted: boolean
  CartButton: Autodesk.Viewing.UI.Button
  Push: (arg0: string) => void
  AddSettingsButton: boolean
  OpenSettings: () => void

  // Contructors
  constructor(viewer: Autodesk.Viewing.GuiViewer3D, options: any) {
    super(viewer, options)
    this.Toolbar = new Autodesk.Viewing.UI.ToolBar('spp-toolbar')
    this.Group = new Autodesk.Viewing.UI.ControlGroup('sppExtensionsToolbar')

    this.InfoPanel = new InfoPanel(
      this.viewer,
      <HTMLElement>this.viewer.container,
      'spp-infopanel',
      i18n.t('infoSelectedNode'),
      this.options
    )
    this.InfoPanel.setSelectedEvent()

    this.DocPanel = new DocPanel(
      this.viewer,
      <HTMLElement>this.viewer.container,
      'spp-docpanel',
      i18n.t('documentsSelectedNode'),
      this.options
    )
    this.DocPanel.setSelectedEvent()

    this.TreePanel = new CustomTreeModelPanel(
      this.viewer,
      <HTMLElement>this.viewer.container,
      'spp-treepanel',
      i18n.t('treeModel'),
      this.options
    )
    this.TreePanel.setSelectedEvent()

    this.BackToParent = new Autodesk.Viewing.UI.Button('sppBackToParentButton')
    this.Matching = {}
    this.TreeData = []
    this.UserInfo = options.userInfo
      ? options.userInfo
      : {
          email: '',
          region: 'en',
          companyName: '',
          type: '',
          loginId: -1,
          customerId: -1,
          businessPartnerId: -1,
          crmCustomerId: -1,
        }
    this.AddToCart = () => {}
    this.SPIds = []
    this.RecSPIds = []
    this.SpIdsButton = null
    this.RecSpIdsButton = null
    this.CartIdsButton = null
    this.isHighligted = false
    this.CartButton = new Autodesk.Viewing.UI.Button('sppCartButton')

    this.AddSettingsButton = this.UserInfo.type === '2'
    this.OpenSettings = () => {}

    this.Push = () => {}

    this.checkSelectedNode = this.checkSelectedNode.bind(this)
    this.handleIsolate = this.handleIsolate.bind(this)
  }

  // Methods
  load() {
    this.createToolbar()

    this.viewer.addEventListener(
      Autodesk.Viewing.SELECTION_CHANGED_EVENT,
      this.checkSelectedNode
    )
    this.viewer.addEventListener(
      Autodesk.Viewing.ISOLATE_EVENT,
      this.handleIsolate
    )

    return true
  }

  unload() {
    // Clean our UI elements if we added any
    if (this.Group) {
      // this.Group.removeControl(this.Button)
      if (this.Group.getNumberOfControls() === 0) {
        this.viewer.toolbar.removeControl(this.Group)
      }
    }

    return true
  }

  createToolbar() {
    //this.Toolbar = new Autodesk.Viewing.UI.ToolBar('spp-toolbar')
    //this.Group = new Autodesk.Viewing.UI.ControlGroup('sppExtensionsToolbar')

    // Create info panel button
    const _infoButton = new Autodesk.Viewing.UI.Button('sppInfoButton')
    _infoButton.onClick = ev => {
      this.InfoPanel.setInfoPanelValues()
      this.InfoPanel.setVisible(!this.InfoPanel.isVisible())
    }
    _infoButton.addClass('fas')
    _infoButton.setIcon('fa-info')
    _infoButton.setToolTip(i18n.t('infoPanel'))

    // Create doc panel button
    const _docButton = new Autodesk.Viewing.UI.Button('sppDocButton')
    _docButton.onClick = ev => {
      this.DocPanel.setDocPanelValues()
      this.DocPanel.setVisible(!this.DocPanel.isVisible())
    }
    _docButton.addClass('fas')
    _docButton.setIcon('fa-folder-open')
    _docButton.setToolTip(i18n.t('documentsPanel'))

    // Create tree panel button
    const _treeButton = new Autodesk.Viewing.UI.Button('sppTreeButton')
    _treeButton.onClick = () => {
      this.TreePanel.setVisible(!this.TreePanel.isVisible())
    }
    _treeButton.setIcon('adsk-icon-structure')
    _treeButton.setToolTip(i18n.t('treeModel'))

    // Create explode button
    const _explodeButton = new Autodesk.Viewing.UI.Button(
      'sppExplodeButton'
    ) as any
    if (_explodeButton.container) {
      _explodeButton.setToolTip(i18n.t('explode'))
      const _explodePanel = new CustomExplodeSubmenu(
        this.viewer,
        _explodeButton.container,
        'sppExplodeSubmenu',
        '',
        {}
      )
      $(_explodePanel.container).addClass(
        'docking-panel-container-solid-color-b explode-submenu'
      )
      _explodeButton.onClick = (ev: Event) => {
        if (ev && ev.target) {
          const _target: HTMLButtonElement = <HTMLButtonElement>ev.target
          if (_target.className === 'adsk-button-icon adsk-icon-explode') {
            _explodeButton.setState(_explodePanel.isVisible() ? 1 : 0)
            _explodePanel.setVisible(!_explodePanel.isVisible())
            _explodePanel.container.style['maxWidth'] = '160px'
            _explodePanel.container.style['width'] = '160px'
          }
        }
      }
      _explodeButton.setIcon('adsk-icon-explode')
    }

    // Create backToParent button
    this.BackToParent.onClick = () => {
      const _iTree = this.viewer.model.getInstanceTree()
      const _rootId = _iTree.getRootId()
      const _isolatedIds = this.viewer.getIsolatedNodes()

      const _parentsIds: number[] = []
      _isolatedIds.forEach(id => {
        const _parentId = _iTree.getNodeParentId(id)
        if (_parentId && _parentId !== _rootId) _parentsIds.push(_parentId)
      })

      this.viewer.isolate(_parentsIds)
      this.viewer.fitToView(_parentsIds)
      this.TreePanel.resetTreeData(_parentsIds)
    }
    this.BackToParent.addClass('fas')
    this.BackToParent.setIcon('fa-level-up-alt')
    //Disable button
    this.BackToParent.setState(2)
    this.BackToParent.setToolTip(i18n.t('backToParent'))

    // Create Cart button
    this.CartButton.onClick = () => {
      //this.CartPanel.setVisible(!this.CartPanel.isVisible());
      this.Push('/cart')
    }
    this.CartButton.addClass('fas')
    this.CartButton.setIcon('fa-shopping-cart')
    this.CartButton.setToolTip('Cart')

    // Create open settings button
    let _settingsButton: Autodesk.Viewing.UI.Button | null = null
    if (this.AddSettingsButton) {
      _settingsButton = new Autodesk.Viewing.UI.Button('sppSettingsButton')
      _settingsButton.onClick = () => {
        this.OpenSettings()
      }
      _settingsButton.addClass('fas')
      _settingsButton.setIcon('fa-cog')
      _settingsButton.setToolTip(i18n.t('settings'))
    }

    if (_settingsButton) this.Group.addControl(_settingsButton)
    this.Group.addControl(_treeButton)
    this.Group.addControl(_infoButton)
    this.Group.addControl(_docButton)
    this.Group.addControl(_explodeButton)
    this.Group.addControl(this.BackToParent)
    // this.Group.addControl(this.CartButton)

    this.Toolbar.addControl(this.Group)

    const _toolbarDivHtml = '<div id="spp-div-toolbar"></div>'

    $(this.viewer.container).append(_toolbarDivHtml)
    $('#spp-div-toolbar')[0].appendChild(this.Toolbar.container)
  }

  setMatching(matching: IMatching) {
    this.Matching = matching

    if (this.InfoPanel != null) this.InfoPanel.setMatching(matching)

    if (this.DocPanel != null) this.DocPanel.setMatching(matching)

    if (this.TreePanel != null) this.TreePanel.setMatching(matching)
  }

  setTreeData(treeData: IJSTreeNode[]) {
    this.TreeData = treeData

    if (this.TreePanel != null) this.TreePanel.setTreeData(treeData)
  }

  setUserInfo(userInfo: IUserInfo) {
    this.UserInfo = userInfo

    if (this.TreePanel != null) this.TreePanel.setUserInfo(userInfo)
  }

  setAddToCart(addToCart: (arg0: ICartItem[]) => void) {
    this.AddToCart = addToCart

    if (this.TreePanel != null) this.TreePanel.setAddToCart(addToCart)
  }

  setSpIds(spIds: number[]) {
    this.SPIds = spIds
    this.updateSpButtons()
  }

  setRecSpIds(recSpIds: number[]) {
    this.RecSPIds = recSpIds
    this.updateSpButtons()
  }

  setPushFunc(push: (arg0: string) => void) {
    this.Push = push
  }

  updateSpButtons() {
    const that = this
    if (this.Group != null) {
      //Create Highlights SP button
      if ((this.SPIds.length || this.RecSPIds.length) && !this.SpIdsButton) {
        this.SpIdsButton = new Autodesk.Viewing.UI.Button('sppHlSpIds')
        this.SpIdsButton.onClick = () => {
          const _cartIds = [] // get from context

          if (!that.isHighligted && this.SpIdsButton) {
            this.SpIdsButton.setState(0)

            that.viewer.isolate([...that.SPIds, ...that.RecSPIds])
            that.viewer.fitToView([...that.SPIds, ...that.RecSPIds])
            that.isHighligted = true
          } else if (this.SpIdsButton) {
            this.SpIdsButton.setState(1)
            that.viewer.isolate([])
            that.viewer.fitToView([])
            that.isHighligted = false
          }
        }
        this.SpIdsButton.addClass('fas')
        this.SpIdsButton.setIcon('fa-cubes')
        this.SpIdsButton.setToolTip(i18n.t('highlightSpareParts'))

        this.Group.addControl(this.SpIdsButton)
      } else if (
        !(this.SPIds.length || this.RecSPIds.length) &&
        this.SpIdsButton
      ) {
        this.Group.removeControl(this.SpIdsButton)
        this.SpIdsButton = null
      }

      //Create Add RecSpIDs to cart button
      if (this.RecSPIds.length && !this.RecSpIdsButton) {
        this.RecSpIdsButton = new Autodesk.Viewing.UI.Button('addRecToCart')
        this.RecSpIdsButton.onClick = () => {
          if (!that.TreePanel.Tree) return
          const _jsTree = that.TreePanel.Tree.jstree(true)
          if (!_jsTree) return

          if (that.RecSPIds.length) {
            const _selection = this.viewer.getSelection()
            const _isolatedNodes = this.viewer.getIsolatedNodes()
            const _hiddenNodes = this.viewer.getHiddenNodes()

            this.viewer.select([])
            const _nodes: IJSTreeNode[] = []
            that.RecSPIds.forEach(id => {
              const _node = _jsTree.get_node(this.Matching[id])
              if (!_nodes.find(n => n.id === _node.id)) {
                _nodes.push(_node)

                this.viewer.isolate(id)
                this.viewer.fitToView([id], this.viewer.model, true)
                addToCartSS(
                  this.viewer,
                  _node,
                  window.location.pathname,
                  that.AddToCart
                )
              }
            })

            this.viewer.select(_selection)
            this.viewer.isolate(_isolatedNodes)
            this.viewer.hide(_hiddenNodes)
            this.viewer.fitToView(_isolatedNodes, this.viewer.model, true)
          }
        }
        this.RecSpIdsButton.addClass('fas')
        this.RecSpIdsButton.setIcon('fa-cart-plus')
        this.RecSpIdsButton.setToolTip(i18n.t('addRecommendedSparePartsToCart'))

        this.Group.addControl(this.RecSpIdsButton)
      } else if (!this.RecSPIds.length && this.RecSpIdsButton) {
        this.Group.removeControl(this.RecSpIdsButton)
        this.RecSpIdsButton = null
      }

      // Create Isolate Cart Items button
      // if (that.CartPanel.CartItems.length > 0) {
      //   this.CartIdsButton = new Autodesk.Viewing.UI.Button('sppCartItemsIds')
      //   this.CartIdsButton.onClick = () => {
      //     const _cartIds: any[] = [] // get from context
      //     that.CartPanel.CartItems.forEach((item) => {
      //       const _data = item.data ? item.data : { dbId: undefined }
      //       if (_data.dbId) {
      //         _data.dbId.forEach((id) => _cartIds.push(id))
      //       }
      //     })
      //     if (!that.isHighligted && this.CartIdsButton) {
      //       this.CartIdsButton.setState(0)

      //       that.viewer.isolate(_cartIds)
      //       that.viewer.fitToView(_cartIds)
      //       that.isHighligted = true
      //     } else if (this.CartIdsButton) {
      //       this.CartIdsButton.setState(1)
      //       that.viewer.isolate([])
      //       that.viewer.fitToView([])
      //       that.isHighligted = false
      //     }
      //   }
      //   this.CartIdsButton.addClass('fas')
      //   this.CartIdsButton.setIcon('fa-cart-arrow-down')
      //   this.CartIdsButton.setToolTip(i18n.t('isolateCartItems'))

      //   this.Group.addControl(this.CartIdsButton)
      // } else if (!(that.CartPanel.CartItems.length > 0) && this.CartIdsButton) {
      //   this.Group.removeControl(this.CartIdsButton)
      //   this.CartIdsButton = null
      // }
    }
  }

  checkSelectedNode() {
    const _dbIdArray = this.viewer.getSelection()
    const _treePanel = this.TreePanel.Tree
      ? this.TreePanel.Tree.jstree(true)
      : null
    if (!_treePanel) return

    const _iTree = this.viewer.model.getInstanceTree()
    const _rootId = _iTree.getRootId()

    const _newIds: number[] = []
    const _nodeIds: string[] = []
    let _toUpdate = false

    _dbIdArray.forEach(id => {
      if (this.Matching[id]) {
        _newIds.push(id)
        _nodeIds.push(this.Matching[id])
      } else {
        const _parentId = _iTree.getNodeParentId(id)
        if (_parentId !== _rootId) {
          _toUpdate = true
          _newIds.push(_parentId)
        }
      }
    })

    if (_toUpdate) this.viewer.select(_newIds)
    else _treePanel.select_node(_nodeIds)
  }

  handleIsolate({
    nodeIdArray,
    model,
  }: {
    nodeIdArray: number[]
    model: Autodesk.Viewing.Model
  }) {
    const _iTree = model.getInstanceTree()
    const _rootId = _iTree.getRootId()

    if (nodeIdArray.length && !nodeIdArray.includes(_rootId))
      this.BackToParent.setState(1)
    else this.BackToParent.setState(2)
  }
}

const CustomToolbarExtensionName = 'CustomToolbarExtension'
Autodesk.Viewing.theExtensionManager.registerExtension(
  CustomToolbarExtensionName,
  CustomToolbarExtension
)

export default CustomToolbarExtensionName
