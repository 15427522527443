import { DEFAULT_SETTINGS } from '../../consts'
import i18n from '../../i18next'

const $ = window.$
const Autodesk = window.Autodesk

interface InfoValue {
  qta: number
  desc: string
  panel_category: string
  [key: string]: any
}

export default class InfoPanel extends Autodesk.Viewing.UI.PropertyPanel {
  // Properties
  Matching: IMatching
  Viewer: Autodesk.Viewing.GuiViewer3D
  Settings: ISettings

  // Contructors
  constructor(
    viewer: Autodesk.Viewing.GuiViewer3D,
    parentContainer: HTMLElement,
    id: string,
    title: string,
    options?: Autodesk.Viewing.UI.DockingPanelOptions
  ) {
    super(parentContainer, id, title, options)
    this.Matching = []
    this.Viewer = viewer
    this.Settings = { ...DEFAULT_SETTINGS }

    this.setInfoPanelValues = this.setInfoPanelValues.bind(this)
  }

  //Methods
  initialize() {
    super.initialize()

    const _height = ($(this.container.parentElement).height() * 70) / 100
    this.container.style.top = '30%'
    this.container.style.height = `${_height}px`
    this.container.style.width = '450px'
    this.container.style.resize = 'both'
  }

  setSelectedEvent() {
    this.addEventListener(
      this.Viewer,
      Autodesk.Viewing.SELECTION_CHANGED_EVENT,
      this.setInfoPanelValues
    )
  }

  setSettings(settings: ISettings) {
    this.Settings = settings

    if (this.isVisible()) {
      this.setInfoPanelValues()
    }
  }

  setMatching(matching: IMatching) {
    this.Matching = matching
  }

  setInfoPanelValues() {
    const _dbIdArray = this.Viewer.getSelection()
    const _treePanel = $('#spp-viewer-tree').jstree(true)

    let _values: InfoValue[] = []
    this.removeAllProperties()
    if (!_dbIdArray.length) {
      _dbIdArray.push(this.Viewer.model.getRootId())
    }

    _dbIdArray.forEach(dbId => {
      if (this.Matching[dbId]) {
        const _nodeId = this.Matching[dbId]
        let _nodeData = _treePanel.get_node(_nodeId).data
        _nodeData = _nodeData ? _nodeData : {}
        _values.push({
          qta: _nodeData.quantity ? _nodeData.quantity : 1,
          desc: _nodeData.desc ? _nodeData.desc : '-',
          ..._nodeData.properties,
          panel_category: `${_nodeData.model} - `,
        })
      }
    })

    if (!_values.length) {
      this.setTitle(i18n.t('noSelectedNode'), {})
      _values.push({
        qta: 0,
        desc: '-',
        panel_category: '',
      })
    } else if (_values.length === 1) {
      this.setTitle(
        _values[0].panel_category
          ? 'Info: ' + _values[0].panel_category
          : i18n.t('infoSelectedNode'),
        {}
      )
      _values[0].panel_category = ''
    } else {
      this.setTitle('Info Selected Nodes', {})
    }

    _values.forEach(val => {
      this.addProperty(
        i18n.t('description'),
        val.desc,
        val.panel_category + 'Default info'
      )
      this.addProperty(
        i18n.t('quantity'),
        `${val.qta}`,
        val.panel_category + 'Default info'
      )
      this.Settings.infoSelectedNode.forEach(info => {
        const _val = val[info] ? val[info] : '-'
        this.addProperty(info, _val, val.panel_category + 'Custom info')
      })
    })
  }
}
