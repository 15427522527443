import React from 'react'
import { Fab, Tooltip } from '@material-ui/core'

import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart'
import HomeIcon from '@material-ui/icons/Home'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

// import SettingsModal from './settings_modal'
import { ID_TREE } from '../../consts'
import { useTranslation } from 'react-i18next'

const $ = window.$

interface IMenuBar {
  fullScreen: boolean
  backHistory: string[]
  setBackHistory: (arg0: string[]) => void
  forwardHistory: string[]
  setForwardHistory: (arg0: string[]) => void
  recomendedIds: number[]
  addRecToCart: () => void
  selectedNode: IJSTreeNode | undefined
}
const MenuBar = (props: IMenuBar) => {
  const {
    fullScreen,
    backHistory,
    setBackHistory,
    forwardHistory,
    setForwardHistory,
    recomendedIds,
    addRecToCart,
    selectedNode,
  } = props

  const tree = $(`#${ID_TREE}`).jstree(true)
  const { t } = useTranslation()

  const btnHomeClick = () => {
    tree.deselect_all()
    tree.select_node(tree.get_json()[0].id)
  }

  const btnLeftClick = () => {
    const _lastElements = backHistory.slice(-2)
    setBackHistory(backHistory.slice(0, backHistory.length - 2))
    setForwardHistory(_lastElements.concat(forwardHistory))

    tree.deselect_all()
    tree.select_node(_lastElements[0])
  }

  const btnRightClick = () => {
    tree.deselect_all()
    tree.select_node(forwardHistory[0])
  }

  const _buttons = [
    {
      icon: <HomeIcon />,
      disabled: !tree, // || !tree.settings.core.data.length,
      onClick: btnHomeClick,
      tooltip: t('goToRoot'),
    },
    {
      icon: <ChevronLeftIcon />,
      disabled: !tree || backHistory.length < 2,
      onClick: btnLeftClick,
      tooltip: t('goBack'),
    },
    {
      icon: <ChevronRightIcon />,
      disabled: !tree || !forwardHistory.length,
      onClick: btnRightClick,
      tooltip: t('goForward'),
    },
  ]

  //   if (level != '2') {
  //     _buttons.push({
  //       icon: 'fas fa-cog',
  //       onClick: showSettings,
  //       tooltip: i18n.t('pageSettings'),
  //     })
  //   }

  if (recomendedIds.length) {
    _buttons.push({
      icon: <AddShoppingCartIcon />,
      onClick: addRecToCart,
      disabled: false,
      tooltip: t('addRecommendedSparePartsToCart'),
    })
  }

  return (
    <div style={{ display: fullScreen ? 'none' : 'flex' }}>
      {selectedNode &&
        _buttons.map((btn, i) =>
          !btn.disabled ? (
            <Tooltip key={i} title={btn.tooltip} placement='bottom' arrow>
              <Fab
                style={{ marginRight: '5px', marginBottom: '10px' }}
                color='primary'
                size='small'
                key={i}
                disabled={btn.disabled}
                onClick={btn.onClick}
              >
                {btn.icon}
              </Fab>
            </Tooltip>
          ) : (
            <Fab
              style={{ marginRight: '5px', marginBottom: '10px' }}
              color='primary'
              size='small'
              key={i}
              disabled={btn.disabled}
              onClick={btn.onClick}
            >
              {btn.icon}
            </Fab>
          )
        )}
    </div>
  )
}

export default MenuBar
