const $ = window.$
const Autodesk = window.Autodesk
const spColor = new window.THREE.Vector4(0, 1, 0, 0.5)
const cartColor = new window.THREE.Vector4(0, 0, 1, 0.5)
const nospColor = new window.THREE.Vector4(1, 0, 0, 0.5)
const spClass = 'jstree-sp'
const cartClass = 'jstree-cart'

export function colorSpareparts(spIds, cartIds, viewer, isolateActive) {
  viewer.clearThemingColors(viewer.model)
  $(`.${spClass}`).removeClass(spClass)
  $(`.${cartClass}`).removeClass(cartClass)

  spIds.forEach((dbId) => {
    if (cartIds.includes(dbId)) {
      viewer.setThemingColor(dbId, cartColor, viewer.model, true)
      $(`#${dbId}_anchor`).addClass(cartClass)
    } else if (isolateActive) {
      viewer.setThemingColor(dbId, spColor, viewer.model, true)
      $(`#${dbId}_anchor`).addClass(spClass)
    }
  })
}

export default function IsolateSparepartsExtension(viewer, options) {
  Autodesk.Viewing.Extension.call(this, viewer, options)
}

IsolateSparepartsExtension.prototype = Object.create(
  Autodesk.Viewing.Extension.prototype
)
IsolateSparepartsExtension.prototype.constructor = IsolateSparepartsExtension

IsolateSparepartsExtension.prototype.load = function () {
  if (this.viewer.toolbar) {
    this.createUI()
  } else {
    this.viewer.addEventListener(
      Autodesk.Viewing.TOOLBAR_CREATED_EVENT,
      this.onToolbarCreated.bind(this)
    )
  }
  return true
}

IsolateSparepartsExtension.prototype.onToolbarCreated = function () {
  this.viewer.removeEventListener(
    Autodesk.Viewing.TOOLBAR_CREATED_EVENT,
    this.onToolbarCreated.bind(this)
  )
  this.createUI()
}

IsolateSparepartsExtension.prototype.createUI = function () {
  const viewer = this.viewer
  this.sparePartsIds = []

  const button1 = new Autodesk.Viewing.UI.Button('isolate-spareparts')
  button1.onClick = () => {
    const _cartItems = [...document.getElementsByClassName('menu-item-basket')]
    const _idCartItems = _cartItems.map((item) => parseInt(item.id))

    if (!this.activeStatus) {
      this.activeStatus = true
      button1.setToolTip('Mostra tutti gli oggetti')
    } else {
      this.activeStatus = false
      button1.setToolTip('Isola Parti di Ricambio')
    }

    colorSpareparts(this.sparePartsIds, _idCartItems, viewer, this.activeStatus)
  }
  button1.addClass('fas')
  button1.setIcon('fa-cubes')
  button1.setToolTip('Isola Parti di Ricambio')

  const subToolbar = new Autodesk.Viewing.UI.ControlGroup(
    'custom-group-toolbar'
  )
  subToolbar.addControl(button1)

  viewer.toolbar.addControl(subToolbar)
}
