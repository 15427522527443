import React from 'react'
import ReactDOM from 'react-dom'
import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ServerError,
  ApolloClientOptions,
  NormalizedCacheObject,
  ApolloProvider,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import axios from 'axios'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

import 'fontsource-roboto'
import './i18next'

import App from './App'

import './styles/index.scss'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#114f8e',
    },
    secondary: {
      main: '#fdb813',
    },
  },
})

const token = localStorage.getItem('token')
if (token) {
  axios.defaults.headers.common['Authorization'] = 'JWT ' + token
}

const authLink = setContext((_: any, { headers }: any) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token')
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const handleUnauthorized = onError(({ networkError }: any) => {
  const _netErro = networkError as ServerError
  if (_netErro?.statusCode === 401) {
    localStorage.removeItem('token')
    window.location.reload()
  }
})

const apolloOptions: ApolloClientOptions<NormalizedCacheObject> = {
  cache: new InMemoryCache(),
  link: authLink.concat(
    handleUnauthorized.concat(
      new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_PLATFORM })
    )
  ),
}

const client = new ApolloClient(apolloOptions)

axios.interceptors.response.use(
  (res) => {
    return res
  },
  (error) => {
    if (error.response?.status !== 401) {
      return new Promise((resolve, reject) => {
        reject(error)
      })
    }

    localStorage.removeItem('token')
    window.location.reload()

    return new Promise((resolve, reject) => {
      reject(error)
    })
  }
)

ReactDOM.render(
  <ApolloProvider client={client}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </ApolloProvider>,
  document.getElementById('root')
)
