import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useCartItems, useUserInfo } from '../../context'

import {
  usePopupState,
  bindTrigger,
  bindPopover,
} from 'material-ui-popup-state/hooks'

import {
  makeStyles,
  Popover,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Badge,
  Icon,
  Tooltip,
} from '@material-ui/core'

import AccountCircle from '@material-ui/icons/AccountCircle'
import HomeIcon from '@material-ui/icons/Home'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import SettingsIcon from '@material-ui/icons/Settings'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'

import TranslateIcon from '@material-ui/icons/Translate'
import { BugReport } from '@material-ui/icons'

import axios from 'axios'

import { useTranslation } from 'react-i18next'
import { LanguageSelector } from '../language_selector'
import ModalReportBug from './modal_report_bug'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  title: {
    flexGrow: 1,
  },
}))

const Navbar = () => {
  const [OpenReportModal, setOpenReportModal] = useState(false)

  const classes = useStyles()
  const history = useHistory()
  const { state } = useLocation()

  const { cartItems } = useCartItems()
  const { userInfo } = useUserInfo()

  const popupState = usePopupState({ variant: 'popover', popupId: 'userMenu' })

  const logout = () => {
    localStorage.removeItem('token')
    axios.defaults.headers.common['Authorization'] = null
    history.push('/login')
  }

  const backToHome = () => {
    history.push('/')
  }

  const historyBack = () => {
    const _state: { from?: '' } = state ? state : {}
    const _from: string = _state.from ? _state.from : ''
    if (_from !== 'login') {
      history.goBack()
    }
  }

  const { t } = useTranslation()

  return (
    <AppBar position='fixed' className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Typography variant='h6' className={classes.title}>
          SPP
        </Typography>
        <Tooltip title={t<string>('goBack')} placement='bottom' arrow>
          <IconButton color='inherit' onClick={historyBack}>
            <ChevronLeftIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t<string>('cart')} placement='bottom' arrow>
          <Badge
            badgeContent={cartItems?.length}
            color='error'
            overlap='circle'
          >
            <IconButton color='inherit' onClick={() => history.push('/cart')}>
              <ShoppingCartIcon />
            </IconButton>
          </Badge>
        </Tooltip>
        <Tooltip title={t<string>('backHome')} placement='bottom' arrow>
          <IconButton color='inherit' onClick={backToHome}>
            <HomeIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t<string>('user')} placement='bottom' arrow>
          <IconButton color='inherit' {...bindTrigger(popupState)}>
            <AccountCircle />
          </IconButton>
        </Tooltip>
        <Popover
          {...bindPopover(popupState)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <List>
            <ListItem>
              <ListItemText primary={userInfo.email} />
            </ListItem>
            {userInfo.type === '1' || userInfo.type === '2' ? (
              <>
                <ListItem button onClick={() => history.push('/settings')}>
                  <ListItemIcon>
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText primary={t<string>('settings')} />
                </ListItem>
                <ListItem button onClick={() => setOpenReportModal(true)}>
                  <ListItemIcon>
                    <BugReport />
                  </ListItemIcon>
                  <ListItemText primary={t<string>('reportBug')} />
                </ListItem>
              </>
            ) : (
              <></>
            )}
            <ListItem>
              <ListItemIcon>
                <TranslateIcon />
              </ListItemIcon>
              <LanguageSelector />
            </ListItem>
            <ListItem button onClick={logout}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary={t<string>('logout')} />
            </ListItem>
          </List>
        </Popover>
      </Toolbar>
      <ModalReportBug
        open={OpenReportModal}
        onClose={() => setOpenReportModal(false)}
        fullWidth={true}
        maxWidth='md'
      />
    </AppBar>
  )
}

export { Navbar }
