import { ID_TREE, ID_TREE_NODE } from '../consts/viewer'
import { createNewJsonNode } from '../utils/tree'
import { MyContextMenuExtension } from '../extensions'

const $ = window.$ || {}

export const backToParent = (matching: IMatching) => {
  const NOP_VIEWER = window.NOP_VIEWER || {}
  const _isolatedNodes = NOP_VIEWER.getIsolatedNodes()
  const _tree = $(`#${ID_TREE}`).jstree(true)
  const _treeNode = $(`#${ID_TREE_NODE}`).jstree(true)

  if (_isolatedNodes.length) {
    const _iTree = NOP_VIEWER.model.getInstanceTree()
    const _rootId = _iTree.getRootId()
    const _parentId = _iTree.getNodeParentId(_isolatedNodes[0])
    const _parentNodeId = matching[_parentId]

    if (_parentNodeId) {
      let _jsonTree = createNewJsonNode(_tree.get_node(_parentNodeId), _tree)
      setTreeProperties(_jsonTree, matching, true)

      _treeNode.settings.core.data = [
        {
          id: _jsonTree.id,
          text: _jsonTree.text,
          type: _jsonTree.type,
          data: _jsonTree.data,
          children: _jsonTree.children,
        },
      ]
      _treeNode.open_node(_jsonTree.id)
      _treeNode.refresh()

      NOP_VIEWER.isolate(_rootId !== _parentId ? [_parentId] : [])
      NOP_VIEWER.fitToView(_rootId !== _parentId ? [_parentId] : [])
    }

    if ('CustomMenu' in NOP_VIEWER.getLoadedExtensions()) {
      const _cme = NOP_VIEWER.getExtension(
        'CustomMenu'
      ) as MyContextMenuExtension
      _cme.setHiddenElements([])
    }
  } else {
    const _selectedNode = _tree.get_node(_tree.get_selected()[0])
    const _parentUrnNode = getParentUrn(_tree, _selectedNode.parent)
    if (_parentUrnNode) {
      _tree.deselect_all()
      _tree.select_node(_parentUrnNode)
    }
  }
}

export const backToRoot = (
  matching: IMatching,
  callback?: (arg0: object) => void
) => {
  const _isolatedNodes = window.NOP_VIEWER.getIsolatedNodes()
  const _tree = $(`#${ID_TREE}`).jstree(true)
  const _treeNode = $(`#${ID_TREE_NODE}`).jstree(true)

  if (_isolatedNodes.length) {
    const _iTree = window.NOP_VIEWER.model.getInstanceTree()
    const _rootId = _iTree.getRootId()
    const _rootNodeId = matching[_rootId]
    if (_rootNodeId) {
      let _jsonTree = createNewJsonNode(_tree.get_node(_rootNodeId), _tree)
      setTreeProperties(_jsonTree, matching, true)

      _treeNode.settings.core.data = [
        {
          id: _jsonTree.id,
          text: _jsonTree.text,
          type: _jsonTree.type,
          data: _jsonTree.data,
          children: _jsonTree.children,
        },
      ]
      _treeNode.open_node(_jsonTree.id)
      _treeNode.refresh()
    }
  }
  if (callback) {
    callback(_treeNode)
  } else {
    window.NOP_VIEWER.isolate([])
    window.NOP_VIEWER.fitToView([])
  }
}

const setTreeProperties = (
  node: IJSTreeNode,
  matching: IMatching,
  isRoot = false
) => {
  const _dbIds = Object.keys(matching).filter(
    key => matching[parseInt(key)] === node.id
  )

  if (!_dbIds.length) {
    node.type = 'no-component'
  } else {
    node.data.dbId = _dbIds.map(dbId => parseInt(dbId))
    node.type = _dbIds.length === 1 ? 'component' : 'components'
  }

  if (node.data.urn) {
    node.type = 'model'
    node.children = isRoot ? node.children : []
  }

  if (node.children && node.children.length) {
    node.children.forEach(child => setTreeProperties(child, matching))
  }

  node = {
    id: node.id,
    text: node.text,
    type: node.type,
    data: node.data,
    children: node.children,
    parent: node.parent,
  }
}

export const getParentUrn = (
  tree: JSTree,
  parentId: string
): IJSTreeNode | null => {
  const _node = tree.get_node(parentId)
  if (_node.data && _node.data.urn) return _node
  else if (_node.parent !== '#' && _node.parent !== null)
    return getParentUrn(tree, _node.parent)
  else return null
}

export const isolateItems = () => {
  let _treeNode = $(`#${ID_TREE_NODE}`).jstree(true) as JSTree
  if (!_treeNode) _treeNode = $(`#spp-viewer-tree`).jstree(true) as JSTree
  if (!_treeNode) return

  const _selectedNodes = _treeNode.get_selected()
  const _firstNode = _treeNode.get_node(_selectedNodes[0])
  if (_selectedNodes.length === 1 && _firstNode.data.urn) {
    const _isolatedNodes = window.NOP_VIEWER
      ? window.NOP_VIEWER.getIsolatedNodes()
      : []
    if (_isolatedNodes.length) {
      window.NOP_VIEWER.isolate([])
      window.NOP_VIEWER.fitToView([])
    }

    focusItem(_selectedNodes[0])
  } else {
    let _dbIds: number[] = []
    let _newData: IJSTreeNode[] = []
    _selectedNodes.forEach((node: string) => {
      const _nodeData = _treeNode.get_node(node)
      if (_nodeData.data.dbId) _dbIds.push(_nodeData.data.dbId[0])

      _newData.push(_treeNode.get_json(node))
    })

    if (_treeNode.settings) {
      _treeNode.settings.core.data = _newData
      _treeNode.refresh()
    }
    window.NOP_VIEWER.isolate(_dbIds)
    window.NOP_VIEWER.fitToView(_dbIds)
  }
}

const focusItem = (node: string) => {
  const _tree = $(`#${ID_TREE}`).jstree(true) as JSTree
  _tree.deselect_all()
  _tree.select_node(node)
}

export const highlightsSpareparts = (
  highlights: boolean,
  spIds: number[] | null,
  recIds: number[] = []
) => {
  const _viewer = window.NOP_VIEWER

  if (highlights && spIds != null) {
    spIds.forEach(dbId => {
      _viewer.setThemingColor(
        dbId,
        new window.THREE.Vector4(0, 1, 0, 0.5),
        _viewer.model,
        true
      )
    })
    recIds.forEach(dbId => {
      _viewer.setThemingColor(
        dbId,
        new window.THREE.Vector4(1, 1, 0, 0.5),
        _viewer.model,
        true
      )
    })
    $(`#${ID_TREE_NODE}`).addClass('hl-spp')
  } else {
    _viewer.clearThemingColors(_viewer.model)
    $(`#${ID_TREE_NODE}`).removeClass('hl-spp')
  }
}

export const getSparepartsCode = (matching: IMatching) => {
  let _tree = $(`#${ID_TREE}`).jstree(true)

  if (!_tree) return { _spIds: null, _spNodes: null }

  const _spIds: number[] = []
  const _recIds: number[] = []
  const _insIds: number[] = []
  Object.keys(matching).forEach(id => {
    const _node = _tree.get_node(matching[parseInt(id)])
    if (_node) {
      const _liAttr = _node.li_attr ? _node.li_attr : { class: '' }
      const _data = _node.data ? _node.data : {}

      if (_liAttr.class?.trim().includes('jstree-sp-rec'))
        _recIds.push(parseInt(id))
      else if (_liAttr.class?.trim().includes('jstree-sp'))
        _spIds.push(parseInt(id))

      if (_data.inseparable) _insIds.push(parseInt(id))
    }
  })

  return { _spIds, _recIds, _insIds }
}

export const getSpCodeFromTreeData = (
  treeNode: IJSTreeNode,
  parentNode?: IJSTreeNode
) => {
  const _res: {
    spIds: number[]
    recIds: number[]
    insIds: number[]
  } = {
    spIds: [],
    recIds: [],
    insIds: [],
  }

  if (treeNode) {
    if (treeNode.children) {
      const _liAttr = treeNode.li_attr ? treeNode.li_attr : { class: '' }
      const _data = treeNode.data ? treeNode.data : { dbId: undefined }
      const _parentData = parentNode?.data
        ? parentNode.data
        : { inseparable: undefined }
      const _dbId = _data.dbId ? _data.dbId : []

      treeNode.children.forEach(node => {
        const _childRes = getSpCodeFromTreeData(node, treeNode)
        _res.spIds = _res.spIds.concat(_childRes.spIds)
        _res.recIds = _res.recIds.concat(_childRes.recIds)
        _res.insIds = _res.insIds.concat(_childRes.insIds)
      })

      if (_liAttr.class?.trim().includes('jstree-sp-rec')) {
        _res.recIds = _res.recIds.concat(_dbId)
      } else if (_liAttr.class?.trim().includes('jstree-sp')) {
        _res.spIds = _res.spIds.concat(_dbId)
      }

      if (_parentData.inseparable) {
        _res.insIds = _res.insIds.concat(_dbId)
      }
    }
  }

  return _res
}

export const addToCartSS = (
  viewer: Autodesk.Viewing.Viewer3D,
  item: IJSTreeNode,
  machineUrl: string,
  addToCart: (arg0: ICartItem[]) => void
) => {
  viewer.getScreenShot(0, 0, (urlBlob: string) => {
    getBlobFromUrl(urlBlob)
      .then(fromBlobToBase64)
      .then((result: string | ArrayBuffer | null) => {
        if (result) {
          const _newCartItem: ICartItem = {
            id: 0,
            componentId: item.data.componentId,
            componentCode: item.data.name ? item.data.name : '',
            componentDescription: item.data.desc ?? '',
            quantity: 1,
            img: result.toString(),
            machineUrl: machineUrl,
            dbId: item.data.dbId ? item.data.dbId[0] : 0,
            relationId: parseInt(item.id),
            revBomId: parseInt(window.location.pathname.split('/')[3]),
            serialNumberId: parseInt(window.location.pathname.split('/')[2]),
            serialNumber: undefined,
          }
          addToCart([_newCartItem])
        }
      })
  })
}

function getBlobFromUrl(bUrl: string) {
  return new Promise((resolve, reject) => {
    let xhr = new XMLHttpRequest()
    xhr.responseType = 'blob'
    xhr.addEventListener('load', event => {
      if (xhr.status === 200) {
        resolve(xhr.response)
      } else {
        reject(new Error('Cannot retrieve blob'))
      }
    })

    xhr.open('GET', bUrl, true)
    xhr.send()
  })
}

function fromBlobToBase64(blob: any) {
  return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
    let reader = new FileReader()
    reader.addEventListener('loadend', event => {
      resolve(reader.result)
    })
    reader.readAsDataURL(blob)
  })
}

export const updateNodeSettings = (
  node: IJSTreeNode,
  settings: ISettings,
  recursive = false
) => {
  const _properties = node.data.properties ? node.data.properties : {}

  if (settings.spareparts === 'prop' || settings.spareparts === 'both') {
    const _logicSp = settings.spp_prop ? settings.spp_prop : {}
    const _logicRec = settings.spp_rec ? settings.spp_rec : {}

    let _classVal = ''
    if (node.li_attr) _classVal = node.li_attr.class
    if (
      evalLogic(_properties, _logicRec) &&
      !_classVal.includes('jstree-sp-rec')
    ) {
      node.li_attr = { class: `jstree-sp-rec ${_classVal}` }
    } else if (
      evalLogic(_properties, _logicSp) &&
      !_classVal.includes('jstree-sp')
    ) {
      node.li_attr = { class: `jstree-sp ${_classVal}` }
    }
  } else {
    const _logicRec = settings.spp_rec ? settings.spp_rec : {}

    let _classVal = ''
    if (node.li_attr) _classVal = node.li_attr.class
    if (
      evalLogic(_properties, _logicRec) &&
      !_classVal.includes('jstree-sp-rec')
    ) {
      node.li_attr = { class: `jstree-sp-rec ${_classVal}` }
    }
  }

  const _logicIns = settings.ins_group ? settings.ins_group : {}
  if (evalLogic(_properties, _logicIns)) node.data.inseparable = true

  if (recursive) {
    if (node.children) {
      node.children.forEach(
        child => (child = updateNodeSettings(child, settings, true))
      )
    }
  }

  return node
}

const evalLogic: (arg0: any, arg1: any) => boolean = (
  properties: any,
  logic: any
) => {
  let _res: boolean = false
  if ('and' in logic) {
    _res = true

    logic['and'].forEach((rule: any) => {
      _res = _res && evalLogic(properties, rule)
    })
  } else if ('or' in logic) {
    logic['or'].forEach((rule: any) => {
      _res = _res || evalLogic(properties, rule)
    })
  } else if ('!' in logic) {
    _res = !evalLogic(properties, logic['!'])
  } else if ('!!' in logic) {
    _res = evalLogic(properties, logic['!!'])
  } else if ('in' in logic) {
    const _value = logic['in'][0]
    const _prop = logic['in'][1]['var']
    const _key = _prop.replace(/\|/g, '.')
    _res = properties[_key] === _value
  } else if ('var' in logic) {
    const _prop = logic['var']
    const _key = _prop.replace(/\|/g, '.')
    _res = properties[_key] !== undefined && properties[_key] !== ''
  }

  return _res
}
