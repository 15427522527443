import React, { useState, useEffect, useContext } from 'react'
import clsx from 'clsx'
import { useParams } from 'react-router-dom'

import { CubeLoader } from '../../components/loader'

import { Grid, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'

// import TableContent from './table_content'
import { CustomViewer } from './viewer'
import Info from './info'
import MenuBar from './menu_bar'
import { CartItemsContext, useFullScreen, useSettings } from '../../context'
import Sidebar from './sidebars'
import { ID_TREE_NODE, ID_TREE, ID_SEARCH } from '../../consts'
import {
  isolateItems,
  backToRoot,
  updateNodeSettings,
} from '../../utils/viewer'
import { gql, useLazyQuery } from '@apollo/client'

const $ = window.$

const prefersDarkMode = false // useMediaQuery('(prefers-color-scheme: dark)')
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  sidebar: {
    height: 'calc(100vh - 64px)',
    backgroundColor: prefersDarkMode ? '#424242' : '#ffffff',
    overflow: 'hidden',
  },
  viewer: {
    padding: theme.spacing(2),
    height: 'calc(100vh - 64px)',
  },
  paper: {
    padding: theme.spacing(2),
  },
}))

interface IQueryTree {
  bomsQuery: {
    bom: IBom
  }
}
const GET_TREE = gql`
  query getTree($id: Int!) {
    bomsQuery {
      bom(id: $id) {
        id
        revProduct
        revProductId
        tree
      }
    }
  }
`

const Machine = () => {
  const { revBomId, serialNumberId } = useParams<any>()

  const [urn, setUrn] = useState('')
  const [showMenu, setShowMenu] = useState(false)
  const [backHistory, setBackHistory] = useState<string[]>([])
  const [selectedNode, setSelectedNode] = useState<IJSTreeNode>()
  const [forwardHistory, setForwardHistory] = useState<string[]>([])
  const [MenuSelected, setMenuSelected] = useState('')
  const [Matching, setMatching] = useState<IMatching>()
  const [RecomendeIds, setRecomendedIds] = useState<number[]>([])
  const [Loading, setLoading] = useState(false)

  const { addCartItems } = useContext(CartItemsContext)
  const { fullScreen, setFullScreen } = useFullScreen()
  const { settings } = useSettings()

  const classes = useStyles()

  useEffect(() => {
    setShowMenu(true)
    setMenuSelected(ID_TREE)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!selectedNode) return

    const _tree = $(`#${ID_TREE}`).jstree(true)
    let _urn
    if (selectedNode)
      _urn = selectedNode.data.urn
        ? `urn:${btoa(selectedNode.data.urn)}`
        : undefined
    if (_urn && _urn !== urn) {
      setUrn(_urn)
    } else if (window.NOP_VIEWER) {
      const _root = _tree.settings.core.data[0]
      if (_root && _root.data.urn) {
        if (Matching) backToRoot(Matching, isolateSelectedNodes)
      } else {
        setUrn('')
      }
    }

    if (selectedNode) {
      const _backHistory = [...backHistory]
      _backHistory.push(selectedNode.id)
      setBackHistory(_backHistory)

      if (selectedNode.id === forwardHistory[0]) {
        setForwardHistory(forwardHistory.slice(1))
      } else {
        setForwardHistory([])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNode])

  const [getTree, { data: treeData, loading: treeDataLoading }] = useLazyQuery<
    IQueryTree,
    { id: number }
  >(GET_TREE, {
    variables: { id: revBomId },
  })

  useEffect(() => {
    getTree()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revBomId, serialNumberId])

  useEffect(() => {
    const _tree = $(`#${ID_TREE}`).jstree(true)
    setLoading(true)

    if (treeData) {
      setLoading(false)

      const _data = treeData.bomsQuery.bom.tree

      _data?.children?.forEach(
        d => (d = updateNodeSettings(d, settings.value, true))
      )
      _tree.settings.core.data = _data
      _tree.refresh()
    }
  }, [treeData, settings])

  const updateSelectedNode = (tree: JSTree, id: string) => {
    if (tree.get_json().length) {
      tree.deselect_all()
      tree.select_node(id)
    } else {
      setTimeout(() => updateSelectedNode(tree, id), 500)
    }
  }

  const isolateSelectedNodes = () => {
    const _treeNode = $(`#${ID_TREE_NODE}`).jstree(true)
    if (_treeNode.get_json().length && selectedNode) {
      let _id = selectedNode.id
      if (MenuSelected === ID_SEARCH) {
        _id = selectedNode.data.refs ? selectedNode.data.refs[0].id : ''
      }
      _treeNode.deselect_all()
      _treeNode.select_node(_id)
      const _node = _treeNode.get_node(_id)
      if (_node && _node.data.dbId) isolateItems()
      else {
        window.NOP_VIEWER.isolate([])
        window.NOP_VIEWER.fitToView([])
      }
    } else {
      setTimeout(() => isolateSelectedNodes(), 500)
    }
  }

  const addRecToCart = () => {
    const _tree = $(`#${ID_TREE}`).jstree(true)

    if (Matching)
      addCartItems(RecomendeIds.map(id => _tree.get_node(Matching[id])))
  }

  let _content
  if (selectedNode) {
    _content = (
      <Grid container spacing={2}>
        <Grid item xs>
          <Box>
            <div
              style={{
                height: `calc(100vh - ${fullScreen ? '90px' : '140px'})`,
                width: '100%',
                position: 'relative',
                top: '0',
              }}
            >
              <CustomViewer
                urn={urn}
                fullScreen={fullScreen}
                selectedNode={selectedNode}
                setFullScreen={setFullScreen}
                matching={Matching}
                setMatching={setMatching}
                recomendedIds={RecomendeIds}
                setRecomendedIds={setRecomendedIds}
                addRecToCart={addRecToCart}
              />
            </div>
          </Box>
        </Grid>
        {fullScreen === false ? (
          <Grid item xs>
            <Paper className={classes.paper}>
              <Info selectedNode={selectedNode} />
            </Paper>
          </Grid>
        ) : (
          ''
        )}
      </Grid>
    )
  } else {
    _content = <CubeLoader />
  }

  return (
    <div className={classes.root}>
      <Grid container id='home-page'>
        <Box boxShadow={4}>
          <Grid item className={clsx(classes.sidebar)}>
            <Sidebar
              machineId={revBomId ? parseInt(revBomId) : undefined}
              showMenu={showMenu}
              menuSelected={MenuSelected}
              setMenuSelected={setMenuSelected}
              fullScreen={fullScreen}
              loading={Loading}
              updateSelectedNode={setSelectedNode}
            />
          </Grid>
        </Box>
        <Grid item xs className={clsx(classes.viewer)}>
          {!fullScreen && (
            <MenuBar
              fullScreen={fullScreen}
              backHistory={backHistory}
              setBackHistory={setBackHistory}
              forwardHistory={forwardHistory}
              setForwardHistory={setForwardHistory}
              recomendedIds={RecomendeIds}
              addRecToCart={addRecToCart}
              selectedNode={selectedNode}
            />
          )}
          {_content}
        </Grid>
      </Grid>
    </div>
  )
}

export default Machine
