import { isolateItems, addToCartSS } from '../../utils/viewer'
import React, { ChangeEvent } from 'react'
import ReactDOM from 'react-dom'
import { TextField } from '@material-ui/core'
import { DEFAULT_SETTINGS } from '../../consts'
import i18n from '../../i18next'
const $ = window.$
const Autodesk = window.Autodesk

interface IContextMenuTree {
  [key: string]: {
    label: string
    action: () => void
    icon: string
  }
}

export default class CustomTreeModelPanel extends Autodesk.Viewing.UI
  .DockingPanel {
  //Properties
  Viewer: Autodesk.Viewing.GuiViewer3D
  Matching: IMatching
  TreeData: IJSTreeNode[]
  UserInfo: IUserInfo
  Tree: JSTree | null
  AddToCart: (arg0: ICartItem[]) => void
  Settings: ISettings

  // Constructors
  constructor(
    viewer: Autodesk.Viewing.GuiViewer3D,
    parentContainer: HTMLElement,
    id: string,
    title: string,
    options?: Autodesk.Viewing.UI.DockingPanelOptions
  ) {
    super(parentContainer, id, title, options)
    this.Viewer = viewer
    this.Matching = []
    this.TreeData = []
    this.UserInfo = {
      email: '',
      region: 'en',
      companyName: '',
      type: '',
      loginId: '',
      customerId: '',
      businessPartner: '',
      businessPartnerId: '',
      businessPartnerCode: '',
      crmCustomerId: '',
    }
    this.Settings = { ...DEFAULT_SETTINGS }
    this.Tree = null
    this.AddToCart = () => {}

    this.updateSelectedNode = this.updateSelectedNode.bind(this)
    this.getNodesData = this.getNodesData.bind(this)
  }

  initialize() {
    super.initialize()

    this.container.style.top = '0'
    this.container.style.height = '100%'
    this.container.style.resize = 'both'

    const _divSearch = document.createElement('div')
    _divSearch.className = 'docking-panel-search'
    this.container.appendChild(_divSearch)

    const _inputSearch = (
      <TextField
        id='tree-search'
        placeholder={`${i18n.t('search')}..`}
        fullWidth
        onChange={(ev: ChangeEvent<HTMLInputElement>) => {
          const _jsTree = this.Tree ? this.Tree.jstree(true) : null
          if (!_jsTree) return

          _jsTree.search(ev.target.value)
        }}
      />
    )

    ReactDOM.render(_inputSearch, _divSearch)

    const _divTree =
      '<div class="docking-panel-scroll docking-panel-container-solid-color-a right mr-2" id="spp-viewer-tree" />'
    $(this.container).append(_divTree)
  }

  setTreeData(treeData: IJSTreeNode[]) {
    this.TreeData = treeData

    if (treeData.length) {
      if (this.Tree && this.Tree.jstree(true)) this.Tree.jstree(true).destroy()
      else this.Tree = $('#spp-viewer-tree')

      if (!this.Tree) return

      const that = this
      this.Tree.jstree({
        core: {
          data: treeData,
        },
        plugins: ['types', 'contextmenu', 'search'],
        types: {
          default: { icon: 'fas fa-exclamation-circle' },
          component: { icon: 'fas fa-cog' },
          components: { icon: 'fas fa-cogs' },
          model: { icon: 'fas fa-cubes' },
          'no-component': { icon: 'fas fa-question-circle' },
        },
        contextmenu: {
          items: (node: IJSTreeNode) => {
            if (!this.Tree) return
            const _jsTree = this.Tree.jstree(true)

            const _items: IContextMenuTree = {}
            const _selectedNodes = _jsTree.get_selected()
            const _isolatedNodes = this.Viewer.getIsolatedNodes()

            if (_selectedNodes.length) {
              _items['isolate'] = {
                label: i18n.t('isolateSelectedElements'),
                action: isolateItems,
                icon: 'fab fa-creative-commons-remix',
              }
            }

            if (_isolatedNodes.length) {
              _items['showAll'] = {
                label: i18n.t('showAll'),
                action: () => {
                  this.Viewer.isolate([])
                  this.resetTreeData([])
                },
                icon: 'fas fa-eye',
              }
            }

            if (this.checkAddToCart(node)) {
              _items['addToCart'] = {
                label: i18n.t('addSelectionToCart'),
                action: () => {
                  if (!this.Tree) return

                  const _selectedNodes = this.Tree.jstree(true).get_selected()
                  const _selection = this.Viewer.getSelection()
                  const _isolatedNodes = this.Viewer.getIsolatedNodes()
                  const _hiddenNodes = this.Viewer.getHiddenNodes()
                  this.Viewer.select([])

                  const _nodesWithImage: IJSTreeNode[] = []
                  const _nodesToAdd: ICartItem[] = []
                  _selectedNodes.forEach(selectedNode => {
                    if (!this.Tree) return
                    const _node = this.Tree.jstree(true).get_node(selectedNode)
                    if (this.checkAddToCart(_node)) {
                      const _dbId = _node.data.dbId ? _node.data.dbId : null

                      if (_dbId) _nodesWithImage.push(_node)
                      else _nodesToAdd.push(_node)
                    }
                  })

                  _nodesWithImage.forEach(nodeImage => {
                    const _dbId = nodeImage.data.dbId
                    this.Viewer.isolate(_dbId)
                    this.Viewer.fitToView(_dbId, this.Viewer.model, true)
                    addToCartSS(
                      this.Viewer,
                      nodeImage,
                      window.location.pathname,
                      that.AddToCart
                    )
                  })

                  if (_nodesWithImage.length) {
                    this.Viewer.select(_selection)
                    this.Viewer.isolate(_isolatedNodes)
                    this.Viewer.hide(_hiddenNodes)
                    this.Viewer.fitToView(
                      _isolatedNodes,
                      this.Viewer.model,
                      true
                    )
                  }

                  if (_nodesToAdd.length) {
                    this.AddToCart(_nodesToAdd)
                  }
                },
                icon: 'fas fa-cart-plus',
              }
            }

            return _items
          },
        },
        search: {
          show_only_matches: true,
        },
      })
      this.Tree.on('select_node.jstree', (_, { node, event }) => {
        if (event) {
          let _ids: number[] = node.data.dbId ? node.data.dbId : []
          if (event.shiftKey || event.ctrlKey) {
            _ids = _ids.concat(that.Viewer.getSelection())
          }
          _ids = _ids.filter(id => that.Viewer.isNodeVisible(id))

          console.log(_ids.length ? [_ids[0]] : _ids)
          that.Viewer.select(_ids.length ? [_ids[0]] : _ids)
          that.Viewer.fitToView(_ids.length ? [_ids[0]] : _ids)
        } else {
          const _anchorId = node.a_attr.id
          if (_anchorId) {
            $(`#${_anchorId}`).focus()
          }
        }
      })
      this.Tree.on(
        'search.jstree',
        (_, { res, instance }: { res: IJSTreeNode[]; instance: JSTree }) => {
          let _dbIds: number[] = []
          res.forEach(nodeId => {
            const _node = instance.get_node(nodeId)
            if (_node.data.dbId) _dbIds = _dbIds.concat(_node.data.dbId)
          })

          this.Viewer.isolate(_dbIds)
          this.Viewer.fitToView(_dbIds)
        }
      )
      this.Tree.on('clear_search.jstree', (_, { instance }) => {
        const _treeData: IJSTreeNode[] = instance.get_json()

        if (_treeData[0].data.urn) {
          that.Viewer.isolate()
          that.Viewer.fitToView()
        } else {
          let _dbId: number[] = []
          _treeData.forEach(node => {
            const _nodeDbId = node.data.dbId ? node.data.dbId : []
            _dbId = _dbId.concat(_nodeDbId)
          })

          that.Viewer.isolate(_dbId)
          that.Viewer.fitToView(_dbId)
        }
      })
      this.Tree.on('loaded.jstree', (_, { instance }) => {
        const _root = instance.get_node('#')

        const _search = window.location.search
        if (_search !== '' && _search.includes('ids')) {
          const _id = _search.split('=')[1]
          this.Viewer.select(parseInt(_id))
        } else {
          instance.open_node(_root.children[0])
          this.Viewer.select([])
        }
      })
    }
  }

  resetTreeData(dbIds: number[] = []) {
    if (!this.Tree) return

    let _newData: IJSTreeNode[] = []
    if (dbIds.length) {
      const _nodeIds = dbIds.map(id => this.Matching[id])

      this.TreeData.forEach(node => {
        _newData = _newData.concat(this.getNodesData(node, _nodeIds))
      })
    } else {
      _newData = this.TreeData
    }

    const _jsTree = this.Tree.jstree(true)
    if (!_jsTree.settings) return
    _jsTree.settings.core.data = _newData
    _jsTree.refresh()
  }

  getNodesData(node: IJSTreeNode, ids: string[]) {
    let _res: IJSTreeNode[] = []

    if (ids.includes(node.id)) {
      _res.push(node)
    } else if (node.children) {
      node.children.forEach(child => {
        _res = _res.concat(this.getNodesData(child, ids))
      })
    }

    return _res
  }

  setMatching(matching: IMatching) {
    this.Matching = matching
  }

  setSelectedEvent() {
    this.addEventListener(
      this.Viewer,
      Autodesk.Viewing.SELECTION_CHANGED_EVENT,
      this.updateSelectedNode
    )
  }

  setAddToCart(addToCart: (arg0: ICartItem[]) => void) {
    this.AddToCart = addToCart
  }

  setUserInfo(userInfo: IUserInfo) {
    this.UserInfo = userInfo
  }

  updateSelectedNode() {
    const _jsTree = this.Tree ? this.Tree.jstree(true) : null
    if (!_jsTree) return

    const _ids: string[] = []
    const _dbIdArray = this.Viewer.getSelection()
    _dbIdArray.forEach(id => {
      if (this.Matching[id]) _ids.push(this.Matching[id])
    })

    _jsTree.deselect_all()
    _jsTree.select_node(_ids)
  }

  checkAddToCart(node: IJSTreeNode) {
    const _settings = this.Settings
    const _li_attr = node.li_attr ? node.li_attr : { class: '' }
    return (
      _settings.spareparts !== 'prop' ||
      _li_attr.class?.trim().includes('jstree-sp') ||
      _li_attr.class?.trim().includes('jstree-sp-rec')
    )
  }
}
