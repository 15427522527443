import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { Layout } from '../layout'

interface IPrivateRoute {
  component: any
  otherProps?: object
  exact?: boolean
  path: string
}

const PrivateRoute = ({
  component: Component,
  otherProps,
  ...rest
}: IPrivateRoute) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.token ? (
          <Layout>
            <Component {...props} {...otherProps} />
          </Layout>
        ) : (
          <Redirect to={{ pathname: '/login' }} />
        )
      }
    />
  )
}

export { PrivateRoute }
