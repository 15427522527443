import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { BOMS_URL } from '../../consts'
import {
  Box,
  CircularProgress,
  Grid,
  makeStyles,
  Paper,
} from '@material-ui/core'
import { StickyTable } from '../../components/sticky_table'
import { Document, Page } from 'react-pdf'
import Pagination from '@material-ui/lab/Pagination/Pagination'

export interface IEBomItem {
  Id: number
  RevBomId: number
  PositionNumber: number
  ItemNumber: number
  Description: string
  Quantity: number
  UM: string
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  loading: {
    margin: 'auto',
    marginTop: '50px',
  },
  pagination: {
    position: 'absolute',
    bottom: '0px',
    width: '100%',
  },
  paper: {
    margin: '8px',
    padding: '16px',
    width: '100%',
  },
}))

const ElectricBom = () => {
  const { revBomId } = useParams<any>()
  const [eBomItems, setEBomItems] = useState<IEBomItem[]>([])
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [pdf, setPdf] = useState('')
  const [pdfSize, setPdfSize] = useState(0)

  const [numPages, setNumPages] = useState(1)
  const [pageNumber, setPageNumber] = useState(1)

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages)
  }

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPageNumber(value)
  }

  useEffect(() => {
    setLoading(true)

    const pdfDiv: HTMLElement | null = document.getElementById('pdf-wrapper')
    setPdfSize(pdfDiv?.offsetWidth ?? 0 - 48)

    const _promises = [
      axios.get(`${BOMS_URL}/eboms/${revBomId}`).then(({ data }) => {
        setEBomItems(data)
      }),
      axios.get(`${BOMS_URL}/revbom/preview/${revBomId}`).then(({ data }) => {
        axios.get(data.ViewableItem).then(({ data }) => {
          setPdf(`data:application/pdf;base64,${data}`)
        })
      }),
    ]

    Promise.all(_promises).finally(() => setLoading(false))
  }, [revBomId])

  return (
    <Grid container className={classes.root}>
      <Grid item xs={6}>
        <StickyTable
          rows={eBomItems}
          columns={[
            {
              minWidth: 85,
              label: 'Position',
              id: 'PositionNumber',
            },
            {
              minWidth: 120,
              label: 'Number',
              id: 'ItemNumber',
            },
            {
              minWidth: 200,
              label: 'Description',
              id: 'Description',
            },
            {
              minWidth: 120,
              label: 'Quantity',
              id: 'Quantity',
            },
            {
              minWidth: 120,
              label: 'UM',
              id: 'UM',
            },
          ]}
        />
      </Grid>
      <Grid item xs={6}>
        <Paper className={classes.paper} id='pdf-wrapper'>
          {loading ? (
            <Box style={{ display: 'flex' }}>
              <CircularProgress className={classes.loading} />
            </Box>
          ) : (
            <>
              <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
                {<Page pageNumber={pageNumber} width={926} />}
              </Document>
              <Pagination
                className={classes.pagination}
                showFirstButton
                showLastButton
                count={numPages}
                page={pageNumber}
                onChange={handlePageChange}
              />
            </>
          )}
        </Paper>
      </Grid>
    </Grid>
  )
}
export { ElectricBom }
