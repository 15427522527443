import React from 'react'
import { Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ButtonBase from '@material-ui/core/ButtonBase'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'relative',
    height: '70px',
    padding: '10px 15px',
    paddingLeft: '90px',
    minWidth: '250px',
    cursor: 'pointer',
    opacity: '0.7',
  },
  selected: {
    opacity: '1',
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
  iconContainer: {
    backgroundColor: theme.palette.primary.main,
    position: 'absolute',
    top: '-10px',
    left: '10px',
    height: '70px',
    width: '70px',
    padding: '15px',
    textAlign: 'center',
    zIndex: 1,
    '& *': {
      color: '#ffffff',
      height: '100%',
      width: '100%',
      fontSize: '40px',
    },
  },
  textContainer: {
    display: 'flex',
    height: '100%',
    width: '100%',
    justifyContent: 'flex-end',
  },
  text: {
    display: 'inline-block',
    alignSelf: 'flex-end',
  },
}))

type Props = {
  title: string
  icon: JSX.Element
  selected: boolean
}

const TitleCard = (props: Props) => {
  const { title, icon, selected } = props
  const classes = useStyles()

  return (
    <ButtonBase>
      <Paper
        className={
          selected ? clsx(classes.paper, classes.selected) : classes.paper
        }
      >
        <Paper elevation={3} className={classes.iconContainer}>
          {icon}
        </Paper>

        <div className={classes.textContainer}>
          <Typography className={classes.text} variant='h5'>
            {title}
          </Typography>
        </div>
      </Paper>
    </ButtonBase>
  )
}

export { TitleCard }
