export const BASE_URL = process.env.REACT_APP_BASE_URL
export const CUSTOMERS_URL = process.env.REACT_APP_CUSTOMERS_URL
export const SALES_ORDERS_URL = process.env.REACT_APP_SALES_ORDERS_URL
export const BOMS_URL = process.env.REACT_APP_BOMS_URL
export const ITEMS_URL = process.env.REACT_APP_ITEMS_URL
export const DOCS_URL = process.env.REACT_APP_DOCS_URL
export const GATEWAY_URL = process.env.REACT_APP_GATEWAY_URL
export const SETTINGS_URL = process.env.REACT_APP_SETTINGS_URL
export const PURCHASE_ORDERS_URL = process.env.REACT_APP_PURCHASE_ORDERS_URL

export const NKE_HOME = 'https://www.nke360.com/'
export const LOGO_NKE =
  'https://www.nke360.com/wp-content/uploads/2015/04/logonke.png'
export const LOGO_NKE_SMALL =
  'https://www.nke360.com/wp-content/uploads/2015/04/logonkepiccolo.png'
