import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { CUSTOMERS_URL } from '../consts'

interface ILogoContext {
  img: string
  setImg: (arg: string) => void
  refreshImg: () => void
}

const defaultLogo = ''
const LogoContext = React.createContext<ILogoContext>({
  img: defaultLogo,
  setImg: () => {},
  refreshImg: () => {},
})

type Props = {
  children: React.ReactNode
}

const LogoContextProvider = ({ children }: Props) => {
  const [img, setImg] = useState(defaultLogo)

  useEffect(() => {
    refreshImg()
  }, [])

  const refreshImg = () => {
    const _token = localStorage.getItem('token')
    if (_token) {
      axios.get(`${CUSTOMERS_URL}/customer/logo`).then(({ data }) => {
        if (data !== null && data !== undefined) {
          setImg(data)
        }
      })
    }
  }

  return (
    <LogoContext.Provider value={{ img, setImg, refreshImg }}>
      {children}
    </LogoContext.Provider>
  )
}

const useLogo = () => React.useContext(LogoContext)

export { LogoContext, LogoContextProvider, useLogo }
