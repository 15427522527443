import { DEFAULT_SETTINGS, DOCS_URL } from '../../consts'
import React from 'react'
import { Grid, Card, CardActionArea, CardContent } from '@material-ui/core'
import ReactDOM from 'react-dom'
import { PreviewCard } from '../../components/preview_card'

import axios from 'axios'

import i18n from '../../i18next'

const $ = window.$
const Autodesk = window.Autodesk

interface InfoValue {
  qta: number
  desc: string
  panel_category: string
  [key: string]: any
}

export default class DocPanel extends Autodesk.Viewing.UI.PropertyPanel {
  // Properties
  Matching: IMatching
  Viewer: Autodesk.Viewing.GuiViewer3D
  Settings: ISettings

  // Contructors
  constructor(
    viewer: Autodesk.Viewing.GuiViewer3D,
    parentContainer: HTMLElement,
    id: string,
    title: string,
    options?: Autodesk.Viewing.UI.DockingPanelOptions
  ) {
    super(parentContainer, id, title, options)
    this.Matching = []
    this.Viewer = viewer
    this.Settings = { ...DEFAULT_SETTINGS }

    this.setDocPanelValues = this.setDocPanelValues.bind(this)
  }

  //Methods
  initialize() {
    super.initialize()

    const _height = ($(this.container.parentElement).height() * 70) / 100
    this.container.style.top = '30%'
    this.container.style.height = `${_height}px`
    this.container.style.width = '450px'
    this.container.style.resize = 'both'
  }

  setSelectedEvent() {
    this.addEventListener(
      this.Viewer,
      Autodesk.Viewing.SELECTION_CHANGED_EVENT,
      this.setDocPanelValues
    )
  }

  setSettings(settings: ISettings) {
    this.Settings = settings

    if (this.isVisible()) {
      this.setDocPanelValues()
    }
  }

  setMatching(matching: IMatching) {
    this.Matching = matching
  }

  downloadFile = (id: any, name: any) => {
    axios
      .get(`${DOCS_URL}/document/${id}/download`, {
        responseType: 'arraybuffer',
      })
      .then(response => {
        if (response.data) {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', name)
          document.body.appendChild(link)
          link.click()
          link.parentNode?.removeChild(link)
        }
      })
      .catch(() => console.log('ERROR DOWNLOADDING!!!'))
  }

  setDocPanelValues() {
    let _dbIdArray = this.Viewer.getSelection()
    const _treePanel = $('#spp-viewer-tree').jstree(true)

    let _values: InfoValue[] = []
    this.removeAllProperties()
    if (!_dbIdArray.length) {
      _dbIdArray.push(this.Viewer.model.getRootId())
    } else if (_dbIdArray[0] === NaN) {
      _dbIdArray = [this.Viewer.model.getRootId()]
    }

    _dbIdArray.forEach(dbId => {
      if (this.Matching[dbId]) {
        const _nodeId = this.Matching[dbId]
        let _nodeData = _treePanel.get_node(_nodeId).data
        _nodeData = _nodeData ? _nodeData : {}
        const _nodeDocs = _nodeData.docs ? _nodeData.docs : []
        _values.push({
          qta: _nodeData.quantity ? _nodeData.quantity : 1,
          desc: _nodeData.desc ? _nodeData.desc : '-',
          docs: _nodeDocs,
          ..._nodeData.properties,
          panel_category: _nodeData.model,
        })
      }
    })

    if (!_values.length) {
      this.setTitle(i18n.t('noSelectedNode'), {})
      _values.push({
        qta: 0,
        desc: '-',
        panel_category: '',
      })
    } else if (_values.length === 1) {
      this.setTitle(
        _values[0].panel_category
          ? i18n.t('documents') + ': ' + _values[0].panel_category
          : i18n.t('documentsSelectedNode'),
        {}
      )
      _values[0].panel_category = ''
    } else {
      this.setTitle('Documents Selected Nodes', {})
    }

    const _docs: any[] = []
    _values.forEach(val => {
      if (val.docs) {
        val.docs.forEach((doc: any) => {
          _docs.push(doc)
        })
      }
    })

    const _divDocs = '<div id="div-doc-grid">Documenti</div>'
    $(this.container).children('.docking-panel-scroll').html(_divDocs)

    const _gridDocs = (
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        {_docs.length ? (
          _docs.map((d, i) => (
            <Card
              style={{
                margin: 5,
                padding: 5,
                display: 'flex',
                overflow: 'auto',
                flexDirection: 'column',
              }}
            >
              <CardActionArea
                title='Download'
                onClick={() => this.downloadFile(d.id, d.name)}
              >
                <PreviewCard
                  url={`${DOCS_URL}/document/${d.id}/thumbnail`}
                  width={200}
                  height={280}
                  prefersDarkMode={false}
                />
              </CardActionArea>
            </Card>
          ))
        ) : (
          <Grid
            style={{
              margin: 5,
              padding: 5,
              display: 'flex',
              overflow: 'auto',
              flexDirection: 'column',
            }}
            item
            xs={12}
          >
            {i18n.t('noDocuments')}
          </Grid>
        )}
      </div>
    )

    const _getDivDocs = document.getElementById('div-doc-grid')

    ReactDOM.render(_gridDocs, _getDivDocs)
  }
}
