import React, { FunctionComponent } from 'react'
import MaterialTable from 'material-table'

import { IBaseTable } from './types'
import { useTranslation } from 'react-i18next'

const BaseTable: FunctionComponent<IBaseTable> = ({
  baseProps,
  selection = true,
}) => {
  const { t } = useTranslation()
  return (
    <MaterialTable
      {...baseProps}
      options={{
        actionsColumnIndex: -1,
        selection: selection,
        showTextRowsSelected: false,
      }}
      localization={{
        body: {
          emptyDataSourceMessage: baseProps.loading
            ? t<string>('loading')
            : t<string>('noRecords'),
        },
        header: {
          actions: t<string>('actions'),
        },
        toolbar: {
          searchPlaceholder: t<string>('search'),
          searchTooltip: t<string>('search'),
        },
        pagination: {
          labelRowsSelect: t<string>('rows'),
          firstTooltip: t<string>('firstPage'),
          lastTooltip: t<string>('lastPage'),
          previousTooltip: t<string>('previousPage'),
          nextTooltip: t<string>('nextPage'),
        },
      }}
    />
  )
}

export default BaseTable
