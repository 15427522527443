import { find } from 'lodash'

export const createNewJsonNode = (selectedNode, tree) => {
  let _toReturn
  let _parents = selectedNode.parents
  if (_parents[0] !== '#') {
    _parents = _parents.reverse()
  }

  _parents = _parents.slice(1).concat([selectedNode.id])
  let _children = tree.settings ? tree.settings.core.data : []
  _parents.forEach((id) => {
    if (_children.id === parseInt(id)) {
      _children = _children ? _children.children : []
    } else {
      _children = find(_children, (child) => child.id === parseInt(id))
      _children = _children ? _children.children : []
    }
  })

  _toReturn = JSON.parse(JSON.stringify(selectedNode))
  _toReturn.children = JSON.parse(JSON.stringify(_children))

  return _toReturn
}
