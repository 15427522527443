import { store } from 'react-notifications-component';

export const fileListToArray = (list) => {
  const array = [];
  for (var i = 0; i < list.length; i++) {
    array.push(list.item(i));
  }
  return array;
}

export const sendNotification = (title, message, type) => {
  store.addNotification({
    title, message, type,
    insert: "top",
    container: "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: 2500,
      onScreen: true
    }
  });
}