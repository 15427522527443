import { makeStyles } from '@material-ui/core'
import React from 'react'
import i18n from '../../i18next'
import it from './flag-for-italy_1f1ee-1f1f9.png'
import en from './flag-for-united-kingdom_1f1ec-1f1e7.png'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
  },
  flag: {
    height: '33px',
    cursor: 'pointer',
    marginRight: '3px',
  },
}))

const LanguageSelector = () => {
  const classes = useStyles()

  const changeLanguage = (val: string) => {
    localStorage.setItem('i18nextLng', val)
    i18n.changeLanguage(val)
    window.location.reload()
  }

  return (
    <div className={classes.container}>
      <img
        className={classes.flag}
        src={en}
        alt='flag-en'
        onClick={() => changeLanguage('en')}
      />
      <img
        className={classes.flag}
        src={it}
        alt='flag-it'
        onClick={() => changeLanguage('it')}
      />
    </div>
  )
}

export { LanguageSelector }
